import {
  Row,
  Col,
  Input,
  notification,
  Select,
  List,
  Avatar,
  Tabs,
  Flex,
  Typography,
  Space,
  Popconfirm,
  Alert,
} from 'antd';
import {
  CopyOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Form, { RuleObject } from 'antd/es/form';
import { Link, useParams } from 'react-router-dom';
import { Footer } from '@seaters-app/ui-shared';
import { useCallback, useEffect, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import './styles.css';
import {
  FanGroupEntity,
  UserFormEntity,
  UserRole,
  UserRoleOptions,
  routes,
  usersKeys,
} from '@seaters-app/constants';
import {
  Button,
  PasswordValidation,
  PhoneInputWithCountryCode,
} from '@seaters-app/ui';
import { SelectFanGroup } from './SelectFanGroup';
import {
  getObjectTranslation,
  getSingleTranslation,
  useDeleteUserHostRole,
  useDeleteUserOwnership,
  useFetchApproverUserFanGroups,
  useFetchUserFanGroups,
  useFetchUserHostRoles,
  useFetchUserOwnerships,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface UserFormProps {
  saveChanges: (
    values: UserFormEntity,
    ownershipFanGroupsList: FanGroupEntity[],
    setOwnershipFanGroupsList: (v: FanGroupEntity[]) => void,
    hostRoleFanGroupsList: FanGroupEntity[],
    setHostRoleFanGroupsList: (v: FanGroupEntity[]) => void
  ) => void;
  initialValues: UserFormEntity;
}

export function UserForm({ saveChanges, initialValues }: UserFormProps) {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const [phoneNumber, setPhoneNumber] = useState(
    initialValues.countryCallingCode + initialValues.localNumber
  );
  const [countryCode, setCountryCode] = useState(
    initialValues?.countryCallingCode
  );
  const { userId = '' } = useParams();

  const [form] = Form.useForm();
  const roles = Form.useWatch('roles', form);

  const password = Form.useWatch('password', form);

  const isFan = roles?.includes(UserRole.FAN);
  const isFanGroupOwner = roles?.includes(UserRole.FAN_GROUP_OWNER);
  const isHost = roles?.includes(UserRole.HOST);
  const isApprover = roles?.includes(UserRole.APPROVER);

  const [isOpenForOwnership, setOpenForOwnership] = useState(false);
  const [isOpenForHostRole, setOpenForHostRole] = useState(false);
  const [roleRemovingWarningShown, setRoleRemovingWarningShown] =
    useState(false);
  const [activeTab, setActiveTab] = useState<UserRole>(UserRole.FAN);

  const [ownershipFanGroupsList, setOwnershipFanGroupsList] = useState<
    FanGroupEntity[] | []
  >([]);

  const [hostRoleFanGroupsList, setHostRoleFanGroupsList] = useState<
    FanGroupEntity[] | []
  >([]);

  const { mutate: deleteUserOwnerShip } = useDeleteUserOwnership();

  const { mutate: deleteUserHostRole } = useDeleteUserHostRole();

  const openFanGroupsPopupForOwnership = () => {
    setOpenForOwnership(true);
  };

  const openFanGroupsPopupForHostRole = () => {
    setOpenForHostRole(true);
  };

  const { data: fanGroups } = useFetchUserFanGroups(userId);
  const { data: approverGroups } = useFetchApproverUserFanGroups(userId);

  const { data: userOwnerships } = useFetchUserOwnerships(userId, {
    itemOffset: 0,
    maxPageSize: 100,
  });

  const { data: userHostRoles } = useFetchUserHostRoles(userId, {
    page: 0,
    size: 100,
  });
  const isSubmitting = !!useIsMutating(usersKeys.mutation());

  const copyID = useCallback(() => {
    if (userId) {
      navigator.clipboard.writeText(userId).then(
        () => {
          notification.success({
            message: t('notification_success_id_copied', { userId }),
          });
        },
        () => {
          notification.error({
            message: t('notification_error_id_copied', { userId }),
          });
        }
      );
    }
  }, []);
  const onSubmit = (values: UserFormEntity) => {
    saveChanges(
      {
        ...values,
        localNumber: phoneNumber.slice(countryCode.length),
        countryCallingCode: countryCode,
      },
      ownershipFanGroupsList,
      setOwnershipFanGroupsList,
      hostRoleFanGroupsList,
      setHostRoleFanGroupsList
    );
  };

  const validatePassword = (rule: RuleObject, value: string) => {
    return new Promise((resolve, reject) => {
      if (
        !value.length ||
        (value.length >= 15 &&
          /[A-Z]/.test(value) &&
          /[a-z]/.test(value) &&
          /\d/.test(value) &&
          /[^a-zA-Z0-9]/.test(value))
      ) {
        return resolve();
      } else {
        return reject(t('should_satisfy_all_validation_error'));
      }
    });
  };

  useEffect(() => {
    if (
      (roleRemovingWarningShown &&
        userHostRoles?.content.length === 0 &&
        activeTab === UserRole.HOST) ||
      (userOwnerships?.items.length === 0 &&
        activeTab === UserRole.FAN_GROUP_OWNER)
    ) {
      setActiveTab(UserRole.FAN);
      setRoleRemovingWarningShown(false);
    }
  }, [userHostRoles?.content.length, userOwnerships?.items.length]);

  useEffect(() => {
    if (roles?.includes(activeTab)) {
      setRoleRemovingWarningShown(false);
    }
  }, [roles]);

  const isNotAllowedToDeselectRole = (role: UserRole) => {
    const isNotAllowed =
      (role === UserRole.HOST && !!userHostRoles?.content.length) ||
      (role === UserRole.FAN_GROUP_OWNER && !!userOwnerships?.items.length);
    return isNotAllowed;
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
        autoComplete="off"
        disabled={isSubmitting}
      >
        <Row gutter={24}>
          <Col xs={24} md={16} xl={12}>
            <Form.Item
              label={t('settings_personal-section_form_email-label')}
              name="email"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={t('settings_personal-section_form_firstname-label')}
              name="firstName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={t('settings_personal-section_form_lastname-label')}
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item label={t('local_number_label_text')} name="localNumber">
              <PhoneInputWithCountryCode
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={16} xl={12}>
            <Form.Item label={t('roles_label_text')} name="roles">
              <Select
                mode="multiple"
                onDeselect={(option) => {
                  if (isNotAllowedToDeselectRole(option)) {
                    setRoleRemovingWarningShown(true);
                    setActiveTab(option);
                  }
                }}
                placeholder={t('please_select_placeholder_text')}
                defaultValue={userId ? initialValues.roles : [UserRole.FAN]}
                options={UserRoleOptions.map((role) => {
                  const rolesCount: {
                    [key: string]: number;
                  } = {
                    [UserRole.FAN]: fanGroups?.content.length ?? 0,
                    [UserRole.FAN_GROUP_OWNER]:
                      userOwnerships?.items.length ?? 0,
                    [UserRole.HOST]: userHostRoles?.content.length ?? 0,
                    [UserRole.APPROVER]: approverGroups?.content.length ?? 0,
                  };

                  return {
                    ...role,
                    label: rolesCount[role.value]
                      ? `${role.label}: ${rolesCount[role.value]}`
                      : role.label,
                    disabled: role.value === UserRole.FAN,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        {roleRemovingWarningShown && (
          <Alert
            description={t('user_role_removing_warning_text', {
              role: activeTab,
            })}
            type="warning"
            showIcon
          />
        )}
        <Row gutter={24}>
          <Col xs={24} md={16} xl={12}>
            <Tabs
              onTabClick={(key) => {
                setActiveTab(key as UserRole);
              }}
              defaultActiveKey={UserRole.FAN}
              activeKey={activeTab}
            >
              {(isFan || !!fanGroups?.content.length) && (
                <Tabs.TabPane tab={t('admin_user_role_fan')} key={UserRole.FAN}>
                  <List
                    size="small"
                    dataSource={fanGroups?.content}
                    renderItem={(item) => (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          description={
                            <Link
                              to={`/${routes.admin}/${routes.fanGroups}/${item.id}`}
                            >
                              <Text>
                                {getObjectTranslation(item.name, lang)} (
                                {item.slug})
                              </Text>
                            </Link>
                          }
                        ></List.Item.Meta>
                      </List.Item>
                    )}
                  />
                </Tabs.TabPane>
              )}
              {(!!userOwnerships?.items.length || isFanGroupOwner) && (
                <Tabs.TabPane
                  tab={t('current_ownerships_label_text')}
                  key={UserRole.FAN_GROUP_OWNER}
                >
                  <List
                    size="small"
                    footer={
                      <Flex justify="flex-end">
                        <Button
                          size="middle"
                          type="primary"
                          icon={<PlusOutlined rev={undefined} />}
                          onClick={openFanGroupsPopupForOwnership}
                        >
                          {t('add_fangroup_ownership_button_text')}
                        </Button>
                      </Flex>
                    }
                    dataSource={userOwnerships?.items}
                    renderItem={(item) => (
                      <List.Item key={item.fanGroupId}>
                        <List.Item.Meta
                          description={
                            <Space>
                              <Link
                                to={`/${routes.admin}/${routes.fanGroups}/${item.fanGroupId}`}
                              >
                                {getSingleTranslation(item.fanGroup.name)} (
                                {item.fanGroup.slug})
                              </Link>
                              <Popconfirm
                                title={t('delete_ownership_button_text')}
                                description={t(
                                  'delete_ownership_confirmation_text'
                                )}
                                cancelText={t('general_no')}
                                onConfirm={() =>
                                  deleteUserOwnerShip({
                                    fanGroupId: item.fanGroupId,
                                    userId: userId,
                                  })
                                }
                                onOpenChange={() => console.log('open change')}
                              >
                                <Button
                                  danger
                                  size="small"
                                  type="text"
                                  icon={<DeleteOutlined rev={undefined} />}
                                ></Button>
                              </Popconfirm>
                            </Space>
                          }
                        ></List.Item.Meta>
                      </List.Item>
                    )}
                  />
                </Tabs.TabPane>
              )}
              {(!!userHostRoles?.content.length || isHost) && (
                <Tabs.TabPane
                  tab={t('user_is_host_in_label_text')}
                  key={UserRole.HOST}
                >
                  <List
                    size="small"
                    footer={
                      <Flex justify="flex-end">
                        <Button
                          size="middle"
                          type="primary"
                          icon={<PlusOutlined rev={undefined} />}
                          onClick={openFanGroupsPopupForHostRole}
                        >
                          {t('add_host_role_label_text')}
                        </Button>
                      </Flex>
                    }
                    dataSource={userHostRoles?.content}
                    renderItem={(item) => (
                      <List.Item key={item.fanGroupId}>
                        <List.Item.Meta
                          description={
                            <Space>
                              <Link
                                to={`/${routes.admin}/${routes.fanGroups}/${item.fanGroupId}`}
                              >
                                {getSingleTranslation(item.fanGroup.name)} (
                                {item.fanGroup.slug})
                              </Link>
                              <Popconfirm
                                title={t('delete_host_role_button_text')}
                                description={t(
                                  'delete_host_role_confirmation_text'
                                )}
                                cancelText={t('general_no')}
                                onConfirm={() =>
                                  deleteUserHostRole({
                                    fanGroupId: item.fanGroupId,
                                    userId: userId,
                                  })
                                }
                                onOpenChange={() => console.log('open change')}
                              >
                                <Button
                                  danger
                                  size="small"
                                  type="text"
                                  icon={<DeleteOutlined rev={undefined} />}
                                ></Button>
                              </Popconfirm>
                            </Space>
                          }
                        ></List.Item.Meta>
                      </List.Item>
                    )}
                  />
                </Tabs.TabPane>
              )}
              {(!!approverGroups?.content.length || isApprover) && (
                <Tabs.TabPane
                  tab={t('user_is_approver_in_label_text')}
                  key={UserRole.APPROVER}
                >
                  <List
                    size="small"
                    dataSource={approverGroups?.content}
                    renderItem={(item) => (
                      <List.Item key={item.fanGroupId}>
                        <List.Item.Meta
                          description={
                            <Link
                              to={`/${routes.admin}/${routes.fanGroups}/${item.fanGroupId}`}
                            >
                              {getSingleTranslation(item.fanGroup.name)} (
                              {item.fanGroup.slug})
                            </Link>
                          }
                        ></List.Item.Meta>
                      </List.Item>
                    )}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
        </Row>

        {!!ownershipFanGroupsList.length && (
          <Row>
            <Col xs={24} md={16} xl={12}>
              <List
                size="small"
                header={<div>{t('selected_fanroups_label_text')}</div>}
                dataSource={ownershipFanGroupsList}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Button
                        onClick={() => {
                          setOwnershipFanGroupsList(
                            ownershipFanGroupsList.filter(
                              (fanGroup) => fanGroup.id !== item.id
                            )
                          );
                        }}
                        size="small"
                        icon={<CloseOutlined rev={undefined} />}
                      >
                        {t('button_text_cancel')}
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item?.profileImageUrl} />}
                      title={
                        getSingleTranslation(item?.name)
                          ? getSingleTranslation(item?.name)
                          : 'No name provided'
                      }
                      description={
                        <Link
                          to={`/${routes.admin}/${routes.fanGroups}/${item.id}`}
                        >
                          {item.id}
                        </Link>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}

        {!!hostRoleFanGroupsList.length && (
          <Row>
            <Col xs={24} md={16} xl={12}>
              <List
                size="small"
                header={<div>{t('set_host_role_to_groups_label_text')}:</div>}
                dataSource={hostRoleFanGroupsList}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Button
                        onClick={() => {
                          setHostRoleFanGroupsList(
                            hostRoleFanGroupsList.filter(
                              (fanGroup) => fanGroup.id !== item.id
                            )
                          );
                        }}
                        size="small"
                        icon={<CloseOutlined rev={undefined} />}
                      >
                        {t('button_text_cancel')}
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item?.profileImageUrl} />}
                      title={
                        getSingleTranslation(item?.name)
                          ? getSingleTranslation(item?.name)
                          : 'No name provided'
                      }
                      description={
                        <Link
                          to={`/${routes.admin}/${routes.fanGroups}/${item.id}`}
                        >
                          {item.id}
                        </Link>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}

        <Row gutter={24} style={{ marginTop: 24 }}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={
                userId
                  ? t('set_new_password_label_text')
                  : t('admin_user_password')
              }
              name="password"
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            {!!form.getFieldValue('password')?.length && (
              <PasswordValidation password={password} />
            )}
          </Col>
        </Row>
        {userId && (
          <Row gutter={24}>
            <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
              <Form.Item label={'ID'} name="id">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
              <Form.Item label="hidden">
                <Button
                  size="large"
                  type="text"
                  onClick={copyID}
                  icon={<CopyOutlined rev={undefined} />}
                  disabled={!userId}
                >
                  {t('copy_button_text')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Footer
          isDisabled={roleRemovingWarningShown}
          onCancel={() => form.resetFields()}
        />
      </Form>
      <SelectFanGroup
        isModalOpen={isOpenForOwnership || isOpenForHostRole}
        setModalOpen={
          isOpenForOwnership ? setOpenForOwnership : setOpenForHostRole
        }
        fanGroupsList={
          isOpenForOwnership ? ownershipFanGroupsList : hostRoleFanGroupsList
        }
        setFanGroupsList={
          isOpenForOwnership
            ? setOwnershipFanGroupsList
            : setHostRoleFanGroupsList
        }
      />
    </>
  );
}

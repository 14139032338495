import {
  useFetchWaitingListInvitations,
  useGuestListStore,
} from '@seaters-app/data-access';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { Button } from '@seaters-app/ui';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import {
  Modal,
  Typography,
  Select,
  Space,
  Form,
  Row,
  Col,
  theme,
  notification,
} from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const { Text } = Typography;
type InternalEmployeeModalType = {
  setSpecialGuestModal: (value: boolean) => void;
  specialGuestModal: boolean;
  onSubmitForm: (specialGuests: { [guestId: string]: string }) => void;
};

type OptionType = {
  disabled?: boolean;
  key: string;
  label: string;
  title?: string;
  value: string;
};

type InternalEmployeesType = {
  assignedGuests: OptionType[];
  specialGuest: OptionType;
};

type ValuesType = {
  internalEmployees: InternalEmployeesType[];
};

const defaultOptionValue = {
  assignedGuests: [],
  specialGuest: {
    key: '',
    label: '',
    value: '',
  },
};

const ValidatorSchema = z.object({
  internalEmployees: z.array(
    z.object({
      assignedGuests: z
        .array(
          z.object({
            label: z.string().min(1, { message: 'Required field' }),
            value: z.string().min(1, { message: 'Required field' }),
          })
        )
        .nonempty({ message: 'Required field' }),
      specialGuest: z.object({
        label: z.string().min(1, { message: 'Required field' }),
        value: z.string().min(1, { message: 'Required field' }),
      }),
    })
  ),
});

export function InternalEmployeeModal({
  setSpecialGuestModal,
  specialGuestModal,
  onSubmitForm,
}: InternalEmployeeModalType) {
  const { t } = useTranslation();
  const fieldArrayName = 'internalEmployees';

  const { waitingListId = '' } = useParams();

  const { data: invitations } = useFetchWaitingListInvitations(waitingListId);

  const [defaultValues, setDefaultValues] = useState<InternalEmployeesType[]>([
    defaultOptionValue,
  ]);

  // TODO: refactor this
  useEffect(() => {
    if (invitations) {
      const obj: InternalEmployeesType[] = [];
      invitations.content.forEach((invitation) => {
        if (!invitation.numberOfNonAllocatedSeats) {
          if (invitation.specialGuest) {
            const assignedTo = invitations.content.filter(
              (el) => el.specialGuestId === invitation.guest.guestId
            );
            obj.push({
              specialGuest: {
                key: invitation.guest.guestId,
                label: `${invitation.guest.firstName} ${invitation.guest.lastName}`,
                value: invitation.guest.guestId,
              },

              assignedGuests: assignedTo.map((el) => ({
                key: el.guest.guestId,
                label: `${el.guest.firstName} ${el.guest.lastName}`,
                value: el.guest.guestId,
              })),
            });
          }
        }
      });

      setDefaultValues(obj);
    }

    return () => {
      // second;
    };
  }, [invitations]);

  const groupGuests = useGuestListStore((state) => Object.values(state.guests));

  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const { token } = theme.useToken();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ValuesType>({
    resolver: zodResolver(ValidatorSchema),
    values: { internalEmployees: defaultValues },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const formDisabled = !!isMutating || !!isFetching || isSubmitting;

  const watchFieldArray = watch(fieldArrayName);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const onSubmit = async (values: ValuesType) => {
    const parsed = ValidatorSchema.safeParse(values);

    if (optionsLength) {
      notification.error({
        message: `All guests must be assigned!`,
      });

      return;
    }

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    let specialGuests = {};
    await values.internalEmployees.forEach(async (el) => {
      el.assignedGuests.forEach((assignedGuest: OptionType) => {
        specialGuests = {
          ...specialGuests,
          [assignedGuest.value]: el.specialGuest.value,
        };
      });
    });

    onSubmitForm(specialGuests);
  };

  const selectedItems = watchFieldArray
    .map((el) => [el.specialGuest, ...el.assignedGuests])
    .flat();

  const filteredOptions = groupGuests.filter(
    (el) =>
      !selectedItems.map((el) => el?.value).includes(el.guest.guestId) &&
      !el.isToAllocated
  );

  const optionsLength = filteredOptions.length;

  return (
    <Modal
      width={1114}
      title={'Define internal employee'} //TODO translation
      open={specialGuestModal}
      onCancel={() => setSpecialGuestModal(false)}
      footer={null}
      // style={{ top: 48, bottom: 48 }}
      // bodyStyle={{
      //   maxHeight: `calc(100vh - 96px)`,
      // }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text type="secondary">
          Select internal employee that will accompany guests
        </Text>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={formDisabled}
        >
          <Space
            direction="vertical"
            style={{ paddingBottom: 24, width: '100%' }}
          >
            {controlledFields.map((field, index) => {
              return (
                <Row key={field.id}>
                  <Space
                    direction="vertical"
                    size="large"
                    style={{
                      backgroundColor: token.colorPrimaryBg,
                      borderRadius: 8,
                      padding: 16,
                      width: '100%',
                    }}
                  >
                    <Row
                      justify={{
                        xs: 'end',
                        sm: 'end',
                        md: 'space-between',
                      }}
                      gutter={[8, 8]}
                    >
                      <Col
                        xs={{ order: 2, span: 24 }}
                        md={{ order: 1, span: 10 }}
                      >
                        <Form.Item
                          name={`internalEmployees.${index}.specialGuest`}
                          validateStatus={
                            errors.internalEmployees &&
                            errors.internalEmployees[index]?.specialGuest &&
                            'error'
                          }
                          help={
                            errors.internalEmployees &&
                            errors.internalEmployees[index]?.specialGuest &&
                            errors.internalEmployees[index]?.specialGuest?.value
                              ?.message
                          }
                        >
                          <Controller
                            control={control}
                            name={`internalEmployees.${index}.specialGuest`}
                            render={({ field }) => {
                              return (
                                <Select
                                  labelInValue
                                  style={{ width: '100%' }}
                                  options={filteredOptions.map((el) => ({
                                    label: `${el.guest.firstName} ${el.guest.lastName}`,
                                    value: el.guest.guestId,
                                  }))}
                                  {...field}
                                />
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ order: 1 }}>
                        <Button
                          disabled={fields.length === 1}
                          color={token.colorError}
                          onClick={() => remove(index)}
                          icon={
                            <CloseOutlined
                              color={token.colorError}
                              rev={undefined}
                            />
                          }
                          type="text"
                        >
                          {t('general_remove-btn')}
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24}>
                        <Form.Item
                          label={'Assigned guests'}
                          name={`internalEmployees.${index}.assignedGuests`}
                          style={{ width: '100%', marginBottom: 0 }}
                          validateStatus={
                            errors.internalEmployees &&
                            errors.internalEmployees[index]?.assignedGuests &&
                            'error'
                          }
                          help={
                            errors.internalEmployees &&
                            errors.internalEmployees[index]?.assignedGuests &&
                            errors.internalEmployees[index]?.assignedGuests
                              ?.message
                          }
                        >
                          <Controller
                            control={control}
                            name={`internalEmployees.${index}.assignedGuests`}
                            render={({ field }) => (
                              <Row>
                                <Col xs={24}>
                                  <Select
                                    labelInValue
                                    style={{ width: '100%' }}
                                    mode="multiple"
                                    options={filteredOptions.map((el) => ({
                                      label: `${el.guest.firstName} ${el.guest.lastName}`,
                                      value: el.guest.guestId,
                                    }))}
                                    {...field}
                                  />
                                </Col>
                              </Row>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                </Row>
              );
            })}
            <Button
              disabled={optionsLength < 2 || formDisabled}
              type="text"
              icon={<PlusOutlined />}
              onClick={() => append(defaultOptionValue)}
            >
              {t('button_add_internal_employee')}
            </Button>
          </Space>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '-12px',
              width: '100%',
            }}
          >
            <Button
              key="back"
              onClick={() => setSpecialGuestModal(false)}
              disabled={formDisabled}
            >
              {t('button_text_cancel')}
            </Button>
            <Space>
              <Space>
                <Text style={{ fontSize: '20px' }} strong>
                  {optionsLength}
                </Text>
                <Text>{t('internal_employee_remaining_to_assign')}</Text>
              </Space>

              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={formDisabled}
              >
                {t('button_save_request')}
              </Button>
            </Space>
          </div>
        </Form>
      </Space>
    </Modal>
  );
}

import { Form, Modal, Select, notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { surveyExtensionPoints } from '../../surveys/[id]/SurveyInstanceDetails/constants';
import { useParams } from 'react-router-dom';
import {
  downloadFileByURL,
  useExportSurveyAnswers,
} from '@seaters-app/data-access';
import { AnswersReportBody, WLPositionStatus } from '@seaters-app/constants';

interface DownloadSurveyAnswersModalProps {
  open: boolean;
  close: () => void;
}

function DownloadSurveyAnswersModal({
  open,
  close,
}: DownloadSurveyAnswersModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { wishListId = '' } = useParams();

  const [answersLoading, setAnswersLoading] = useState(false);

  const { mutate: exportAnswers } = useExportSurveyAnswers(wishListId);

  const handleExportAnswers = (values: AnswersReportBody) => {
    setAnswersLoading(true);

    exportAnswers(values, {
      onSuccess: async (response) => {
        downloadFileByURL(response.url);
        form.resetFields();
        close();
        notification.success({
          message: t('notification_success_survey_answers_report_download'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_survey_answers_report_download'),
        });
      },
      onSettled: () => {
        setAnswersLoading(false);
      },
    });
  };
  return (
    <Modal
      title={t('export_survey_answers')}
      open={open}
      onOk={() => form.submit()}
      onCancel={close}
    >
      <Form
        initialValues={{
          extensionPoint: '',
          positionStatus: WLPositionStatus.HAS_SEAT,
        }}
        form={form}
        disabled={answersLoading}
        onFinish={async (values) => {
          handleExportAnswers(values);
        }}
      >
        <Form.Item
          label={t('position_status_label')}
          name="positionStatus"
          rules={[{ required: true, message: t('field_validation_required') }]}
        >
          <Select
            allowClear
            placeholder={t('please_select_placeholder_text')}
            options={[
              {
                label: WLPositionStatus.HAS_SEAT,
                value: WLPositionStatus.HAS_SEAT,
              },
            ]}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={t('survey_extension_point_label')}
          name="extensionPoint"
          rules={[{ required: true, message: t('field_validation_required') }]}
        >
          <Select
            allowClear
            placeholder={t('please_select_placeholder_text')}
            options={surveyExtensionPoints.map(({ label, value }) => {
              return { value, label: t(label) };
            })}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DownloadSurveyAnswersModal;

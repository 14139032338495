import { UserRole, routes } from '@seaters-app/constants';
import {
  AppstoreOutlined,
  HomeOutlined,
  MailOutlined,
  StarOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  VerticalAlignBottomOutlined,
  CheckSquareOutlined,
  ShareAltOutlined,
  FileUnknownOutlined,
  SettingOutlined,
  TranslationOutlined,
  EuroOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import '@seaters-app/data-access';
import { ReactNode } from 'react';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

export const icons = [
  <UnorderedListOutlined rev={undefined} />,
  <TeamOutlined rev={undefined} />,
  <AppstoreOutlined rev={undefined} />,
  <StarOutlined rev={undefined} />,
  <HomeOutlined rev={undefined} />,
  <TeamOutlined rev={undefined} />,
  <UnorderedListOutlined rev={undefined} />,
  <FileUnknownOutlined rev={undefined} />,
  <TagsOutlined rev={undefined} />,
  <SettingOutlined rev={undefined} />,
];

export interface IMenuItem {
  name: string;
  path: string;
  order: number;
  icon?: ReactNode;
  roles?: UserRole[];
  allowedRoles?: UserRole[];
}
type MenuItem = Required<MenuProps>['items'][number];

const rolesIntersection = (
  allowedRoles: UserRole[] | undefined,
  userRoles?: UserRole[]
) => userRoles?.filter((value) => allowedRoles?.includes(value));

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  roles?: UserRole[],
  allowedRoles?: UserRole[]
): MenuItem {
  const hasIntersections = !!rolesIntersection(
    roles,
    Array.from(new Set(allowedRoles))
  )?.length;

  if (roles && !hasIntersections) {
    return null;
  }
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
export const useMenuItems = (
  fanGroupName: string,
  roles: UserRole[] = []
): MenuProps['items'] => {
  const { t } = useTranslation();
  return [
    getItem(
      fanGroupName,
      'g1',
      null,
      [
        getItem(
          t('wl_title'),
          routes.wishLists,
          <UnorderedListOutlined rev={undefined} />
        ),
        getItem(
          t('fans_tab-fans'),
          routes.fans,
          <TeamOutlined rev={undefined} />
        ),
        getItem(
          t('fg-configuration_tab'),
          routes.fanGroupConfiguration,
          <SettingOutlined rev={undefined} />
        ),
        getItem(
          t('admin_support_reporting'),
          routes.reporting,
          <FilePdfOutlined rev={undefined} />,
          undefined,
          undefined,
          [UserRole.ADMIN, UserRole.FAN_GROUP_OWNER],
          roles
        ),
      ],
      'group',
      [UserRole.FAN_GROUP_OWNER],
      roles
    ),
    getItem(
      t('admin_title'),
      'g2',
      null,
      [
        getItem(
          t('admin_user_tab-groups'),
          routes.fanGroups,
          <AppstoreOutlined rev={undefined} />
        ),
        getItem(
          t('admin_events'),
          routes.events,
          <StarOutlined rev={undefined} />
        ),
        getItem(
          t('admin_venues'),
          routes.venues,
          <HomeOutlined rev={undefined} />
        ),
        getItem(
          t('admin_users'),
          routes.users,
          <TeamOutlined rev={undefined} />
        ),
        getItem(
          t('admin_badges'),
          routes.badges,
          <UnorderedListOutlined rev={undefined} />
        ),
        getItem(
          t('admin_surveys'),
          routes.surveys,
          <FileUnknownOutlined rev={undefined} />
        ),
        getItem(
          t('admin_ticketing-systems'),
          routes.ticketingSystems,
          <TagsOutlined rev={undefined} />
        ),
        getItem(
          t('admin_menu_payment'),
          routes.paymentSystems,
          <EuroOutlined rev={undefined} />
        ),
        getItem(
          t('admin_menu_localization'),
          routes.localization,
          <TranslationOutlined rev={undefined} />
        ),
        getItem(t('admin_menu_support'), routes.supportActions),
      ],
      'group',
      [UserRole.ADMIN],
      roles
    ),
  ];
};

export const fanWebMenuItems: IMenuItem[] = [
  {
    name: 'dashboard_menu_wishlists', // TODO check key
    path: routes.waitingLists,
    order: 0,
    icon: <UnorderedListOutlined rev={undefined} />,
  },
  {
    name: 'menus_label_my_wishlists', // TODO check key
    path: routes.myWaitingLists,
    order: 1,
    icon: <StarOutlined rev={undefined} />,
  },
  {
    name: 'menus_label_my_seats', // TODO check key
    path: routes.tickets,
    order: 2,
    icon: <TagsOutlined rev={undefined} />,
  },
  {
    name: 'my_invites',
    path: routes.invites,
    order: 3,
    icon: <MailOutlined rev={undefined} />,
    roles: [UserRole.HOST],
  },
  {
    name: 'menus_label_requests',
    path: routes.requests,
    order: 4,
    icon: <VerticalAlignBottomOutlined rev={undefined} />,
    roles: [UserRole.APPROVER],
  },
  {
    name: 'menus_label_approvals',
    path: routes.approvals,
    order: 5,
    icon: <CheckSquareOutlined rev={undefined} />,
    roles: [UserRole.APPROVER],
  },
  {
    name: 'menus_label_allocate',
    path: routes.allocate,
    order: 4,
    icon: <ShareAltOutlined rev={undefined} />,
    roles: [UserRole.FAN_GROUP_OWNER],
  },
];

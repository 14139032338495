import React, { ReactElement } from 'react';
import AntLayout from 'antd/es/layout';
import { Space, theme } from 'antd';
import styles from './footer.module.css';
import { Button } from '@seaters-app/ui';
import { getSlugFromUrl } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

export interface FooterProps {
  children?: ReactElement;
  onCancel?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  formName?: string;
}
const { Footer: AntFooter } = AntLayout;

export function Footer({
  onCancel,
  formName,
  isDisabled,
  isLoading,
  children,
}: FooterProps) {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const slug = getSlugFromUrl();
  return (
    <AntFooter
      className={!slug ? styles.footer : styles.footerFan}
      style={{
        borderTop: `1px solid ${token.colorBorderSecondary}`,
      }}
    >
      {children}
      <Space>
        <Button size="large" onClick={onCancel}>
          {t('button_text_cancel')}
        </Button>
        <Button
          form={formName}
          size="large"
          type="primary"
          htmlType="submit"
          disabled={isDisabled}
          loading={isLoading}
        >
          {t('button_text_save')}
        </Button>
      </Space>
    </AntFooter>
  );
}

export default Footer;

import { Breadcrumb, Flex, Spin, Typography, notification } from 'antd';
import {
  useCreateUser,
  useCreateUserHostRole,
  useCreateUserOwnership,
} from '@seaters-app/data-access';
import {
  FanGroupEntity,
  UserFormEntity,
  UserRole,
  UserStatus,
} from '@seaters-app/constants';
import { Link, useNavigate } from 'react-router-dom';
import { UserForm } from '../UserForm/UserForm';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;

const initialValues: UserFormEntity = {
  status: UserStatus.ACTIVE,
  roles: [UserRole.FAN],
  language: 'en',
  countryCallingCode: '',
  localNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  confirmedMobilePhoneNumber: true,
  confirmedEmail: true,
  password: '',
};

export function CreateUser() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: createUser, isLoading: isUserCreating } = useCreateUser();
  const { mutate: createOwnership, isLoading: isOwnershipCreating } =
    useCreateUserOwnership();

  const { mutate: setHostRoleForUser, isLoading: isHostRoleSetting } =
    useCreateUserHostRole();

  const saveChanges = (
    values: UserFormEntity,
    ownershipFanGroupsList: FanGroupEntity[],
    setOwnershipFanGroupsList: (v: FanGroupEntity[]) => void,
    hostRoleFanGroupsList: FanGroupEntity[],
    setHostRoleFanGroupsList: (v: FanGroupEntity[]) => void
  ) => {
    const { countryCallingCode, localNumber } = values;

    const userToCreate: UserFormEntity & {
      mobilePhoneNumber: {
        countryCallingCode: string;
        localNumber: string;
      } | null;
    } = {
      ...initialValues,
      ...values,
      roles: values.roles.length ? values.roles : initialValues.roles,
      mobilePhoneNumber: {
        countryCallingCode: countryCallingCode,
        localNumber,
      },
    };
    if (!countryCallingCode || !localNumber) {
      userToCreate.mobilePhoneNumber = null;
    }

    createUser(userToCreate, {
      onSuccess: async (response) => {
        notification.success({
          message: t('notification_ success_user_created'),
        });
        await ownershipFanGroupsList.forEach((fanGroup: FanGroupEntity, i) =>
          createOwnership(
            {
              userId: response.id,
              fanGroupId: fanGroup.id,
            },
            {
              onSuccess: () => {
                notification.success({
                  message: t('notification_success_ownership_created'),
                });
                if (i === ownershipFanGroupsList.length - 1) {
                  setOwnershipFanGroupsList([]);
                }
              },
              onError: (err) => {
                console.error(err);
                notification.error({
                  message: `${t('notification_error_ownership_created')}. ${
                    err?.response?.data.message
                      ? err?.response?.data.message
                      : ''
                  }`,
                });
              },
            }
          )
        );

        await hostRoleFanGroupsList.forEach((fanGroup: FanGroupEntity, i) =>
          setHostRoleForUser(
            {
              userId: response.id,
              fanGroupId: fanGroup.id,
            },
            {
              onSuccess: () => {
                notification.success({
                  message: t('notification_success_role_setted'),
                });
                if (i === hostRoleFanGroupsList.length - 1) {
                  setHostRoleFanGroupsList([]);
                }
              },
              onError: (err) => {
                console.error(err);
                notification.error({
                  message: `${t('notification_error_role_setted')}. ${
                    err?.response?.data.message
                      ? err?.response?.data.message
                      : ''
                  }`,
                });
              },
            }
          )
        );
        navigate(`../${response.id}`);
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: `${t('notification_ error_user_created')}. ${
            err?.response?.data.message ? err?.response?.data.message : ''
          }`,
        });
      },
    });
  };

  return (
    <Spin
      tip={t('please_wait_message_text')}
      spinning={isUserCreating || isOwnershipCreating}
    >
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
        gap={12}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'..'}>{t('admin_users')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{t('cwl_event_create-btn')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="subheader">
            <Title level={3}>{t('create_user_button_text')}</Title>
          </div>
        </div>
        <UserForm saveChanges={saveChanges} initialValues={initialValues} />
      </Flex>
    </Spin>
  );
}

import { PlusOutlined } from '@ant-design/icons';
import {
  Flex,
  Popconfirm,
  Space,
  Table,
  Typography,
  notification,
  theme,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { UserListEntity, UserStatus, routes } from '@seaters-app/constants';
import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useUsers } from './hooks/useUsers';
import { Button } from '@seaters-app/ui';
import { useAppThemeStore, useDeleteUser } from '@seaters-app/data-access';
import { getStatusStyle } from './helpers/getStatusStyle';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { Column } = Table;

export function Users() {
  const { token } = theme.useToken();
  const { mode } = useAppThemeStore();
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    handleTableChange,
    pagination,
    queryParams,
    setQuery,
  } = useUsers();

  const { mutateAsync: deleteUser } = useDeleteUser();

  const handleDeleteUser = (userId: string) =>
    deleteUser(
      { userId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_user_removed'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_user_removed'),
          });
        },
      }
    );

  const onSearch = (value: string) => {
    setQuery({ search: value.trim() });
  };
  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
      gap={12}
    >
      <Title level={2}>{t('admin_users')}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.search}
          placeholder={t('search_by_placeholder_text', {
            by: t('admin_user_email'),
          })}
          onSearch={onSearch}
          size="large"
          style={{ width: '30%' }}
        />
        <NavLink to={routes.create}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
          >
            {t('add_user_button')}
          </Button>
        </NavLink>
      </div>
      <div>
        <Table
          rowKey={(user) => user.id}
          dataSource={data?.items}
          loading={isLoading}
          pagination={pagination}
          onChange={handleTableChange}
          tableLayout="fixed"
        >
          <Column
            title={t('general_name')}
            dataIndex="name"
            key="name"
            render={(_, user: UserListEntity) => {
              return (
                <span>
                  {user.firstName} {user.lastName}
                </span>
              );
            }}
          />
          <Column
            title={t('roles_label_text')}
            dataIndex="roles"
            key="roles"
            render={(roles: string[]) => {
              return <span>{roles.join(', ')}</span>;
            }}
          />
          {/* <Column title="Id" dataIndex="id" key="id" /> */}
          <Column title={t('admin_user_email')} dataIndex="email" key="email" />
          <Column
            title={t('status_label')}
            dataIndex="status"
            key="status"
            render={(status: UserStatus) => {
              return (
                <span style={getStatusStyle(status, mode)}>
                  {status.toLowerCase()}
                </span>
              );
            }}
          />
          <Column
            title=""
            dataIndex="details"
            key="details"
            align="right"
            render={(name: string, user: UserListEntity) => {
              return (
                <Space size="small">
                  <NavLink to={user.id}>
                    <Button
                      type="link"
                      icon={<InfoCircleOutlined rev={undefined} />}
                    >
                      {t('admin_tabs_details')}
                    </Button>
                  </NavLink>
                  <Popconfirm
                    title={t('delete_user_button_text')}
                    description={t('delete_user_confirmation_text')}
                    cancelText={t('general_no')}
                    onConfirm={() => handleDeleteUser(user.id)}
                    onOpenChange={() => console.log('open change')}
                  >
                    <Button
                      type="link"
                      color={token.colorError}
                      icon={<DeleteOutlined rev={undefined} />}
                    >
                      {t('wl_delete-btn')}
                    </Button>
                  </Popconfirm>
                </Space>
              );
            }}
          />
        </Table>
      </div>
    </Flex>
  );
}

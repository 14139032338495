import React, { useEffect } from 'react';
import {
  getSessionToken,
  queryClient,
  useVerifyOauth,
} from '@seaters-app/data-access';
import { routes } from '@seaters-app/constants';
import { Navigate, useParams } from 'react-router-dom';
import {
  SESSION_STORAGE_ALLOWED_GROUPS,
  LOCAL_STORAGE_SLUG,
  LOCAL_STORAGE_STRIPE_DEEP_LINK,
  LookEntity,
} from '@seaters-app/constants';
import { Spin } from 'antd';

const VerifyAuthentication: React.FC = () => {
  const auth = getSessionToken();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG);
  const searchParams = new URLSearchParams(window.location.search);

  const { provider } = useParams();

  const code = searchParams.get('code');

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { mutate: verifyOauth, isLoading } = useVerifyOauth({
    provider: provider || '',
    authorizationCode: code || '',
    fangroupId: fanGroupData?.fanGroupId || '',
  });

  const handleVerify = () => {
    verifyOauth();
  };

  let origin: string | undefined = undefined;
  let stripeDeepLink: string | undefined = undefined;
  let wlId = null;

  if (provider === 'kpn-b2c') {
    const state = searchParams.get('state');

    const decodedState = state ? atob(state) : null;

    const splitted = decodedState?.split('&');

    origin = splitted
      ?.find((item) => item.startsWith('origin='))
      ?.slice('origin='.length);

    wlId =
      splitted
        ?.find((item) => item.startsWith('wlId='))
        ?.slice('wlId='.length)
        .trim() ?? null;

    if (origin && origin !== localStorage.getItem(LOCAL_STORAGE_SLUG)) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, origin);
    }

    const allowedFangroup = splitted
      ?.find((item) => item.startsWith('allowedGroups='))
      ?.slice('allowedGroups='.length);

    if (
      allowedFangroup &&
      allowedFangroup !== sessionStorage.getItem(SESSION_STORAGE_ALLOWED_GROUPS)
    ) {
      sessionStorage.setItem(SESSION_STORAGE_ALLOWED_GROUPS, allowedFangroup);
    }

    stripeDeepLink = splitted
      ?.find((item) => item.startsWith('stripeDeepLink='))
      ?.slice('stripeDeepLink='.length);

    if (stripeDeepLink) {
      localStorage.setItem(LOCAL_STORAGE_STRIPE_DEEP_LINK, stripeDeepLink);
    }
  }

  useEffect(() => {
    if (!isLoading && provider && code && fanGroupData?.fanGroupId && !auth) {
      handleVerify();
    }
  }, []);

  useEffect(() => {
    if (!slug && origin) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, origin);
      handleVerify();
    }
  }, []);

  return auth ? (
    <Navigate
      to={`/${
        wlId && slug ? `${slug}/${routes.waitingList}/${wlId}` : slug ?? origin
      }`}
    />
  ) : (
    <Spin tip="Loading" size="large" style={{ margin: '24px 0' }}>
      <div className="content" />
    </Spin>
  );
};

export default VerifyAuthentication;

import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import {
  Alert,
  Col,
  InputNumber,
  Modal,
  Radio,
  Row,
  Segmented,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import {
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Form from 'antd/es/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Editor, baseColor600 } from '@seaters-app/ui';
import { Document, Page } from 'react-pdf';

import {
  requestOneTimeUpload,
  uploadFile,
  useAppUserStore,
  useFanGroupStore,
  useFetchAdminFanGroup,
  useFetchAdminWaitingList,
  useFetchFGOWaitingListAvailableSeats,
  useFetchFanGroupOwnerWaitingList,
  useFetchLanguages,
  usePreviewWaitingListVoucher,
  useRemoveWishListTickets,
  useUpdateAdminWaitingList,
  useUpdateWaitingList,
  useUpdateWaitingListTickets,
  useUpdateWaitingListToDraft,
  useUpdateWaitingListVoucherImage,
} from '@seaters-app/data-access';
import {
  CreateWishListTicketsFormValidatorSchema,
  EditWishListTicketsFormValidatorSchema,
  FormTranslationType,
  Language,
  SEATERS_APP_BASE_URL,
  SeatDistributionMode,
  SeatDistributionModeLabels,
  UserRole,
  routes,
} from '@seaters-app/constants';
import { getTranslations, initialTranslations } from '../../../../utils';
import styles from './tickets.module.css';

import Footer from '../components/Footer';
import { convertTranslation } from '../helpers/convertTransaltions';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../hooks/useCurrentStep';
import Card from 'antd/es/card/Card';
import Paragraph from 'antd/es/typography/Paragraph';
import TicketPoolSelect from './TicketPoolSelect';
import { ImageCropper, StyledText } from '@seaters-app/ui-shared';

const MAX_SIZE = 1024 * 512 * 5;

const { Text } = Typography;

type TicketsStepFormValues = {
  seatDistributionMode: SeatDistributionModeLabels;
  voucherText?: FormTranslationType;
  ticketsToAdd: number;
  estimatedTickets: number;
  ticketPoolIds?: string[];
  secondaryTicketPoolIds?: string[];
  voucherImageUrl: string;
};

type UploadedImage = {
  height: number;
  size: number;
  type: string;
  width: number;
};

export function TicketsStep({ isEdit }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const isMutating = !!useIsMutating();
  const { next, prev, current } = useCurrentStep();
  const { fanGroup } = useFanGroupStore();
  const { user } = useAppUserStore();
  const [editorShown, setEditorShown] = useState(false);
  const [infoModalShown, setInfoModalShown] = useState(false);

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { data: adminFanGroup } = useFetchAdminFanGroup(
    (isAdmin && fanGroup?.id) || ''
  );
  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const { t } = useTranslation();
  const { data: wishList, isLoading: wishListIsLoading } =
    useFetchFanGroupOwnerWaitingList(wishListId);
  const { data: availableSeatsData } =
    useFetchFGOWaitingListAvailableSeats(wishListId);

  const { data: languagesList } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  const { mutateAsync: updateWishList, isLoading: updateWishListIsLoading } =
    useUpdateWaitingList();

  const { mutateAsync: removeWishListTickets } = useRemoveWishListTickets();

  const { data: adminWL } = useFetchAdminWaitingList(wishListId);

  const {
    mutateAsync: updateAdminWishList,
    isLoading: updateAdminWishListIsLoading,
  } = useUpdateAdminWaitingList();

  const {
    mutateAsync: updateWishListTickets,
    isLoading: updateWishListTicketsIsLoading,
  } = useUpdateWaitingListTickets();

  const { mutate: updateWLToDraft } = useUpdateWaitingListToDraft();
  const { mutate: updateVoucherImage } = useUpdateWaitingListVoucherImage();

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);
  const [misshapedImage, setMisshapedImage] = useState<UploadedImage | null>(
    null
  );

  const { data: previewVoucherImage, isLoading: voucherPreviewIsLoading } =
    usePreviewWaitingListVoucher({
      waitingListId: wishListId,
      language: currentLanguage,
    });

  const initialValues: TicketsStepFormValues = {
    voucherText: wishList?.voucherText.length
      ? getTranslations(['voucherText'], wishList)?.voucherText
      : initialTranslations,
    ticketsToAdd: availableSeatsData?.numberOfAvailableSeats || 0,
    estimatedTickets: wishList?.estimatedTickets || 0,
    seatDistributionMode: wishList?.seatDistributionMode,
    ticketPoolIds: adminWL?.ticketPoolIds || [],
    secondaryTicketPoolIds: adminWL?.secondaryTicketPoolIds || [],
    voucherImageUrl: wishList?.voucherImageUrl || '',
  };

  const methods = useForm({
    mode: 'onBlur',
    resolver: zodResolver(
      isEdit
        ? EditWishListTicketsFormValidatorSchema
        : CreateWishListTicketsFormValidatorSchema
    ),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const seatDistributionMode = useWatch({
    control,
    name: 'seatDistributionMode',
  });

  const voucherImageUrl = useWatch({
    control,
    name: 'voucherImageUrl',
  });

  const languages = useMemo(
    () =>
      languagesList
        ? languagesList?.items.map((language) => ({
            label: language.locale.toUpperCase(),
            value: language.locale,
          }))
        : [],
    [languagesList]
  );

  const removeVoucherImage = () => {
    setValue('voucherImageUrl', '');
    setMisshapedImage(null);
  };

  const handleUploadVoucherImage = async () => {
    const voucherUrlOrCanvas = voucherImageUrl as string | HTMLCanvasElement;
    if (voucherUrlOrCanvas && typeof voucherUrlOrCanvas !== 'string') {
      setImageUploading(true);
      await requestOneTimeUpload({
        fileName: 'file.png',
      })
        .then(async (res) => {
          if (res.fileId) {
            const formFile = new FormData();
            const blob = (await new Promise((resolve) =>
              voucherUrlOrCanvas.toBlob(resolve)
            )) as Blob;
            if (blob.size > MAX_SIZE) {
              notification.error({
                message: t('file_too_big'),
                description: t('max_blob_file_text'),
              });
              return;
            }
            formFile.append('file', blob);
            await uploadFile(formFile, res.path.slice(20, 52)).then(() => {
              updateVoucherImage(
                {
                  waitingListId: wishList?.waitingListId || '',
                  body: {
                    fileId: res.fileId,
                  },
                },
                {
                  onSuccess: () => {
                    notification.success({
                      message: t('notification_success_image_upload'),
                    });
                    setImageUploading(false);
                  },
                  onError: ({ response }) => {
                    setImageUploading(false);
                    response?.data?.errors?.errorsTypes?.technical_error?.forEach(
                      ({ error }) => {
                        notification.error({
                          message: t('notification_error_image_upload'),
                          description: t(error.errorCode),
                        });
                      }
                    );
                    response?.data?.errors?.errorsTypes?.validation_errors?.forEach(
                      ({ error }) => {
                        notification.error({
                          message: t('notification_error_image_upload'),
                          description: t(error.errorCode),
                        });
                      }
                    );
                  },
                }
              );
            });
          }
        })
        .finally(() => {
          setImageUploading(false);
        });
    }
  };

  const handlePreviewVoucher = () => {
    if (previewVoucherImage?.url) {
      window.open(previewVoucherImage.url, '_blank');
    }
  };

  const onSubmit = async (values: TicketsStepFormValues) => {
    setInfoModalShown(false);
    const voucherText = convertTranslation(
      values.voucherText || initialTranslations
    );

    const updateTicketsBody =
      values.ticketsToAdd > initialValues.ticketsToAdd
        ? {
            originalNumberOfTickets: wishList?.totalTickets || 0, // to ensure that the request is sync when adding tickets
            ticketsToAdd: values.ticketsToAdd - initialValues.ticketsToAdd,
          }
        : {
            originalNumberOfTickets: wishList?.totalTickets || 0,
            ticketsToRemove: initialValues.ticketsToAdd - values.ticketsToAdd,
          };

    if (isAdmin && adminWL) {
      const { billingVariables, ...rest } = adminWL;
      const body = {
        ...rest,
        ...billingVariables,
        seatDistributionMode:
          values.seatDistributionMode === SeatDistributionModeLabels.TICKET
            ? SeatDistributionMode.TICKET
            : SeatDistributionMode.VOUCHER,
        ticketPoolIds: values.ticketPoolIds,
        secondaryTicketPoolIds: values.secondaryTicketPoolIds,
        voucherText,
        estimatedTickets: values.estimatedTickets,
      };

      await handleUploadVoucherImage();

      await updateAdminWishList(
        {
          waitingListId: wishList?.waitingListId || '',
          body,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_wl_updated'),
              description: response?.data.message,
            });
            if (values.ticketsToAdd < initialValues.ticketsToAdd) {
              notification.error({
                message: t('notification_error_vouchers_number_changed'),
              });
            }
          },
          // onSettled: () => {
          //   reset();
          // },
        }
      );
    } else if (wishList?.waitingListId) {
      await handleUploadVoucherImage();
      await updateWishList(
        {
          waitingListId: wishList?.waitingListId || '',
          body: {
            ...wishList,
            voucherText,
            estimatedTickets: values.estimatedTickets,
          },
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
        }
      );
    }
    if (updateTicketsBody.ticketsToAdd) {
      await updateWishListTickets(
        {
          waitingListId: wishList?.waitingListId || '',
          body: updateTicketsBody,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_vouchers_number_changed', {
                to: initialValues.ticketsToAdd + updateTicketsBody.ticketsToAdd,
              }),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_vouchers_number_changed'),
              description: response?.data.message,
            });
          },
        }
      );
    }

    if (updateTicketsBody.ticketsToRemove) {
      await removeWishListTickets(
        {
          waitingListId: wishList?.waitingListId || '',
          body: updateTicketsBody,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_vouchers_number_changed', {
                to:
                  initialValues.ticketsToAdd -
                  updateTicketsBody.ticketsToRemove,
              }),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_vouchers_number_changed'),
              description: response?.data.message,
            });
          },
        }
      );
    }

    if (saveAsDraft) {
      updateWLToDraft(
        { waitingListId: wishList?.waitingListId },
        { onSuccess: () => navigate(`/${routes.admin}/${routes.wishLists}`) }
      );
    } else {
      if (!isEdit) {
        next();
      } else {
        navigate(
          `/${routes.admin}/${routes.wishLists}/${wishList?.waitingListId}`
        );
      }
    }
  };

  const seatDistributionModeOptions = [
    {
      value: SeatDistributionModeLabels.VOUCHER,
      label: 'wl_set_distribution-mode-voucher',
      description: 'wl_set_distribution-mode-voucher-description',
    },
    {
      value: SeatDistributionModeLabels.TICKET,
      label: 'wl_set_distribution-mode-ticket',
      description: 'wl_set_distribution-mode-ticket-description',
    },
    {
      value: SeatDistributionModeLabels.LATER,
      label: 'wl_set_distribution-mode-later',
      description: 'wl_set_distribution-mode-later-description',
      hide: isEdit,
    },
  ];

  const handleChangeVoucherImage = (img: string) => {
    setValue('voucherImageUrl', img);
  };

  return (
    <Spin
      spinning={wishListIsLoading || imageUploading}
      tip={t('general_please_wait')}
    >
      <Space
        size="large"
        direction="vertical"
        style={{ width: '100%', paddingBottom: 40 }}
      >
        <p style={{ color: baseColor600 }}>{t('tickets_tab_instruction')}</p>
        {adminFanGroup?.operationParameters?.ticketingSystemId && (
          <Link
            to={`${SEATERS_APP_BASE_URL}${routes.admin}/${routes.ticketingSystems}/${adminFanGroup?.operationParameters?.ticketingSystemId}`}
          >
            <Button type="link" icon={<EyeOutlined rev={undefined} />}>
              {t('admin_fan-groups_ticketing-system')} {t('admin_tabs_details')}
            </Button>
          </Link>
        )}
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            id="ticketsStep"
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
            disabled={
              updateWishListTicketsIsLoading ||
              updateAdminWishListIsLoading ||
              updateWishListIsLoading ||
              imageUploading
            }
          >
            <Form.Item name="seatDistributionMode">
              <Controller
                control={control}
                name="seatDistributionMode"
                render={({ field }) => {
                  return (
                    <Radio.Group
                      value={seatDistributionMode}
                      name="seatDistributionMode"
                      defaultValue={wishList?.seatDistributionMode}
                      disabled={!isAdmin}
                    >
                      <Space>
                        <Row gutter={20}>
                          {seatDistributionModeOptions.map((option) => {
                            return !option.hide ? (
                              <Col xs={24} md={8}>
                                <Card bordered>
                                  <Radio
                                    {...field}
                                    disabled={
                                      option.value ===
                                        SeatDistributionModeLabels.TICKET &&
                                      !isAdmin
                                    }
                                    value={option.value}
                                  >
                                    {t(option.label)}
                                  </Radio>

                                  <Paragraph
                                    style={{ color: baseColor600, margin: 0 }}
                                  >
                                    {t(option.description)}
                                  </Paragraph>
                                </Card>
                              </Col>
                            ) : null;
                          })}
                        </Row>
                      </Space>
                    </Radio.Group>
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              name="estimatedTickets"
              label={t('wl_set_estimated-seats')}
            >
              <Controller
                control={control}
                name="estimatedTickets"
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    min={0}
                    size="middle"
                    style={{ width: 120 }}
                  />
                )}
              />
            </Form.Item>
            {seatDistributionMode === SeatDistributionMode.TICKET &&
              isAdmin && <TicketPoolSelect />}
            {seatDistributionMode === SeatDistributionMode.VOUCHER && (
              <>
                <Row gutter={20} align="middle">
                  <Col xs={24} md={16} xl={5}>
                    <Form.Item
                      label={t('wl_availableseats-label')}
                      name="ticketsToAdd" // Actually this is the number of available seats
                    >
                      <Controller
                        control={control}
                        name="ticketsToAdd"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            size="middle"
                            style={{ width: 120 }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20} align="top">
                  <Col xs={24} sm={24} md={16} xxl={12} xl={12}>
                    <Row>
                      <Col>
                        <Form.Item
                          label={t('mwl_seats_table-voucher')}
                          name="voucherImageUrl"
                        >
                          <ImageCropper
                            quality={0.8}
                            imageOrCanvas={voucherImageUrl}
                            handleRemoveImage={removeVoucherImage}
                            handleImageChange={handleChangeVoucherImage}
                            handleReset={() => {
                              setValue('voucherImageUrl', '');
                            }}
                            cropperProps={{
                              autoCropArea: 1,
                              aspectRatio: 2480 / 1170,
                              zoomable: false,
                              cropBoxResizable: false,
                              scalable: false,
                            }}
                            width={2480}
                            height={1170}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {misshapedImage ? (
                          <Alert
                            message={
                              <Text strong>
                                {t('invalid_image_dimensions')}
                              </Text>
                            }
                            description={
                              <Space direction="vertical" size={4}>
                                <Text>
                                  {t('uploadfile_imagewidth')}:{' '}
                                  {misshapedImage.width}
                                </Text>
                                <Text>
                                  {t('uploadfile_imageheight')}:{' '}
                                  {misshapedImage.height}
                                </Text>
                                <Text>
                                  {t('uploadfile_size')}: {misshapedImage.size}
                                </Text>
                                <Text>
                                  {t('uploadfile_type')}: {misshapedImage.type}
                                </Text>
                              </Space>
                            }
                            type="error"
                            closable
                            onClose={removeVoucherImage}
                          />
                        ) : null}
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col xs={24} md={24} xl={24}>
                        <Form.Item
                          label={
                            <div>
                              {t('voucher_instruction_label')}{' '}
                              <InfoCircleOutlined
                                rev={undefined}
                                style={{ color: baseColor600 }}
                              />
                            </div>
                          }
                          name={`voucherText.${currentLanguage}`}
                          validateStatus={
                            errors.voucherText &&
                            errors.voucherText?.root?.message &&
                            'error'
                          }
                          help={
                            errors.voucherText &&
                            t(errors.voucherText?.root?.message)
                          }
                        >
                          <Controller
                            control={control}
                            name={`voucherText.${currentLanguage}`}
                            render={({ field: { onChange, ...field } }) => {
                              return !editorShown ? (
                                <StyledText
                                  text={field.value ?? ''}
                                  onClick={() => setEditorShown(true)}
                                />
                              ) : (
                                <Editor
                                  {...field}
                                  onEditorChange={onChange}
                                  width="100%"
                                  height={200}
                                />
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={16} xxl={12} xl={12}>
                    <Form.Item label={t('voucher_preview_label')}>
                      <Space direction="vertical">
                        {typeof getValues().voucherImageUrl === 'object' && (
                          <Alert
                            closable
                            type="info"
                            message={t('updated_voucher_image_tip')}
                          />
                        )}
                        <Document
                          file={previewVoucherImage?.url}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onError={(error) =>
                            console.log('Error loading PDF:', error)
                          }
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <div className={styles.container}>
                              <div className={styles.overlay}>
                                <Button
                                  type="primary"
                                  icon={<DownloadOutlined rev={undefined} />}
                                  onClick={() => {
                                    const voucherUrlOrCanvas =
                                      voucherImageUrl as
                                        | string
                                        | HTMLCanvasElement;

                                    if (
                                      voucherUrlOrCanvas &&
                                      typeof voucherUrlOrCanvas !== 'string'
                                    ) {
                                      setInfoModalShown(true);
                                    } else {
                                      handlePreviewVoucher();
                                    }
                                  }}
                                  loading={voucherPreviewIsLoading}
                                  size="large"
                                />
                              </div>
                              <div
                                key={`page_${index}`}
                                className={styles.content}
                              >
                                <Page
                                  pageNumber={index + 1}
                                  renderMode="canvas"
                                  scale={0.7}
                                />
                              </div>
                            </div>
                          ))}
                        </Document>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Footer
              isEdit={isEdit}
              handlePrevStep={prev}
              saveAsDraft={() => {
                setSaveAsDraft(true);
                onSubmit(getValues());
              }}
              isLoading={isMutating}
            >
              <Segmented
                size="middle"
                options={languages}
                value={currentLanguage}
                onChange={setCurrentLanguage}
              />
            </Footer>
          </Form>
        </FormProvider>
        {infoModalShown && (
          <Modal
            title={t('voucher_preview_label')}
            open={infoModalShown}
            onCancel={() => setInfoModalShown(false)}
            footer={[
              <Button onClick={handlePreviewVoucher}>
                {t('see_current_voucher_button')}
              </Button>,
              <Button htmlType="submit" form="ticketsStep" type="primary">
                {t('save_wl_button')}
              </Button>,
            ]}
          >
            <Text>{t('voucher_image_preview_description_modal')}</Text>
          </Modal>
        )}
      </Space>
    </Spin>
  );
}

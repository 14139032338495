import {
  OauthAuthConfiguration,
  PimAuthConfiguration,
  PimCheckTransaction,
  PimCheckTransactionQuery,
  PimQuery,
  SamlAuthConfiguration,
  VerificationTypeV2,
  authConfigKeys,
} from '@seaters-app/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchOauthAuthConfiguration,
  fetchPimAuthConfiguration,
  fetchPimCheckTransaction,
  fetchSamlAuthConfiguration,
  verifyOauth,
} from '../api';
import { setSessionData } from '../storage';
import { setAuthToken } from '../axios-instance';

export const useFetchPimAuthConfig = (provider: string, params?: PimQuery) =>
  useQuery<PimAuthConfiguration, Error>(
    authConfigKeys.pim(provider),
    () => fetchPimAuthConfiguration(provider, params),
    {
      enabled: !!provider.length,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchPimCheckTransaction = (
  provider: string,
  params?: PimCheckTransactionQuery
) =>
  useQuery<PimCheckTransaction, Error>(
    authConfigKeys.pimCheckTransaction(provider, params),
    () => fetchPimCheckTransaction(provider, params),
    {
      enabled: !!provider.length,
      refetchInterval: 2000,
    }
  );

// success response
//   {
//     "pim": {
//         "id": "94be45a0-34e1-4f8a-900a-06505ae9a1c2",
//         "provider": "kpnconsumers",
//         "reference": "5bdcd83af40144c8ab6327ad8da5068d",
//         "status": "ACCEPTED"
//     },
//     "authenticationSuccess": {
//         "token": {
//             "value": "2092cc91578d542dab08faadfc6ff58dd18f997b6a0d1f66214e41ad0d14044f2ba60f84be27a95737bb67dbeb9c1cf2aac743883efe02477e4375dab74ba934057286419b6d25ca6f4d96f942e5145e9b88788579bc05a3da5df35b4407c91ba128c42ba9d7d59a494a09ed27369d786a6937fcfe7d7700c9a48c3027b66415334b681a53d40143bfde4065d26cf539a904aab88e98209face7bc1a6f0d99a92288cc2316af441188a7463c7e069dc45d21c59d4934fb9cadef4b0254ab6f1d38b1c34ec3690115ad90138a7b47feda8ed488dc810f5aa1",
//             "expirationDate": "2023-08-15T12:18:34.730Z"
//         },
//         "userData": {
//             "name": {
//                 "firstName": "Vlad",
//                 "lastName": "Chernii"
//             },
//             "email": "vladyslav.chernii@panenco.com",
//             "facebookId": null,
//             "mobilePhoneNumber": {
//                 "countryCallingCode": "32",
//                 "localNumber": "499999999"
//             },
//             "roles": [
//                 "FAN_GROUP_OWNER",
//                 "ADMIN",
//                 "FAN"
//             ],
//             "locale": "en"
//         }
//     }
// }

export const useFetchOauthAuthConfig = (provider: string) =>
  useQuery<OauthAuthConfiguration, Error>(
    authConfigKeys.oauth(provider),
    () => fetchOauthAuthConfiguration(provider),
    {
      enabled: !!provider.length,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchSamlAuthConfig = (provider: string) =>
  useQuery<SamlAuthConfiguration, Error>(
    authConfigKeys.saml(provider),
    () => fetchSamlAuthConfiguration(provider),
    {
      enabled: !!provider.length,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useVerifyOauth = (body: {
  provider: string;
  authorizationCode: string;
  fangroupId: string;
}) =>
  useMutation<VerificationTypeV2, Error>(() => verifyOauth(body), {
    retry: 0,
    onSuccess: (data) => {
      if (data.token) {
        setSessionData(data);
        setAuthToken();
      }
    },
  });

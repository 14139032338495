import React, { UIEvent, useMemo } from 'react';
import {
  Button,
  baseColor400,
  baseColor600,
  baseColor900,
} from '@seaters-app/ui';
import { Form, Modal, Space, Spin, Typography, notification } from 'antd';
import { AccessMode } from '@seaters-app/constants';
import {
  useAddFanGroupMember,
  useAddFanToProtectedFG,
  useFanGroupStore,
  useUpdateUserRequestToJoinProtectedFG,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { useProtectedCodesOptions } from './hooks/useProtectedCodes';
import { useUsersOptions } from './hooks/useUsers';
import Select from 'antd/es/select';
import debounce from 'lodash/debounce';

const { Text } = Typography;

interface AddFanModalProps {
  isAddFanModalOpen: boolean;
  setAddFanModalOpen: (v: boolean) => void;
}

function AddFanModal({
  isAddFanModalOpen,
  setAddFanModalOpen,
}: AddFanModalProps) {
  const { fanGroup } = useFanGroupStore();
  const { t } = useTranslation();

  const {
    setCodesQuery,
    codesOptions,
    loadMoreData: loadMoreCodes,
    isFetching: codesFetching,
  } = useProtectedCodesOptions(fanGroup?.id ?? '');

  const handleScrollCodes = (e: UIEvent<HTMLDivElement>) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
      e.currentTarget.scrollHeight - 5
    ) {
      loadMoreCodes();
    }
  };

  const handleSearchCodes = debounce((searchValue) => {
    setCodesQuery({ size: 10, page: 0, query: searchValue });
  }, 500);

  const {
    usersOptions,
    loadMoreData: loadMoreUsers,
    query,
    setQuery,
    isFetching: usersFetching,
  } = useUsersOptions();

  const handleScrollUsers = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 5) {
      loadMoreUsers();
    }
  };

  const { mutate: addFan, isLoading: isFanAdding } = useAddFanGroupMember(
    fanGroup?.id ?? ''
  );

  const { mutate: requestAccess, isLoading: isRequestLoading } =
    useAddFanToProtectedFG(fanGroup?.id ?? '');

  const { mutate: updateRequestAccess, isLoading: isRequestUpdating } =
    useUpdateUserRequestToJoinProtectedFG(fanGroup?.id ?? '');

  const handleAddFan = (userId: string) => {
    addFan(
      { userId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_add_fan'),
          });
          setAddFanModalOpen(false);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_add_fan'),
            description: err.response?.data.message,
          });
        },
      }
    );
  };

  const handleRequestAccess = (userId: string, code: string) => {
    requestAccess(
      { userId, body: { code } },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_join_fg_request'),
          });
          setAddFanModalOpen(false);
        },
        onError: (err) => {
          updateRequestAccess(
            { userId, body: { code } },
            {
              onSuccess: () => {
                notification.success({
                  message: t('notification_success_join_fg_request'),
                });
                setAddFanModalOpen(false);
              },
              onError: (err) => {
                console.log('Error', err);
                notification.error({
                  message: err.response?.data.message,
                });
                setAddFanModalOpen(false);
              },
            }
          );
        },
      }
    );
  };

  const addUserToFanGroup = (values: { userId: string; code: string }) => {
    const { userId, code } = values;
    if (fanGroup?.accessMode !== AccessMode.CODE_PROTECTED) {
      handleAddFan(userId);
    } else if (code) {
      handleRequestAccess(userId, code);
    }
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value && !query.query.length) {
        setQuery({ ...query, page: 1, query: value });
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  const onSearch = (value: string) => {
    debounceFetcher(value.trim());
  };

  const emailTrans = t('settings_personal-section_form_email-label');
  return (
    <Modal
      destroyOnClose
      open={isAddFanModalOpen}
      title={t('add_fan_button_text')}
      onCancel={() => setAddFanModalOpen(false)}
      width={500}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={{
          code: '',
        }}
        onFinish={addUserToFanGroup}
      >
        <Form.Item
          label={t('add_fan_id_field_label')}
          name="userId"
          rules={[{ required: true, message: t('general_label_required') }]}
        >
          <Select
            style={{ width: '100%' }}
            allowClear
            filterOption={(input, option) => {
              return (option?.id ?? '').includes(input.trim());
            }}
            showSearch
            placeholder={t('search_by_placeholder_text', {
              by: emailTrans,
            })}
            notFoundContent={usersFetching ? <Spin size="small" /> : null}
            optionFilterProp="id"
            onSearch={onSearch}
            onPopupScroll={handleScrollUsers}
            onBlur={() => {
              setQuery({ page: 1, size: 10, query: '' });
            }}
            onClear={() => {
              setQuery({ page: 1, size: 10, query: '' });
            }}
            options={usersOptions}
            optionRender={(data) => {
              return (
                <Space>
                  <Text>{data.label}</Text>{' '}
                  <Text style={{ color: baseColor600 }}>{data.data.id}</Text>
                </Space>
              );
            }}
          />
        </Form.Item>
        {fanGroup?.accessMode === AccessMode.CODE_PROTECTED && (
          <Form.Item
            label={t('add_fan_code_field_label')}
            name="code"
            rules={[{ required: true, message: t('general_label_required') }]}
          >
            <Select
              allowClear
              onClear={() => {
                setCodesQuery({ size: 10, page: 0, query: '' });
              }}
              onSearch={handleSearchCodes}
              showSearch
              options={codesOptions}
              notFoundContent={codesFetching ? <Spin size="small" /> : null}
              onPopupScroll={handleScrollCodes}
              optionRender={({ data }) => {
                return (
                  <>
                    <Text
                      style={{
                        color: data.disabled ? baseColor400 : baseColor900,
                      }}
                    >
                      {data?.label}
                    </Text>{' '}
                    <Text
                      style={{
                        color: data.disabled ? baseColor400 : baseColor600,
                      }}
                    >
                      {data.used}
                    </Text>
                  </>
                );
              }}
            />
          </Form.Item>
        )}

        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
            marginBottom: '24px',
          }}
        >
          <Button
            style={{ flexGrow: 1 }}
            onClick={() => {
              setAddFanModalOpen(false);
            }}
          >
            {t('button_text_cancel')}
          </Button>
          <Button
            style={{ flexGrow: 1 }}
            type="primary"
            htmlType="submit"
            loading={isFanAdding || isRequestLoading}
          >
            {t('general_add-btn')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddFanModal;

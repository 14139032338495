import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import {
  LOCATION_STATES,
  LoginTypeEnum,
  LookEntity,
  routes,
  SEATERS_COOKIES_ACCEPTED,
  usersKeys,
} from '@seaters-app/constants';
import { StyledText } from '../StyledText';
import styles from './cookies.module.css';
import { Button } from '@seaters-app/ui';
import {
  getSlugFromUrl,
  queryClient,
  useCookiesStore,
  useFetchLook,
} from '@seaters-app/data-access';
import { useLocation } from 'react-router-dom';

const cookieConsentExpirationTime = 60 * 60 * 24 * 30 * 6;

export function CookiesAcceptModal() {
  const { t } = useTranslation();
  const { setCookies } = useCookiesStore();
  const slug = getSlugFromUrl();

  const look = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const { state: locationState } = useLocation();
  const oauthOption = look?.login.find(
    (login) => login.type === LoginTypeEnum.OAUTH
  );

  const isRedirectingToSSO =
    oauthOption &&
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.includes(
      routes.waitingList
    );

  const cookiesAcceptedData = document.cookie.includes(
    `${SEATERS_COOKIES_ACCEPTED}=true`
  );

  const [cookiesAccepted, setCookiesAccepted] = useState(cookiesAcceptedData);

  const handleCookiesAccepted = () => {
    setCookiesAccepted(true);
    document.cookie = `${SEATERS_COOKIES_ACCEPTED}=true; max-age=${cookieConsentExpirationTime}; path=/`;
    setCookies(); // set cookies in zustand store, to trigger  TermsAndConditionsModal
  };

  const DEV = import.meta.env.MODE === 'development';

  return (
    <Modal
      centered
      title={t('cookies_accept_modal-title')}
      closeIcon={null}
      maskClosable={false}
      open={
        !cookiesAccepted &&
        !DEV &&
        !window.location.pathname.includes(routes.security) &&
        !window.location.pathname.includes(routes.surveyAfterEvent) &&
        !isRedirectingToSSO
      }
      // onOk={handleCookiesAccepted}
      destroyOnClose={true}
      okText={t('cookies_accept_modal-accept')}
      cancelButtonProps={{ style: { display: 'none' } }}
      className={styles.modal}
      footer={[
        <Button
          data-testid="cookiesAcceptButton"
          key="submit"
          type="primary"
          onClick={handleCookiesAccepted}
        >
          {t('cookies_accept_modal-accept')}
        </Button>,
      ]}
    >
      <StyledText text={t('cookies_accept_modal-description')} />
    </Modal>
  );
}

export default CookiesAcceptModal;

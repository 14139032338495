import { Modal, Typography } from 'antd';
import { camelCaseToNormal } from '../../helpers/camelCaseToNormalName';
import { FanGroupFilter } from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';
import { getObjectTranslation } from '@seaters-app/data-access';
import { StyledText } from '@seaters-app/ui-shared';
const { Title } = Typography;

interface FiltersExplanationModalProps {
  filtersInfoShown: boolean;
  setFiltersInfoShown: (v: boolean) => void;
  filters: FanGroupFilter[];
}

export function FiltersExplanationModal({
  filtersInfoShown,
  setFiltersInfoShown,
  filters,
}: FiltersExplanationModalProps) {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  return (
    <Modal
      title={<Title level={3}>{t('filters_explanation_modal_title')}</Title>}
      onCancel={() => {
        setFiltersInfoShown(false);
      }}
      open={filtersInfoShown}
      footer={null}
      width={500}
      centered
      style={{ maxHeight: '800px' }}
      destroyOnClose={true}
    >
      {filters?.map((filter) => {
        return (
          filter.description && (
            <>
              <Title level={5}>
                {camelCaseToNormal(getObjectTranslation(filter.label, lang))}:
              </Title>
              <StyledText
                text={getObjectTranslation(filter.description, lang) || ''}
              />
            </>
          )
        );
      })}
    </Modal>
  );
}

export default FiltersExplanationModal;

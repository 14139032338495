import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useIsMutating } from '@tanstack/react-query';
import { Col, Form, Row, Select, Space, Typography } from 'antd';
import {
  useFetchAdminFanGroup,
  useFetchAdminWaitingList,
  useFetchTicketPools,
} from '@seaters-app/data-access';
import { Button, Container } from '@seaters-app/ui';
import CreateTicketPool from '../../../ticketing-systems/[id]/CreateTicketPool';
import { useTranslation } from 'react-i18next';
import { TicketPool } from '@seaters-app/constants';

const { Text } = Typography;

const TicketPoolSelect = () => {
  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);

  const mutations = !!useIsMutating();

  const { data: adminWL } = useFetchAdminWaitingList(wishListId);

  const { data: fanGroup } = useFetchAdminFanGroup(adminWL?.groupId || '');
  const { data: ticketPools } = useFetchTicketPools(
    fanGroup?.operationParameters?.ticketingSystemId || '',
    {
      itemOffset: 0,
      maxPageSize: 999,
    }
  );

  const showDrawer = () => {
    setOpenCreateDrawer(true);
  };

  const onClose = () => {
    setOpenCreateDrawer(false);
  };

  const disabledIfNoTicketingSystem =
    !fanGroup?.operationParameters?.ticketingSystemId;

  const getTicketPoolLabel = (el: TicketPool) => {
    const {
      name,
      uploadConfiguration: { distributedSeats, totalSeats, cancelledSeats },
    } = el;

    return `${name}: ${distributedSeats}/${totalSeats} ${t(
      'matrix_header_distributed'
    ).toLowerCase()} – ${cancelledSeats} ${t(
      'cancel_wl_status_text'
    ).toLowerCase()}`;
  };

  return (
    <Container>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {disabledIfNoTicketingSystem && (
          <Text type="warning">{t('admin_ticketing_system_not_linked')}</Text>
        )}
        <Button
          onClick={showDrawer}
          size="middle"
          disabled={mutations || disabledIfNoTicketingSystem}
        >
          <PlusCircleOutlined rev={undefined} />
          {t('admin_ticket_pools-add')}
        </Button>
        <CreateTicketPool
          onClose={onClose}
          open={openCreateDrawer}
          ticketingSystemId={
            fanGroup?.operationParameters?.ticketingSystemId || ''
          }
        />

        <Row gutter={20}>
          <Col xs={24} md={16} xl={8}>
            <Form.Item
              label={t('admin_waiting-list_main-ticket-pool')}
              name="ticketPoolIds"
              help={
                errors?.ticketPoolIds?.message && t('general_label_required')
              }
              validateStatus={errors?.ticketPoolIds ? 'error' : undefined}
              required
            >
              <Controller
                disabled={disabledIfNoTicketingSystem}
                control={control}
                name="ticketPoolIds"
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    size="middle"
                    options={ticketPools?.items.map((el) => ({
                      label: getTicketPoolLabel(el),
                      value: el.id,
                    }))}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={16} xl={8}>
            <Form.Item
              label={t('admin_waiting-parking-ticket-pool')}
              name="secondaryTicketPoolIds"
            >
              <Controller
                disabled={disabledIfNoTicketingSystem}
                control={control}
                name="secondaryTicketPoolIds"
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    size="middle"
                    options={ticketPools?.items.map((el) => ({
                      label: getTicketPoolLabel(el),
                      value: el.id,
                    }))}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Container>
  );
};

export default TicketPoolSelect;

import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
  TagsOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { baseColor100, Button } from '@seaters-app/ui';
import {
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  notification,
  theme,
  ConfigProvider,
  Typography,
  Modal,
  Progress,
  Avatar,
} from 'antd';
import {
  fanGroupOwnerWaitingListsKeys,
  SurveyExtensionPoint,
  SurveyQuery,
  waitingListsKeys,
  WaitingListStatusEnum,
  WLPositionEntity,
  WLPositionStatus,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import { useWishListPositions } from './hooks/useWishListPositions';
import {
  downloadFileByURL,
  fetchFGOWaitingListDistributionFinished,
  getSingleTranslation,
  queryClient,
  retryCallback,
  sleep,
  useAssignPositions,
  useAssignPositionsWithoutSeats,
  useAssignPositionsWithParking,
  useDeclineSeatsPosition,
  useExportSurveyAnswers,
  useFetchFanGroupOwnerWaitingList,
  useFetchFGOWaitingListAvailableSeats,
  useFetchFGOWaitingListAvailableSecondarySeats,
  useFetchWaitingListSurveyInstances,
  useFGOShuffleWishList,
  useUnmarkParkingPosition,
  useUpdateParkingPosition,
  useUpdateSeatsPosition,
} from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { Key, useState } from 'react';
import Search from 'antd/es/input/Search';
import { SortOrder } from 'antd/es/table/interface';
import { PositionStatusTag } from './PositionStatusTag';
import styles from './styles.module.css';
import { CreatePositionModal } from './CreatePositionModal';
import { useTranslation } from 'react-i18next';
import { NumberItem } from './NumberItem';
import ExpandedRow from './ExpandedRow/ExpandedRow';
import { checkVoucherText } from './helpers';
import { FanDetailsLayout } from '../../fans/[id]/FanLayout';
import { convertMsToString } from 'apps/seaters/src/utils';

const { Text } = Typography;

// WLid to test 73e4fc18-1862-49ec-9f7a-f50ad43d15da or 1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7
export function WishListPositions() {
  const { wishListId = '' } = useParams();
  const [openFanInfo, setOpenFanInfo] = useState(false);
  const [fan, setFan] = useState<string>('false');

  const openFanInfoModal = () => {
    setOpenFanInfo(true);
  };
  const [loading, setLoading] = useState(false);
  const [isSeatsAssignation, setIsSeatsAssignation] = useState<boolean>(false);

  const [assignWithoutSeats, setAssignWithoutSeats] = useState<boolean>(false);

  const [isCreatePositionModal, setIsCreatePositionModal] =
    useState<boolean>(false);

  const [assignationProgress, setAssignationProgress] = useState<number>(0);

  const [api, contextHolder] = notification.useNotification();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectedAssetsRowId, setSelectedAssetsRowId] = useState<string | null>(
    null
  );

  const [selectedParkingRowId, setSelectedParkingRowId] = useState<
    string | null
  >(null);

  const [assetsValue, setAssetsValue] = useState<{
    [fanId: string | Key]: number;
  }>({});

  const [parkingsValue, setParkingsValue] = useState<{
    [fanId: string | Key]: number;
  }>({});

  const [selectedRowItems, setSelectedRowItems] = useState<WLPositionEntity[]>(
    []
  );
  const [currentRow, setCurrentRow] = useState<WLPositionEntity | null>(null);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const expandAllRows = () => {
    const allFans = positions?.content.map((position) => position?.fanId);
    setExpandedRows(allFans ?? []);
  };

  const hideAllRows = () => {
    setExpandedRows([]);
  };
  const rejectablePositions = selectedRowItems.filter(
    (el) => el.seatsRequestStatus !== 'DECLINED'
  );

  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId);

  const params: Omit<SurveyQuery, 'fangroup_id'> = {
    size: 9999,
    page: 0,
    waitinglist_id: wishListId,
  };

  const { data: surveys } = useFetchWaitingListSurveyInstances(params);
  const resetSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRowItems([]);
  };

  const {
    positions,
    handleTableChange,
    pagination,
    isLoading,
    setPaginationParams,
    queryParams,
    sort,
    handleSort,
  } = useWishListPositions(resetSelection);

  const { data: availableSeatsData } =
    useFetchFGOWaitingListAvailableSeats(wishListId);
  const { data: availableSecondarySeatsData } =
    useFetchFGOWaitingListAvailableSecondarySeats(wishListId);

  const parkingSeatsTotal = selectedRowItems.reduce(
    (total, position) =>
      total +
      (parkingsValue[position.fanId] || position.numberOfParkingTickets),
    0
  );

  const getPositionSeats = (position: WLPositionEntity) =>
    assetsValue[position.fanId] || position.numberOfSeats;

  const getPositionParkingSeats = (position: WLPositionEntity) =>
    parkingsValue[position.fanId] || position.numberOfParkingTickets;

  const seatsTotal = selectedRowItems.reduce(
    (total, position) => total + getPositionSeats(position),
    0
  );

  const availableSeats = availableSeatsData?.numberOfAvailableSeats || 0;
  const availableParkingSeats =
    availableSecondarySeatsData?.numberOfAvailableSeats || 0;

  const isSeatsDistributionAvailable = seatsTotal <= availableSeats;

  const isParkingSeatsDistributionAvailable =
    parkingSeatsTotal <= availableParkingSeats;

  const isGeneralDistributionAvailable =
    isSeatsDistributionAvailable && isParkingSeatsDistributionAvailable;

  const { mutate: declineSeatsPosition } = useDeclineSeatsPosition(wishListId);
  const { mutate: updateSeatsPosition } = useUpdateSeatsPosition(wishListId);

  const { mutateAsync: updateParkingPosition } =
    useUpdateParkingPosition(wishListId);

  const { mutateAsync: unmarkParkingPosition } =
    useUnmarkParkingPosition(wishListId);

  const { mutate: shuffle } = useFGOShuffleWishList(wishListId);

  const { mutateAsync: assignPositionsWithParking } =
    useAssignPositionsWithParking(wishListId);

  const { mutateAsync: assignPositionsWithoutSeats } =
    useAssignPositionsWithoutSeats(wishListId);

  const { mutateAsync: assignPositions } = useAssignPositions(wishListId);

  const getSortOrder = (columnName: string): SortOrder | undefined =>
    sort?.name === columnName
      ? sort.order === 'ascend'
        ? 'descend'
        : sort.order === 'descend'
        ? 'ascend'
        : null
      : undefined;

  const handleAssignPositions = async (
    position: WLPositionEntity,
    isMultiple?: boolean,
    isWithoutSeats?: boolean
  ) => {
    const isWithParking = getPositionParkingSeats(position);
    const assignMethod = isWithoutSeats
      ? assignPositionsWithoutSeats
      : isWithParking
      ? assignPositionsWithParking
      : assignPositions;

    if (
      !isWithoutSeats &&
      (getPositionSeats(position) > availableSeats ||
        getPositionParkingSeats(position) > availableParkingSeats)
    ) {
      api.error({
        duration: 10,
        message: t('notification_distribution_failed'),
        description: t('not_enough_tickets_message'),
      });
      return;
    }

    await assignMethod(
      { positionId: position.fanId },
      {
        onError: ({ response }) => {
          notification.error({
            message: t(response?.data?.message || 'notification_error_message'),
          });
        },
      }
    ).then(async (data) => {
      const attempt = async () => {
        await sleep(1000);

        const { distributionFinished } =
          await fetchFGOWaitingListDistributionFinished(
            wishListId,
            data.nextDistributionNumber
          );

        if (distributionFinished) {
          return distributionFinished;
        } else {
          throw distributionFinished;
        }
      };

      const test = (value: boolean) => {
        if (value) {
          return value;
        } else {
          throw value;
        }
      };

      const processResult = (result: boolean) => {
        if (result) {
          if (!isMultiple) {
            notification.success({
              message: t('notification_success_all_seats_distributed'),
            });
          }
        }
      };

      const errorHandler = (error: any) => {
        console.error(error);
      };

      const result = await retryCallback(
        attempt,
        test,
        processResult,
        errorHandler,
        10
      );

      return result;
    });
  };

  const handlePositionDecline = (positionId: string) => {
    declineSeatsPosition(
      { positionId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_seats_declined'),
          });
        },
      }
    );
  };

  const handlePositionUpdate = (
    positionId: string,
    nrOfSeats: number | null
  ) => {
    if (nrOfSeats !== null) {
      setAssetsValue({
        ...assetsValue,
        [positionId]: nrOfSeats,
      });
      updateSeatsPosition(
        { positionId, nrOfSeats },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_seats_updated'),
            });
          },
        }
      );
    }
  };

  const handleParkingTicketsUpdate = (
    positionId: string,
    nbrOfParkingTickets: number | null
  ) => {
    if (nbrOfParkingTickets !== null) {
      setParkingsValue({
        ...parkingsValue,
        [positionId]: nbrOfParkingTickets,
      });

      const onSuccess = () =>
        notification.success({
          message: t('notification_parking_seats_updated'),
        });

      if (nbrOfParkingTickets === 0) {
        unmarkParkingPosition(
          { positionId },
          {
            onSuccess,
          }
        );
      } else {
        updateParkingPosition(
          { positionId, nbrOfParkingTickets },
          {
            onSuccess,
          }
        );
      }
    }
  };

  const onSelect = (record: WLPositionEntity, selected: boolean) => {
    const newRowItems = selected
      ? selectedRowItems.concat(record)
      : selectedRowItems.filter((el) => el.fanId !== record.fanId);
    const newRowsKeys = newRowItems.map((el) => el.fanId);

    setSelectedRowKeys(newRowsKeys);
    setSelectedRowItems(newRowItems);
  };
  const onSelectAll = (selected: boolean, selectedRows: WLPositionEntity[]) => {
    setSelectedRowItems(selectedRows);
    const selectedKeys = selectedRows.map((row: WLPositionEntity) => row.fanId);
    setSelectedRowKeys(selected ? selectedKeys : []);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelectAll,
    onSelect,
  };

  const SearchInput = () => (
    <Search
      defaultValue={queryParams.search}
      id="searchInput"
      placeholder={t('cwl_event_search-btn')}
      allowClear
      onSearch={(search) => {
        setPaginationParams({
          ...queryParams,
          search,
          current: 0,
        });
      }}
      style={{ maxWidth: 300 }}
      size="middle"
    />
  );

  const [answersLoading, setAnswersLoading] = useState(false);

  const { mutate: exportAnswers } = useExportSurveyAnswers(wishListId);

  const handleExportAnswers = () => {
    setAnswersLoading(true);

    exportAnswers(
      {
        positionStatus: WLPositionStatus.WAITING_SEAT,
        extensionPoint: SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
      },
      {
        onSuccess: async (response) => {
          downloadFileByURL(response.url);
          notification.success({
            message: t('notification_success_survey_answers_report_download'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_survey_answers_report_download'),
          });
        },
        onSettled: () => {
          setAnswersLoading(false);
        },
      }
    );
  };

  const handleQueriesInvalidation = () => {
    queryClient.invalidateQueries(waitingListsKeys.positions(wishListId));
    queryClient.invalidateQueries(
      fanGroupOwnerWaitingListsKeys.availableSeats(wishListId)
    );
    queryClient.invalidateQueries(
      fanGroupOwnerWaitingListsKeys.availableSecondarySeats(wishListId)
    );
  };

  const rejectSelected = () => {
    rejectablePositions.forEach((position) => {
      declineSeatsPosition(
        { positionId: position.fanId },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_seats_declined'),
            });
            resetSelection();
            handleQueriesInvalidation();

            // Will execute only once, for the last mutation (Todo 3),
            // regardless which mutation resolves first
          },
        }
      );
    });
  };

  const distributeSelected = async () => {
    if (!isGeneralDistributionAvailable) {
      api.error({
        duration: 10,
        message: t('notification_distribution_failed'),
        description: t('not_enough_tickets_message'),
      });
    } else {
      setIsSeatsAssignation(true);
    }
  };

  const seatsToDistribute: number = currentRow
    ? currentRow.requestedNumberOfSeats
    : selectedRowItems.reduce((acc, row) => {
        return acc + row?.requestedNumberOfSeats;
      }, 0);

  const positionsToDistribute: number = currentRow
    ? 1
    : selectedRowItems.length;

  const handleRowExpand = (seat: WLPositionEntity) => {
    const isExpanded = expandedRows.includes(seat.fanId);
    const newExpandedRows = isExpanded
      ? expandedRows.filter((rowKey) => rowKey !== seat.fanId)
      : expandedRows.concat(seat.fanId);
    setExpandedRows(newExpandedRows);
  };

  const handleWLShuffle = () => {
    shuffle(
      { waitingListId: wishListId },
      {
        onSuccess: () => {
          api.success({
            message: t('mwl_wl_shuffle-confirm'),
            description: t('mwl_wl_shuffle-confirm-info'),
          });
        },
      }
    );
  };

  if (!wishList) {
    return null;
  }

  const progressPercent = currentRow
    ? Number(
        (
          (assignationProgress / currentRow.requestedNumberOfSeats) * 100 || 0
        ).toFixed(2)
      )
    : Number(
        (
          (assignationProgress /
            (assignWithoutSeats
              ? selectedRowItems.length
              : seatsToDistribute)) *
            100 || 0
        ).toFixed(2)
      );

  const handleCloseDistributionModal = () => {
    setAssignationProgress(0);
    setAssignWithoutSeats(false);
    if (progressPercent === 100) {
      resetSelection();
    }
    setIsSeatsAssignation(false);
    setCurrentRow(null);
  };

  const handleConfirmDistributionModal = async () => {
    setLoading(true);
    if (assignWithoutSeats) {
      handleAssignation();
    } else {
      if (!currentRow) {
        for (const position of selectedRowItems) {
          await handleAssignPositions(position, true);

          setAssignationProgress(
            (assignationProgress) =>
              assignationProgress +
              (assignWithoutSeats ? 1 : position.requestedNumberOfSeats)
          );
        }
      } else {
        await handleAssignPositions(currentRow, true);
        setAssignationProgress(
          (assignationProgress) =>
            assignationProgress + currentRow.requestedNumberOfSeats
        );
      }
    }
    setLoading(false);
    handleQueriesInvalidation();
  };

  const handleAssignation = async () => {
    for (const position of selectedRowItems) {
      await handleAssignPositions(position, true, true);

      setAssignationProgress(
        (assignationProgress) =>
          assignationProgress +
          (assignWithoutSeats ? 1 : position.requestedNumberOfSeats)
      );
    }
    await handleQueriesInvalidation();
  };

  const handleDistributeWithoutSeats = () => {
    setAssignWithoutSeats(true);
    setIsSeatsAssignation(true);
  };

  const distributedSeats = assignWithoutSeats
    ? `${Math.floor((progressPercent / 100) * positionsToDistribute)}
/ ${positionsToDistribute}
${t('positions')}`
    : `${Math.floor((progressPercent / 100) * seatsToDistribute)}
/ ${seatsToDistribute}
${t('wl_seats-label')}`;

  return (
    <div style={{ width: '100%' }}>
      {contextHolder}
      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          borderBottom: `1px solid ${token.colorBorder}`,
        }}
      >
        {selectedRowKeys.length ? (
          <div className={styles.distributeBlock}>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    addonBg: baseColor100,
                    hoverBg: baseColor100,
                  },
                },
              }}
            >
              <SearchInput />
            </ConfigProvider>
            <Space size={24}>
              <ConfigProvider
                theme={{
                  token: {
                    colorText: baseColor100,
                  },
                }}
              >
                <NumberItem
                  label={t('selected_seats_label')}
                  value={seatsTotal}
                  isInverted
                />
                <NumberItem
                  label={t('remaining_seats_label')}
                  value={availableSeats}
                  isInverted
                />
                <NumberItem
                  label={t('selected_parking_label')}
                  value={parkingSeatsTotal}
                  isInverted
                />
                <NumberItem
                  label={t('remaining_tickets_label')}
                  value={availableParkingSeats}
                  isInverted
                />
              </ConfigProvider>

              <Button danger type="text" onClick={rejectSelected}>
                {t('reject_positions_button_text', {
                  count: rejectablePositions.length,
                })}
              </Button>

              <Popconfirm
                title={t('general_distribution_without_seats')}
                description={t(
                  `general_distribution_without_seats-confirmation`
                )}
                cancelText={t('general_cancel_text')}
                onConfirm={handleDistributeWithoutSeats}
                onOpenChange={() => console.log('open change')}
              >
                <Button disabled={availableSeats >= 1}>
                  {t('general_distribution_without_seats-btn')}
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={distributeSelected}
                style={{
                  color: 'white',
                }}
                // disabled={!isGeneralDistributionAvailable}
              >
                {t('mwl_wl_pre-distribute-box')}
              </Button>
            </Space>
          </div>
        ) : (
          <div className={styles.distribution}>
            <Space>
              <SearchInput />
              <Button
                type="text"
                loading={answersLoading}
                onClick={handleExportAnswers}
                icon={<DownloadOutlined rev={undefined} />}
              >
                {t('export_survey_answers')}
              </Button>
            </Space>

            <Space>
              <NumberItem
                label={t('remaining_seats_label')}
                value={availableSeats}
              />
              <NumberItem
                label={t('remaining_tickets_label')}
                value={availableParkingSeats}
              />
              <Tooltip
                title={
                  wishList.status === WaitingListStatusEnum.CLOSED
                    ? null
                    : t('cwl_set_err-shuffle-text-not-closed-text')
                }
              >
                <Button
                  disabled={wishList.status !== WaitingListStatusEnum.CLOSED}
                  onClick={handleWLShuffle}
                >
                  {/* key returns 'SHUFFLE' */}
                  {t('mwl_wl_shuffle-btn')?.charAt(0).toUpperCase() +
                    t('mwl_wl_shuffle-btn')?.slice(1)}
                </Button>
              </Tooltip>
              {wishList.status === WaitingListStatusEnum.OPEN && (
                <Button
                  // disabled
                  type="primary"
                  icon={<PlusOutlined rev={undefined} />}
                  onClick={() => setIsCreatePositionModal(true)}
                >
                  {t('add_position_button_text')}
                </Button>
              )}
              {!!surveys?.content.length && (
                <>
                  {expandedRows?.length === positions?.content?.length &&
                  !!positions?.content?.length ? (
                    <Tooltip title={t('hide_all_answers_btn_text')}>
                      <Button
                        type="text"
                        onClick={hideAllRows}
                        icon={<UpOutlined rev={undefined} />}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('show_all_answers_btn_text')}>
                      <Button
                        onClick={expandAllRows}
                        disabled={!positions || !positions.content.length}
                        type="text"
                        icon={<DownOutlined rev={undefined} />}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              {isCreatePositionModal && (
                <CreatePositionModal
                  wishList={wishList}
                  open={isCreatePositionModal}
                  onCancel={() => setIsCreatePositionModal(false)}
                />
              )}
            </Space>
          </div>
        )}
      </div>

      <Table
        rowKey={(record) => record.fanId}
        rowSelection={rowSelection}
        size="small"
        loading={isLoading}
        onChange={handleTableChange}
        pagination={pagination}
        dataSource={positions?.content}
        expandable={
          surveys?.content.length
            ? {
                expandedRowRender: (record) => (
                  <ExpandedRow
                    rowData={record}
                    surveys={surveys}
                    extensionPoint={
                      SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST
                    }
                  />
                ),
                expandedRowKeys: expandedRows,
                onExpand: (_, event) => {
                  handleRowExpand(event);
                },
              }
            : undefined
        }
        columns={[
          {
            title: t('mwl_wl_table-rank'),
            dataIndex: 'rank',
            sorter: true,
            sortOrder: getSortOrder('rank'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('rank');
              },
            }),
            fixed: 'left',
            width: 60,
          },
          {
            title: t('fans_list_tbl-name'),
            dataIndex: 'name',
            width: '12%',
            render: (_, position: WLPositionEntity) => {
              return (
                <Button
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                  type="link"
                  onClick={() => {
                    openFanInfoModal();
                    setFan(position.fanId);
                  }}
                >
                  {position.firstName} {position.lastName}
                </Button>
              );
            },
            sorter: true,
            sortOrder: getSortOrder('lastName'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('lastName');
              },
            }),
            fixed: 'left',
          },
          {
            title: t('mwl_seats_table-status'),
            dataIndex: 'position',
            width: '7%',
            ellipsis: {
              showTitle: false,
            },
            render: (_, position) => {
              return (
                <PositionStatusTag
                  seatsRequestStatus={position.seatsRequestStatus}
                />
              );
            },
          },
          {
            title: t('mwl_seats_table-email'),
            dataIndex: 'fanEmail',
            width: '12%',
            ellipsis: {
              showTitle: false,
            },
            render: (fanEmail) => (
              <Tooltip placement="topLeft" title={fanEmail}>
                <Text
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                >
                  {fanEmail}
                </Text>
              </Tooltip>
            ),
          },
          {
            title: t('mwl_seats_table-seats'),
            dataIndex: 'assets',
            width: 50,
            onCell: (record, rowIndex) => ({
              style: { cursor: 'pointer' },
              onClick: () => {
                if (record.fanId !== selectedAssetsRowId) {
                  setSelectedAssetsRowId(record.fanId);
                }
              }, // click header
              onMouseLeave: () => setSelectedAssetsRowId(null),
            }),
            render: (_, position: WLPositionEntity) => {
              return (
                <Space size={4}>
                  {selectedAssetsRowId === position.fanId ? (
                    <InputNumber
                      addonAfter={`/ ${position.requestedNumberOfSeats}`}
                      style={{ width: '100px' }}
                      min={1}
                      // max={position.requestedNumberOfSeats}
                      defaultValue={position.numberOfSeats}
                      onChange={(v) => handlePositionUpdate(position.fanId, v)}
                      value={assetsValue[position.fanId]}
                    />
                  ) : (
                    `${getPositionSeats(position)} / ${
                      position.requestedNumberOfSeats
                    }`
                  )}
                </Space>
              );
            },
          },
          {
            title: t('parking_tickets_label'),
            dataIndex: 'numberOfParkingTickets',
            width: 60,
            onCell: (record, rowIndex) => ({
              style: { cursor: 'pointer' },
              onClick: () => {
                if (record.fanId !== selectedParkingRowId) {
                  setSelectedParkingRowId(record.fanId);
                }
              }, // click header
              onMouseLeave: () => setSelectedParkingRowId(null),
            }),
            render: (numberOfParkingTickets, position: WLPositionEntity) => {
              return (
                <Space size={4}>
                  {selectedParkingRowId === position.fanId ? (
                    <InputNumber
                      min={0}
                      defaultValue={position.numberOfParkingTickets}
                      onChange={(v) =>
                        handleParkingTicketsUpdate(position.fanId, v)
                      }
                      value={parkingsValue[position.fanId]}
                    />
                  ) : (
                    parkingsValue[position.fanId] ||
                    position.numberOfParkingTickets
                  )}
                </Space>
              );
            },
          },
          {
            title: t('joined_wl_status_text'),
            dataIndex: ['userStats', 'nrOfWaitinglistsJoined'],
            sorter: true,
            width: 60,
            sortOrder: getSortOrder('userStats.nrOfWaitinglistsJoined'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('userStats.nrOfWaitinglistsJoined');
              },
            }),
          },
          {
            title: t('rsvp_sent_message_text'),
            dataIndex: 'rsvpSent',
            width: 90,
            render: (_, position: WLPositionEntity) => {
              return (
                <span>
                  {position.userStats.won} /{' '}
                  {position.userStats.nrOfWaitinglistsJoined}
                </span>
              );
            },
            sorter: true,
            sortOrder: getSortOrder('userStats.won'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('userStats.won');
              },
            }),
          },
          {
            title: t('abandon_label'),
            dataIndex: 'abandon',
            width: 80,
            render: (_, position: WLPositionEntity) => {
              return (
                <span>
                  {position.userStats.abandoned} /{' '}
                  {position.userStats.nrOfWaitinglistsJoined}
                </span>
              );
            },
            sorter: true,
            sortOrder: getSortOrder('userStats.abandoned'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('userStats.abandoned');
              },
            }),
          },
          {
            title: t('last_rsvp_title'),
            dataIndex: 'lastRSVP',
            width: 100,
            sorter: true,
            sortOrder: getSortOrder('lastRSVP'),
            onHeaderCell: () => ({
              onClick: () => {
                handleSort('lastRSVP');
              },
            }),
            render: (lastRSVP) => {
              return <span>{lastRSVP ? dayjs(lastRSVP).fromNow() : ''}</span>;
            },
          },
          {
            title: t('admin_badges'),
            dataIndex: 'badges',
            width: 120,
            render: (_, position) => {
              const userBadges = position.badges.map((badge) => badge.badge);
              return (
                <Space size={16} align="start">
                  {!!userBadges.length && (
                    <Avatar.Group
                      maxCount={3}
                      maxStyle={{
                        color: token.colorPrimaryText,
                        backgroundColor: token.colorPrimaryBg,
                      }}
                    >
                      {userBadges?.map(
                        (
                          badge: WLPositionEntity['badges'][number]['badge']
                        ) => (
                          <Tooltip
                            title={getSingleTranslation(badge.name)}
                            placement="top"
                          >
                            <Avatar size={40} src={badge.displayedLogoUrl} />
                          </Tooltip>
                        )
                      )}
                    </Avatar.Group>
                  )}
                </Space>
              );
            },
          },
          {
            title: t('mwl_wl_table-actions'),
            width: 150,
            render: (_, position: WLPositionEntity) => {
              return (
                <Space size={20}>
                  <Popconfirm
                    title={t('confirm_areyousure')}
                    description={t('mail_will_be_sent_message')}
                    cancelText={t('general_cancel_text')}
                    okText={t('reject_button_text')}
                    onConfirm={() => handlePositionDecline(position.fanId)}
                    onOpenChange={() => console.log('open change')}
                  >
                    <Button
                      style={{ padding: 0 }}
                      size="small"
                      disabled={position.seatsRequestStatus === 'DECLINED'}
                      danger
                      icon={<CloseOutlined rev={undefined} />}
                      type="link"
                    >
                      {t('reject_button_text')}
                    </Button>
                  </Popconfirm>
                  <Button
                    size="small"
                    style={{ padding: 0 }}
                    disabled={
                      getPositionSeats(position) === 0 ||
                      position.seatsRequestStatus === 'DECLINED'
                    }
                    type="link"
                    icon={<TagsOutlined rev={undefined} />}
                    color={token.colorPrimary}
                    onClick={() => {
                      if (!checkVoucherText(wishList)) return;
                      setCurrentRow(position);
                      setIsSeatsAssignation(true);
                    }}
                  >
                    {t('mwl_wl_pre-distribute-box')}
                  </Button>
                </Space>
              );
            },
          },
        ]}
      />
      <Modal
        title={
          assignWithoutSeats
            ? t('mwl_assign_wo_seats_title')
            : t('mwl_assign_title')
        }
        open={isSeatsAssignation}
        confirmLoading={loading}
        onOk={
          !assignationProgress
            ? handleConfirmDistributionModal
            : handleCloseDistributionModal
        }
        okText={
          !assignationProgress ? t('general_ok-btn') : t('general_close-btn')
        }
        onCancel={() => {
          handleCloseDistributionModal();
          handleQueriesInvalidation();
          setCurrentRow(null);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Text>
            {t('matrix_header_distributed')} {distributedSeats}
          </Text>
          {!!wishList.voucherExpirationTimeOutInMs && (
            <Text>
              {t('wl_set_confirm-seat-to')}:{' '}
              {convertMsToString(wishList.voucherExpirationTimeOutInMs)}
            </Text>
          )}
          <Text type="secondary">{t('mwl_distri_info')}</Text>
          <Progress percent={progressPercent} />
        </Space>
      </Modal>
      <Modal
        centered
        width={'80%'}
        open={openFanInfo && !!fan}
        cancelText={null}
        onOk={() => setOpenFanInfo(false)}
        onCancel={() => setOpenFanInfo(false)}
        footer={null}
      >
        <FanDetailsLayout fanId={fan} />
      </Modal>
    </div>
  );
}

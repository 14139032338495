import React, { useState } from 'react';
import SurveyInstanceDetails from '../../../surveys/[id]/SurveyInstanceDetails/SurveyInstanceDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchFanGroupOwnerSurveys,
  queryClient,
  useCreateFGOSurveyInstance,
  useFetchFGOWishListSurveysInstances,
} from '@seaters-app/data-access';
import { Drawer, Form, Space, notification } from 'antd';
import {
  SurveyInputMode,
  SurveyInstanceBody,
  surveyInstancesKeys,
} from '@seaters-app/constants';
import Footer from './../../create/components/Footer';
import { Button } from '@seaters-app/ui';
import WishListSurveys from './WishListSurveys';
import { PlusOutlined } from '@ant-design/icons';
import { useCurrentStep } from '../../create/hooks/useCurrentStep';

function AddSurveyToWL({ isEdit = true }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wishListId = '' } = useParams();

  const { data: surveyInstances } = useFetchFGOWishListSurveysInstances(
    wishListId,
    {
      // no sense to fetch more , as it should be only one survey per extension point (max 4)
      size: 10,
      page: 0,
      waitinglist_id: wishListId,
    }
  );

  const { next, prev } = useCurrentStep();

  const { mutate: createSurveyInstanceFGO, isLoading: isCreatingFGO } =
    useCreateFGOSurveyInstance();

  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);

  const showDrawer = () => {
    if (surveyInstances?.content && surveyInstances?.content?.length >= 3) {
      notification.error({
        message: t('notification_error_survey_usage_count_reached'),
        description: t(
          'notification_error_survey_usage_count_reached_description'
        ),
      });
      return;
    } else {
      setOpenCreateDrawer(true);
    }
  };

  const onClose = () => {
    setOpenCreateDrawer(false);
  };

  const initialValues = {
    surveyId: '',
    fangroupId: '',
    waitinglistId: wishListId,
    inputMode: SurveyInputMode.EDITABLE,
  };

  const createSurvey = (dataToSend: SurveyInstanceBody) => {
    createSurveyInstanceFGO(dataToSend, {
      onSuccess: (res) => {
        notification.success({
          message: t('notification_success_survey_usage_created'),
        });

        onClose();
        queryClient.invalidateQueries(
          surveyInstancesKeys.listPerWL({
            size: 10,
            page: 0,
            waitinglist_id: wishListId,
          })
        );
      },
      onError: ({ response }) => {
        response?.data?.errors?.errorsTypes?.validation_errors.map(
          ({ error }) => {
            notification.error({
              message: t('notification_error_survey_usage_created'),
              description: t(error.errorCode),
            });
          }
        );
      },
    });
  };

  return (
    <div style={{ height: '100vh' }}>
      <Space direction="vertical" size={16} style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined rev={undefined} />}
          onClick={showDrawer}
        >
          {t('add_survey_to_wl_button_text')}
        </Button>
      </Space>
      <Drawer
        open={openCreateDrawer}
        title={t('add_survey_to_wl_title_text')}
        width={720}
        onClose={onClose}
        destroyOnClose
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>{t('general_cancel')}</Button>
            <Button
              form={'surveyInstanceForm'}
              htmlType="submit"
              type="primary"
              loading={isCreatingFGO}
            >
              {t('general_submit')}
            </Button>
          </Space>
        }
      >
        <SurveyInstanceDetails
          initialValues={initialValues}
          onSubmit={createSurvey}
          fromWishList
          surveysFetchFunction={fetchFanGroupOwnerSurveys}
        />
      </Drawer>

      <WishListSurveys />
      {!isEdit && (
        <Form onFinish={next}>
          <Footer
            handlePrevStep={prev}
            saveAsDraft={() => {
              navigate('..');
            }}
          />
        </Form>
      )}
    </div>
  );
}

export default AddSurveyToWL;

import { LOCATION_STATES } from '@seaters-app/constants';
import { useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';

export const getModifiedUrl = (url: string) => {
  const { state: locationState } = useLocation();

  let splittedURI = url?.split('&') ?? [];

  const state = splittedURI
    ?.find((item) => item.startsWith('state='))
    ?.slice('state='.length)
    ?.replace('\r\n', '');

  const decodedState = state ? atob(state) : null;

  const modifiedState =
    decodedState +
    `&wlId=${
      locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.split('/')[3]
    }`;

  const encodedModifiedState = Base64.encode(modifiedState, true);

  const stateIndex = splittedURI?.findIndex((item) =>
    item.startsWith('state=')
  );

  splittedURI[stateIndex ?? 0] = `state=${encodedModifiedState}`;

  const newURI = splittedURI.join('&');
  return newURI;
};

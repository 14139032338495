import React, { useEffect, useMemo, useState } from 'react';
import {
  Input,
  notification,
  Form,
  Row,
  Col,
  Typography,
  Segmented,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateAdminSurvey,
  useFetchAdminSurvey,
  useFetchLanguages,
  useUpdateAdminSurvey,
} from '@seaters-app/data-access';
import {
  FormattedValuesWithTranslations,
  Language,
  SurveyCreationFormValues,
  SurveyEntity,
  SurveyFormValidator,
  SurveyFormValidatorSchema,
  SurveyStatus,
  SurveyValidatorSchema,
} from '@seaters-app/constants';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { prepareValues } from './../prepareValues';
import { getTranslations, initialTranslations } from '../../../../../utils';
import TextArea from 'antd/es/input/TextArea';
import { Footer } from '@seaters-app/ui-shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { SegmentedValue } from 'antd/es/segmented';
import { useCurrentStep } from '../useCurrentStep';
const { Text } = Typography;

function GeneralSurveyInfoStep({ isEdit }: { isEdit: boolean }) {
  const { questionnaireId = '' } = useParams();
  const { data: survey } = useFetchAdminSurvey(questionnaireId);
  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<SurveyEntity>(['title', 'description'], survey);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { next } = useCurrentStep();

  const initialValues = !survey
    ? {
        title: initialTranslations,
        description: initialTranslations,
        name: '',
        status: SurveyStatus.DRAFT,
      }
    : {
        title: valuesWithTranslations?.title,
        description: valuesWithTranslations?.description,
        name: survey.name,
        status: survey.status,
      };

  const methods = useForm<SurveyFormValidator>({
    resolver: zodResolver(SurveyFormValidatorSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = methods;

  const { mutate: createSurvey, isLoading: isSurveyCreating } =
    useCreateAdminSurvey();
  const { mutate: updateSurvey, isLoading: isSurveyUpdating } =
    useUpdateAdminSurvey(questionnaireId);

  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { data: languageData } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = useMemo(() => {
    return languageData?.items.map((language) => {
      return {
        label: language.locale.toUpperCase(),
        value: language.locale,
      };
    });
  }, [languageData?.items]);

  const onSubmit = (values: SurveyCreationFormValues) => {
    if (survey && !isDirty) {
      next();
      return;
    }
    const preparedValues = prepareValues(values);
    const parsed = SurveyValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    if (!survey) {
      createSurvey(parsed.data, {
        onSuccess: (res) => {
          notification.success({
            message: t('notification_success_survey_creation'),
          });
          navigate(res.id);
          next();
        },
        onError: (err) => {
          notification.error({
            message: t('notification_error_survey_creation'),
            description:
              `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}` ??
              err.message,
          });
        },
      });
    } else if (survey.surveyQuestions.length) {
      updateSurvey(
        {
          ...parsed.data,
          surveyQuestions: survey.surveyQuestions.map((q) => {
            return {
              enabled: q.enabled,
              mandatory: q.mandatory,
              questionId: q.question.id,
            };
          }),
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('notification_success_survey_updated'),
            });
            if (!isEdit) {
              navigate(res.id);
              next();
            } else {
              navigate('..');
            }
          },
          onError: (err) => {
            notification.error({
              message: t('notification_error_survey_updated'),
              description:
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}` ??
                err.message,
            });
          },
        }
      );
    } else {
      updateSurvey(
        { ...parsed.data, surveyQuestions: [] },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('notification_success_survey_updated'),
            });
            if (!isEdit) {
              navigate(res.id);
              next();
            } else {
              navigate('..');
            }
          },
          onError: (err) => {
            notification.error({
              message: t('notification_error_survey_updated'),
              description:
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}` ??
                err.message,
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (errors && currentLanguage !== Language.EN) {
      setCurrentLanguage(Language.EN);
    }
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <Form
        style={{ maxWidth: 630, width: '100%', marginTop: 20 }}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name="name"
              label={<Text strong>{t('survey_management_name_label')}</Text>}
              validateStatus={errors.name && 'error'}
              help={errors?.name && errors?.name?.message}
              required
            >
              <Controller
                control={control}
                rules={{ required: true, min: 1 }}
                name="name"
                render={({ field }) => <Input {...field} size="middle" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name={`title.${currentLanguage}`}
              label={<Text strong>{t('survey_management_title_label')}</Text>}
              required={currentLanguage === Language.EN}
              validateStatus={
                currentLanguage === Language.EN ? errors.title && 'error' : ''
              }
              help={
                errors?.title &&
                errors?.title[currentLanguage] &&
                errors?.title[currentLanguage]?.message
              }
            >
              <Controller
                rules={{ required: true, min: 1 }}
                control={control}
                name={`title.${currentLanguage}`}
                render={({ field }) => <Input {...field} size="middle" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name={`description.${currentLanguage}`}
              label={
                <Text strong>{t('survey_management_description_label')}</Text>
              }
              required={currentLanguage === Language.EN}
              validateStatus={
                currentLanguage === Language.EN
                  ? errors.description && 'error'
                  : ''
              }
              help={
                errors?.description &&
                errors?.description[currentLanguage] &&
                errors?.description[currentLanguage]?.message
              }
            >
              <Controller
                rules={{
                  required: true,
                  min: 1,
                }}
                control={control}
                name={`description.${currentLanguage}`}
                render={({ field }) => <TextArea {...field} size="middle" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Footer
          onCancel={() => navigate('..')}
          isLoading={isSurveyCreating || isSurveyUpdating}
          isDisabled={!isDirty}
        >
          {languages && (
            <Segmented
              size="large"
              options={languages}
              value={currentLanguage}
              onChange={setCurrentLanguage as (value: SegmentedValue) => void}
            />
          )}
        </Footer>
      </Form>
    </FormProvider>
  );
}

export default GeneralSurveyInfoStep;

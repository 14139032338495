import { BNPHospitalitySlug } from '@seaters-app/constants';
import { downloadFileByURL, useExportFGOSeats } from '@seaters-app/data-access';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const useExportList = (): {
  isExporting: boolean;
  handleExport: () => void;
} => {
  const { wishListId = '' } = useParams();

  const [reportLoading, setReportLoading] = useState(false);

  const { mutate } = useExportFGOSeats(wishListId);

  const handleExportFGReport = () => {
    setReportLoading(true);

    mutate(
      { reportName: BNPHospitalitySlug },
      {
        onSuccess: async (response) => {
          downloadFileByURL(response.url);
          setReportLoading(false);
        },
      }
    );
  };
  return {
    isExporting: reportLoading,
    handleExport: handleExportFGReport,
  };
};

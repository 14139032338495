import {
  ChoiceEntity,
  FormattedValuesWithTranslations,
  QuestionFormType,
  QuestionItem,
  QuestionStructureType,
  QuestionType,
} from '@seaters-app/constants';
import { initialTranslations } from '../../../../../../utils';
import { convertTranslationToObject } from '../../../../../../utils/helpers/convertTranslations';

const defaultStructureFields = [
  {
    name: 'First name',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  {
    name: 'Last name',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  {
    name: 'Birthdate',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  { name: 'Email', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  {
    name: 'Phone number',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  { name: 'Language', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  { name: 'Address', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  {
    name: 'ID number',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
];

export const getInitialValues = (
  question?: QuestionItem,
  answers?: ChoiceEntity[],
  valuesWithTranslations?: FormattedValuesWithTranslations | null
): QuestionFormType => {
  return question
    ? answers?.length
      ? {
          text: valuesWithTranslations?.text ?? initialTranslations,
          type: question.question.type,
          answerSemanticId: question.question.answerSemanticId,
          answerLabel:
            valuesWithTranslations?.answerLabel ?? initialTranslations,
          questionChoices: answers.map((answer: ChoiceEntity) => {
            return {
              ...answer,
              offeredAnswerTranslation: convertTranslationToObject(
                answer.offeredAnswerTranslation
              ),
            };
          }),
          structure: defaultStructureFields,
        }
      : question.question.type === QuestionType.STRUCTURED
      ? {
          text: valuesWithTranslations?.text ?? initialTranslations,
          type: question.question.type,
          answerSemanticId: question.question.answerSemanticId,
          answerLabel:
            valuesWithTranslations?.answerLabel ?? initialTranslations,
          structure: question.question.structure,
        }
      : {
          text: valuesWithTranslations?.text ?? initialTranslations,
          type: QuestionType.FREE_TEXT,
          answerSemanticId: question.question.answerSemanticId,
          answerLabel:
            valuesWithTranslations?.answerLabel ?? initialTranslations,
          structure: defaultStructureFields,
        }
    : {
        text: initialTranslations,
        type: QuestionType.FREE_TEXT,
        answerSemanticId: '',
        answerLabel: initialTranslations,
        structure: defaultStructureFields,
      };
};

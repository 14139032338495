import { useParams } from 'react-router-dom';
import {
  useFetchWaitingListVenueConditions,
  useFetchWaitingListSurveyAnswers,
  useFetchWaitingListSurveyInstances,
  useJoinWaitingListAsFan,
  useCheckoutStore,
  getSlugFromUrl,
  useFetchFGBySlug,
  useFetchWaitingListInvitations,
  getSingleTranslation,
  fetchWaitingList,
  queryClient,
  useEmailConfirmationStore,
  useFetchWishListBadgesState,
  useFetchUserRolesForCurrentFG,
  useFetchTransaction,
} from '@seaters-app/data-access';
import styles from './details.module.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  Drawer,
  Grid,
  Modal,
  Skeleton,
  Space,
  Tabs,
  TabsProps,
  Typography,
  notification,
  theme,
} from 'antd';
import InfoBlock from './components/InfoBlock/InfoBlock';

import {
  DisplayMode,
  WLPositionStatus,
  SurveyExtensionPoint,
  SurveyQuery,
  SeatStatus,
  JoinWLAsFanResponse,
  UserRole,
  InvitationMode,
  waitingListsKeys,
  RequestAccessStatus,
  WaitingListEntity,
  SESSION_STORAGE_EMAIL_REMINDER,
  TransactionEntity,
} from '@seaters-app/constants';
import { useEffect, useMemo, useState } from 'react';
import {
  RequestTicketsFooter,
  Survey,
  CheckoutModal,
  ProtectedCodeModal,
} from '@seaters-app/ui-shared';

import Banner from './components/WishListDetailsBanner/WishListDetailsBanner';
import { Button, Container, baseColor100 } from '@seaters-app/ui';
import RequestTicketsComponent from './components/TicketsComponents/RequestTicketsComponent';
import CancelTicketsComponent from './components/TicketsComponents/CancelTicketsComponent';
import AcceptTicketsComponent from './components/TicketsComponents/AcceptTicketsComponent';
import DownloadTicketsComponent from './components/TicketsComponents/DownloadTicketsComponent';
import EditGuestListComponent from './components/TicketsComponents/EditGuestListComponent';
import TicketsComponentSkeleton from './components/TicketsComponents/TicketsComponentSkeleton';
import { getCounterValue } from './helpers';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';

import { getFormattedDate } from '../../../utils/helpers/formattedDate';
import { SSOWarning } from '../components/SSOWarning/SSOWarning';

const { Text, Title } = Typography;

const { useBreakpoint } = Grid;

export type RefetchingStatus = 'join' | 'cancel' | 'pendingTransaction' | null;

dayjs.extend(relativeTime);

export function WaitingListFanView() {
  const { waitingListId = '' } = useParams();
  const { token } = theme.useToken();
  const { t, i18n, ready } = useTranslation();
  const { language: lang } = i18n;
  const { setEmailReminder } = useEmailConfirmationStore();

  const slug = getSlugFromUrl();

  const { data: fanGroupBySlugData, isRefetching } = useFetchFGBySlug(
    slug ?? ''
  );

  const { data: badgesState } = useFetchWishListBadgesState(waitingListId);

  const { data: invitations } = useFetchWaitingListInvitations(waitingListId, {
    refetchOnWindowFocus: true,
  });

  const { data: userRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fanGroupBySlugData?.id ?? '');

  const isHost = userRoles?.roles.find((role) => role === UserRole.HOST);

  const hasInvitations = invitations && invitations.totalElements > 0;

  const [refetchingStatus, setRefetchingStatus] =
    useState<RefetchingStatus>(null);

  const [position, setPosition] = useState<JoinWLAsFanResponse | null>(null);

  const getPollingConditions = (wishList: WaitingListEntity) => {
    if (refetchingStatus === 'cancel') {
      if (wishList?.position !== null) {
        return true;
      }
    } else if (refetchingStatus === 'join') {
      if (
        wishList?.position?.status === WLPositionStatus.BEING_PROCESSED ||
        !wishList?.position
      ) {
        return true;
      }
    }

    if (
      transaction &&
      transaction.status === 'PENDING' &&
      (wishList.seat.status === SeatStatus.ASSIGNED ||
        wishList.seat.status === SeatStatus.ASSIGNED_WITHOUT_SEATS)
    ) {
      return true;
    }

    setRefetchingStatus(null);
    return false;
  };

  const { data: waitingList, isLoading: waitingListIsLoading } = useQuery<
    WaitingListEntity,
    Error
  >(
    waitingListsKeys.detail(waitingListId),
    () => fetchWaitingList(waitingListId),
    {
      enabled: !!waitingListId,
      refetchInterval(data, query) {
        if (!refetchingStatus) {
          return false;
        }

        if (data) {
          const toRefetch = getPollingConditions(data);

          if (toRefetch) {
            return 1000;
          }
        }

        return false;
      },
    }
  );

  const { data: transaction } = useFetchTransaction(
    waitingListId,
    waitingList?.position ?? null
  );

  const { data: venueConditions } =
    useFetchWaitingListVenueConditions(waitingListId);
  const { xs } = useBreakpoint();
  const mobileDrawerOffset = 80;

  const { isCheckoutShown, setCheckoutShown } = useCheckoutStore();

  const [counter, setCounter] = useState(
    getCounterValue(waitingList, !!isHost).defaultValue
  );

  const editGuestList =
    isHost &&
    hasInvitations &&
    waitingList?.invitationMode === InvitationMode.ENABLED;

  const [numberOfParkingTickets, setNumberOfParkingTickets] = useState(0);

  const params: Omit<SurveyQuery, 'fangroup_id'> = {
    size: 9999,
    page: 0,
    waitinglist_id: waitingListId,
    extension_point: SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
  };
  const isMerchandiseDisplayMode =
    waitingList?.displayMode === DisplayMode.MERCHANDISE;

  const [restrictedWLModal, setRestrictedWLModal] = useState(false);

  const closeRestrictedWLModal = () => setRestrictedWLModal(false);
  const [isProtectedCodeModalShown, setProtectedCodeModalShown] =
    useState(false);

  const [isSSOWarningModalShown, setSSOWarningModalShown] = useState(false);

  const [isJoined, setIsJoined] = useState(
    !!(waitingList?.position?.status === WLPositionStatus.WAITING_SEAT)
  );
  const [isActive, setIsActive] = useState(
    !!(waitingList?.position?.status === WLPositionStatus.HAS_SEAT)
  );

  const [isAccepted, setIsAccepted] = useState(
    !!(
      waitingList?.seat?.status === SeatStatus.ACCEPTED ||
      waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED
    )
  );
  const requestTickets = !(isJoined || isActive || isAccepted);

  const [isRequestTicketsDrawerOpen, setRequestTicketsDrawerOpen] =
    useState(false);

  const toggleRequestTicketsPopup = () => {
    setRequestTicketsDrawerOpen(!isRequestTicketsDrawerOpen);
  };

  const { data: surveys } = useFetchWaitingListSurveyInstances(params);
  const { data: surveyAnswers } = useFetchWaitingListSurveyAnswers(
    waitingListId,
    surveys?.content[0]?.id ?? ''
  );
  const [isSurveyShown, setSurveyShown] = useState(false);

  const handleTabClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      window.scrollTo({ behavior: 'smooth', top: element.offsetTop });
    }
  };

  const { mutate: joinWishListAsFan, isLoading } = useJoinWaitingListAsFan(
    waitingList?.waitingListId ?? ''
  );

  const surveyTicketsAmount =
    isJoined || isAccepted || waitingList?.seat
      ? waitingList?.position?.numberOfSeats
      : counter;

  const joinWL = () => {
    joinWishListAsFan(
      {
        numberOfSeats: counter ?? 0,
        numberOfParkingTickets: numberOfParkingTickets ?? 0,
      },
      {
        onSuccess: async (response) => {
          setRefetchingStatus('join');
          notification.success({
            message: t('alert_waitinglist_reservation-confirmed'),
          });

          setPosition(response);

          if (waitingList?.directSalesEnabled) {
            queryClient.invalidateQueries(
              waitingListsKeys.detail(waitingListId)
            );
          }
          // else {
          //   setIsJoined(true);
          // }
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('join_waiting_notification_error'),
            description: t(err.response?.data.message),
          });

          if (err.response?.data.message === 'api_email_not_confirmed') {
            sessionStorage.setItem(SESSION_STORAGE_EMAIL_REMINDER, 'true');
            setEmailReminder(true);
          }
        },
      }
    );
  };

  useEffect(() => {
    setIsJoined(
      !!(waitingList?.position?.status === WLPositionStatus.WAITING_SEAT)
    );

    if (!refetchingStatus) {
      setCounter(getCounterValue(waitingList, isHost).defaultValue);
    }
    setIsActive(
      !!(waitingList?.position?.status === WLPositionStatus.HAS_SEAT)
    );
    setIsAccepted(
      !!(
        waitingList?.seat?.status === SeatStatus.ACCEPTED ||
        waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED
      )
    );
  }, [waitingList]);

  const tabItems: TabsProps['items'] = useMemo(() => {
    const tabs = [
      {
        key: t('waitinglist_experience_title'),
        text: waitingList?.description[lang] ?? waitingList?.description.en,
      },
      {
        key: t('waitinglist_infos_title'),
        text: waitingList?.translatedEventDescription,
      },
      {
        key: t('waitinglist_venue-conditions_title'),
        text:
          ((venueConditions &&
            (venueConditions[lang] ?? venueConditions?.en)) ||
            waitingList?.venueImageUrl ||
            waitingList?.venueName) &&
          !isMerchandiseDisplayMode,
      },
    ]
      .map((item) => {
        return { key: item.key, label: item.text ? item.key : undefined };
      })
      .filter((tabItem) => !!tabItem && !!tabItem.label);
    return tabs;
  }, [waitingList, venueConditions, ready]);

  useEffect(() => {
    if (
      fanGroupBySlugData?.membership?.request?.status ===
        RequestAccessStatus.ACCEPTED &&
      isProtectedCodeModalShown
    ) {
      setProtectedCodeModalShown(false);
    }
  }, [fanGroupBySlugData?.membership?.request?.status, isRefetching]);

  useEffect(() => {
    if (transaction?.status === 'PENDING') {
      setRefetchingStatus('pendingTransaction');
    }
  }, [transaction]);

  useEffect(() => {
    if (isAccepted && refetchingStatus === 'pendingTransaction') {
      setRefetchingStatus(null);
    }
  }, [waitingList?.seat?.status]);

  const isMerchandisedDisplayMode =
    waitingList?.displayMode === DisplayMode.MERCHANDISE;

  return (
    <div
      className={styles.waitingListDetails}
      style={{ backgroundColor: baseColor100 }}
    >
      <Banner
        waitingList={waitingList}
        image={
          waitingList?.waitingListImageUrl ||
          waitingList?.eventImageUrl ||
          waitingList?.venueImageUrl
        }
        isLoading={waitingListIsLoading}
      />

      <div
        className={styles.subheader}
        style={{
          backgroundColor: token.colorPrimaryBg,
          top: xs ? 45 : 60,
        }}
      >
        <Container>
          <div
            style={{
              width: '100%',
              position: 'relative',
            }}
          >
            <Tabs
              defaultActiveKey={t('waitinglist_infos_title')}
              items={tabItems}
              onChange={handleTabClick}
            />
            {waitingListIsLoading && !xs ? (
              <TicketsComponentSkeleton />
            ) : (
              !xs &&
              waitingList &&
              (editGuestList ? (
                <EditGuestListComponent />
              ) : requestTickets ? (
                <RequestTicketsComponent
                  setRestrictedWLModal={setRestrictedWLModal}
                  badgesState={badgesState?.state}
                  setSurveyShown={setSurveyShown}
                  joinWL={joinWL}
                  waitingList={waitingList}
                  survey={surveys?.content[0]}
                  surveyAnswers={surveyAnswers?.content[0]}
                  counter={counter ?? 0}
                  setCounter={setCounter}
                  loading={
                    isLoading || waitingListIsLoading || !!refetchingStatus
                  }
                  numberOfParkingTickets={numberOfParkingTickets ?? 0}
                  setNumberOfParkingTickets={setNumberOfParkingTickets}
                  setProtectedCodeModalShown={setProtectedCodeModalShown}
                  setSSOWarningModalShown={setSSOWarningModalShown}
                />
              ) : isJoined ? (
                <CancelTicketsComponent
                  setStartRefetching={setRefetchingStatus}
                  setSurveyShown={setSurveyShown}
                  waitingList={waitingList}
                  counter={counter ?? 0}
                  numberOfParkingTickets={numberOfParkingTickets ?? 0}
                  surveyAnswers={surveyAnswers?.content[0]}
                  setIsJoined={setIsJoined}
                  setPosition={setPosition}
                />
              ) : !isAccepted ? (
                <AcceptTicketsComponent
                  setStartRefetching={setRefetchingStatus}
                  waitingList={waitingList}
                  counter={counter ?? 0}
                  setIsActive={setIsActive}
                />
              ) : (
                waitingList?.seat && (
                  <DownloadTicketsComponent
                    setStartRefetching={setRefetchingStatus}
                    waitingList={waitingList}
                  />
                )
              ))
            )}
          </div>
        </Container>
      </div>
      <Container paddingTop={15}>
        <Space
          direction="vertical"
          className={!xs ? styles.content : styles.contentMobile}
        >
          {waitingListIsLoading ? (
            [...Array(2)].map(() => <Skeleton paragraph={{ rows: 3 }} title />)
          ) : (
            <Space direction="vertical" size="large">
              <Space direction="vertical">
                <Title level={4} style={{ marginLeft: 0 }}>
                  {(waitingList?.experienceName[lang] ??
                    waitingList?.experienceName.en) ||
                    (waitingList?.eventName[lang] ?? waitingList?.eventName.en)}
                </Title>
                {waitingList?.eventStartDate && !isMerchandisedDisplayMode && (
                  <Space>
                    <CalendarOutlined rev={undefined} />
                    <Text>{getFormattedDate(waitingList.eventStartDate)}</Text>

                    {waitingList?.eventEndDate && (
                      <Text>
                        {' - '}
                        {getFormattedDate(waitingList?.eventEndDate)}
                      </Text>
                    )}
                  </Space>
                )}

                {!isMerchandisedDisplayMode && (
                  <Text>
                    {waitingList?.venueName[lang] ?? waitingList?.venueName.en}
                  </Text>
                )}
                {waitingList?.displayName && !isMerchandisedDisplayMode && (
                  <Space>{waitingList.displayName}</Space>
                )}
              </Space>
              {(waitingList?.description[lang] ??
                waitingList?.description?.en) && (
                <InfoBlock
                  id={t('waitinglist_experience_title')}
                  title={t('waitinglist_experience_title')}
                  text={
                    waitingList?.description[lang] ??
                    waitingList?.description?.en
                  }
                />
              )}
              {waitingList?.translatedEventDescription && (
                <InfoBlock
                  id={t('waitinglist_infos_title')}
                  title={t('waitinglist_infos_title')}
                  text={waitingList?.translatedEventDescription}
                />
              )}
              {!isMerchandiseDisplayMode && (
                <InfoBlock
                  id={t('waitinglist_venue-conditions_title')}
                  title={t('waitinglist_venue-conditions_title')}
                  text={
                    venueConditions &&
                    (venueConditions[lang] ?? venueConditions?.en)
                  }
                >
                  <Space direction="vertical">
                    <Text type="secondary">
                      <EnvironmentOutlined rev={undefined} />{' '}
                      {[
                        waitingList?.translatedVenueName,
                        waitingList?.translatedVenueCity,
                        waitingList?.translatedVenueCountry,
                      ].join(', ')}
                    </Text>
                    <img
                      alt={
                        waitingList?.eventName[lang] ??
                        waitingList?.eventName.en
                      }
                      className={styles.image}
                      src={
                        waitingList?.venueImageUrl ??
                        waitingList?.eventImageUrl ??
                        waitingList?.waitingListImageUrl
                      }
                    />
                  </Space>
                </InfoBlock>
              )}
            </Space>
          )}
        </Space>
      </Container>

      {xs && (
        <Drawer
          placement="bottom"
          closable={false}
          onClose={toggleRequestTicketsPopup}
          open={isRequestTicketsDrawerOpen}
          styles={{
            wrapper: {
              borderRadius: '24px 24px 0 0',
              overflow: 'hidden',
              maxHeight: `calc(100vh - ${mobileDrawerOffset}px)`,
              height: 'unset',
            },
          }}
        >
          {waitingList &&
            (requestTickets ? (
              <RequestTicketsComponent
                setRestrictedWLModal={setRestrictedWLModal}
                badgesState={badgesState?.state}
                setSurveyShown={setSurveyShown}
                joinWL={joinWL}
                waitingList={waitingList}
                survey={surveys?.content[0]}
                surveyAnswers={surveyAnswers?.content[0]}
                counter={counter ?? 0}
                setCounter={setCounter}
                loading={
                  isLoading || waitingListIsLoading || !!refetchingStatus
                }
                numberOfParkingTickets={numberOfParkingTickets ?? 0}
                setNumberOfParkingTickets={setNumberOfParkingTickets}
                isMobile={true}
                setSSOWarningModalShown={setSSOWarningModalShown}
                setProtectedCodeModalShown={setProtectedCodeModalShown}
              />
            ) : isJoined ? (
              <CancelTicketsComponent
                setStartRefetching={setRefetchingStatus}
                setSurveyShown={setSurveyShown}
                waitingList={waitingList}
                counter={counter ?? 0}
                numberOfParkingTickets={numberOfParkingTickets ?? 0}
                surveyAnswers={surveyAnswers?.content[0]}
                setIsJoined={setIsJoined}
                setPosition={setPosition}
                isMobile={true}
              />
            ) : !isAccepted ? (
              <AcceptTicketsComponent
                setStartRefetching={setRefetchingStatus}
                isMobile={true}
                waitingList={waitingList}
                counter={counter ?? 0}
                setIsActive={setIsActive}
              />
            ) : (
              waitingList?.seat && (
                <DownloadTicketsComponent
                  setStartRefetching={setRefetchingStatus}
                  isMobile={true}
                  waitingList={waitingList}
                />
              )
            ))}
        </Drawer>
      )}
      <ProtectedCodeModal
        isProtectedCodeModalShown={isProtectedCodeModalShown}
        setProtectedCodeModalShown={setProtectedCodeModalShown}
      />
      {isSSOWarningModalShown && (
        <SSOWarning isSSOWarningModalShown={isSSOWarningModalShown} />
      )}
      <Modal
        title={getSingleTranslation(
          surveys?.content[0]?.survey?.title ?? [],
          lang
        )}
        centered
        footer={null}
        open={isSurveyShown && !!surveys?.content[0]}
        onCancel={() => setSurveyShown(false)}
        width={680}
        style={{ maxHeight: 'fit-content', margin: '24px' }}
        destroyOnClose={true}
      >
        {surveys?.content[0]?.survey?.description &&
          getSingleTranslation(surveys?.content[0]?.survey?.description, lang)}
        {surveys?.content[0] && (
          <Survey
            surveyInstance={surveys?.content[0]}
            cancel={() => setSurveyShown(false)}
            joinWL={joinWL}
            surveyAnswers={
              surveyAnswers?.content.length ? surveyAnswers?.content : undefined
            }
            ticketsAmount={surveyTicketsAmount}
            isJoined={isJoined}
          />
        )}
      </Modal>
      {xs && waitingList && (
        <RequestTicketsFooter
          setStartRefetching={setRefetchingStatus}
          openRequestTickets={toggleRequestTicketsPopup}
          editGuestList={editGuestList}
          waitingList={
            position?.status
              ? {
                  ...waitingList,
                  position: {
                    ...waitingList?.position,
                    status: position?.status,
                  },
                }
              : waitingList
          }
          isLoading={waitingListIsLoading}
        />
      )}
      {restrictedWLModal && (
        <Modal
          open={restrictedWLModal}
          title={t('wl_badge_error_title')}
          closeIcon={null}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={closeRestrictedWLModal}
            >
              {t('general_ok-btn')}
            </Button>,
          ]}
        >
          <Text>{t('wl_badge_error_instructions')}:</Text>
          <Space direction="vertical" style={{ marginTop: 16 }} size={24}>
            {!!badgesState?.badgeFanViews ? (
              badgesState?.badgeFanViews?.map((item) => {
                return (
                  <Space direction="vertical">
                    <Text strong>
                      {getSingleTranslation(item.displayedText, lang)}
                    </Text>

                    {getSingleTranslation(item.description, lang)}
                  </Space>
                );
              })
            ) : (
              <>{t('protected_wl_by_badge_default_text')}</>
            )}
          </Space>
        </Modal>
      )}
      {isCheckoutShown && (
        <CheckoutModal
          open={isCheckoutShown}
          onCancel={() => setCheckoutShown(false)}
        />
      )}
    </div>
  );
}

import { LoginEntity, routes } from '@seaters-app/constants';
import {
  getSlugFromUrl,
  useFetchPimAuthConfig,
  useFetchPimCheckTransaction,
  setSessionData,
  setAuthToken,
} from '@seaters-app/data-access';
import { Image, Space, QRCode, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PimLogo from '/loginPIM.png';

const { Title } = Typography;

type PimAuthType = {
  pimOption: LoginEntity;
};

export function PimAuth({ pimOption }: PimAuthType) {
  const { t } = useTranslation();
  const slug = getSlugFromUrl();

  const navigate = useNavigate();

  const { data: pimConfig, refetch } = useFetchPimAuthConfig(
    pimOption.providerId
  );

  const { data: checkTransaction } = useFetchPimCheckTransaction(
    pimOption.providerId,
    { reference: pimConfig?.uniqueReference || '' }
  );

  useEffect(() => {
    if (checkTransaction) {
      const { authenticationSuccess, pim } = checkTransaction;
      if (authenticationSuccess && pim.status === 'ACCEPTED') {
        setSessionData(authenticationSuccess);

        setAuthToken();

        navigate(`${routes.root}${slug ?? ''}`);
      }
    }
  }, [checkTransaction]);

  useEffect(() => {
    if (
      checkTransaction?.pim.status === 'EXPIRED' ||
      checkTransaction?.pim.status === 'REJECTED'
    ) {
      refetch();
    }
  }, [checkTransaction?.pim.status]);

  if (!pimConfig) return null;

  return (
    <Space align="center" direction="vertical" style={{ width: '100%' }}>
      <Space size="middle">
        <Title style={{ margin: 0 }} level={4}>
          {t('landing_title_log_in_met')}
        </Title>
        <Image src={PimLogo} width={48} preview={false} />
      </Space>
      <QRCode
        value={pimConfig.qrCodeUrl}
        status={
          checkTransaction?.pim.status === 'EXPIRED' ? 'expired' : 'active'
        }
      />
    </Space>
  );
}

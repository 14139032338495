import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Select, Switch, Tooltip } from 'antd';
import { DisplayMode } from '@seaters-app/constants';
import { baseColor600 } from '@seaters-app/ui';

interface BulkWishListsFormProps {
  // Define the props for the component here
}

const BulkWishListsForm: React.FC<BulkWishListsFormProps> = () => {
  // Define the state and event handlers here

  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        label={t('wl_set_direct-sales')}
        name="directSales"
        valuePropName="checked"
      >
        <Switch
          size="default"
          checkedChildren={<CheckOutlined rev={undefined} />}
        />
      </Form.Item>
      <Form.Item
        label={t('admin_waiting-list_direct-sales_without_seats')}
        name="directSalesWithoutSeat"
        valuePropName="checked"
      >
        <Switch
          size="default"
          checkedChildren={<CheckOutlined rev={undefined} />}
        />
      </Form.Item>
      <Form.Item
        label={
          <div>
            {t('display_mode_label')}{' '}
            <Tooltip title={t('display_mode_label-help')}>
              <InfoCircleOutlined
                rev={undefined}
                style={{ color: baseColor600 }}
              />
            </Tooltip>
          </div>
        }
        name="displayMode"
      >
        <Select
          size="middle"
          style={{ width: '100%' }}
          options={[
            {
              label: t('display_mode_classic'),
              value: DisplayMode.CLASSIC,
            },
            {
              label: t('display_mode_contest'),
              value: DisplayMode.CONTEST,
            },
            {
              label: t('display_mode_detailed'),
              value: DisplayMode.DETAILED,
            },

            {
              label: t('display_mode_informative'),
              value: DisplayMode.INFORMATIVE,
            },
            {
              label: t('display_mode_merchandise'),
              value: DisplayMode.MERCHANDISE,
            },
          ]}
        />
      </Form.Item>
    </>
  );
};

export default BulkWishListsForm;

import * as Sentry from '@sentry/react';
import { type EventHint } from '@sentry/react';

import { MODE, SENTRY_DSN } from '../../constants/env';
import { AxiosError } from 'axios';
import { ZodError } from 'zod';

// const IGNORE = //i;

export function sentryInit() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: MODE,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // This sets the sample rate at 20%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Called for message and error events
    beforeSend(event, hint) {
      const eventCopy = { ...event };

      const error = hint?.originalException as EventHint['originalException'];

      const filterStatuses = [400, 401];

      if (error instanceof AxiosError && error.isAxiosError) {
        // don't trace errors if status code is filter statuses
        // and when error message matches ignore regex
        if (
          // error.message.match(IGNORE) ||
          error.response &&
          filterStatuses.includes(error.response.status)
        ) {
          return null;
        }

        eventCopy.extra = {
          ...eventCopy.extra,
          ...error,
        };
      }

      if (error instanceof ZodError) {
        eventCopy.extra = {
          ...eventCopy.extra,
          validationError: {
            message: error.message,
          },
        };
      }

      return eventCopy;
    },
  });
}

export default sentryInit;

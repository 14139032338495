import { TranslationEntity } from '../types';

export enum SurveyExtensionPoint {
  BEFORE_JOINING_WAITINGLIST = 'BEFORE_JOINING_WAITINGLIST',
  BEFORE_PAYMENT = 'BEFORE_PAYMENT',
  AT_CHECKOUT = 'AT_CHECKOUT',
  AFTER_EVENT = 'AFTER_EVENT',
  AT_GUEST_INVITATION = 'AT_GUEST_INVITATION',
  AFTER_REGISTRATION = 'AFTER_REGISTRATION',
}

export enum SurveyStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

export enum QuestionStructureType {
  FREE_TEXT = 'FREE_TEXT',
  CHECK_BOX = 'CHECK_BOX',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum QuestionType {
  FREE_TEXT = 'FREE_TEXT',
  CHECKBOX = 'CHECKBOX',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MULTIPLE_CHOICE_DROPDOWN = 'MULTIPLE_CHOICE_DROPDOWN',
  MULTIPLE_CHOICE_MULTIPLEANSWER = 'MULTIPLE_CHOICE_MULTIPLEANSWER',
  MULTIPLE_CHOICE_WITH_OTHER = 'MULTIPLE_CHOICE_WITH_OTHER',
  MULTIPLE_CHOICE_WITH_OTHER_DROPDOWN = 'MULTIPLE_CHOICE_WITH_OTHER_DROPDOWN',
  MULTIPLE_CHOICE_AUTO_COMPLETE = 'MULTIPLE_CHOICE_AUTO_COMPLETE',
  MULTIPLE_CHOICE_AUTO_COMPLETE_MULTIPLEANSWER = 'MULTIPLE_CHOICE_AUTO_COMPLETE_MULTIPLEANSWER',
  MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE = 'MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE',
  MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE_MULTIPLEANSWER = 'MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE_MULTIPLEANSWER',
  STRUCTURED = 'STRUCTURED',
  STRUCTURED_MULTIPLEANSWER = 'STRUCTURED_MULTIPLEANSWER',
}
export enum SurveyInputMode {
  EDITABLE = 'EDITABLE',
  LOCKED = 'LOCKED',
}

import {
  getSingleTranslation,
  queryClient,
  useFetchWaitingListSurveyAnswersWithoutHeaders,
  useFetchWaitingListSurveyInstancesWithoutHeader,
} from '@seaters-app/data-access';
import { Container } from '@seaters-app/ui';
import { Survey } from '@seaters-app/ui-shared';
import {
  SurveyExtensionPoint,
  surveyInstancesKeys,
  SurveyQuery,
} from '@seaters-app/constants';
import { useParams } from 'react-router-dom';
import { Result, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;

function SurveyAfterEvent() {
  const { waitingListId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const { t, i18n } = useTranslation();
  const code = searchParams.get('token');
  const { language: lang } = i18n;

  const params: Omit<SurveyQuery, 'fangroup_id'> = {
    size: 9999,
    page: 0,
    code: code ?? '',
    waitinglist_id: waitingListId ?? '',
    extension_point: SurveyExtensionPoint.AFTER_EVENT,
  };

  const { data: surveys, isLoading: isSurveyLoading } =
    useFetchWaitingListSurveyInstancesWithoutHeader(params);

  const { data: surveyAnswers, isLoading: isSurveyAnswersLoading } =
    useFetchWaitingListSurveyAnswersWithoutHeaders(
      waitingListId ?? '',
      surveys?.content[0]?.id ?? '',
      code ?? ''
    );

  const onSuccess = () => {
    if (!!surveys?.content.length) {
      queryClient.invalidateQueries(
        surveyInstancesKeys.answersAfterEvent(
          {
            waitinglist_id: waitingListId,
            extension_point: SurveyExtensionPoint.AFTER_EVENT,
          },
          surveys.content[0].id ?? ''
        )
      );
    }
  };

  return (
    <Container paddingTop={24} paddingBottom={24}>
      {isSurveyLoading || isSurveyAnswersLoading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : !!surveyAnswers && !!surveyAnswers?.content.length ? (
        <Result
          status="success"
          title={t('survey_after_event_success_title')}
          subTitle={t('survey_after_event_success_description')}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title level={4}>
            {getSingleTranslation(
              surveys?.content[0]?.survey?.title ?? [],
              lang
            )}
          </Title>
          <Title level={5}>
            {surveys?.content[0]?.survey?.description &&
              getSingleTranslation(
                surveys?.content[0]?.survey?.description,
                lang
              )}
          </Title>
          <div style={{ width: '700px' }}>
            {surveys?.content[0] && (
              <Survey
                cancel={() => {}}
                surveyInstance={surveys?.content[0]}
                surveyAnswers={undefined}
                ticketsAmount={1}
                onSuccess={onSuccess}
              />
            )}
          </div>
        </div>
      )}
    </Container>
  );
}

export default SurveyAfterEvent;

import { Button } from '@seaters-app/ui';
import {
  LOCATION_STATES,
  LoginEntity,
  LookEntity,
  privacyPolicyUrl,
  routes,
  userAgreementUrl,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  queryClient,
  useFetchOauthAuthConfig,
} from '@seaters-app/data-access';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Checkbox, Space } from 'antd';
import { useTranslatedProperty } from '../../helpers';
import { StyledText } from '@seaters-app/ui-shared';
import { useState } from 'react';
import './index.css';

const cookieConsentExpirationTime = 60 * 60 * 24 * 30 * 12;

export function OAUTHLogin({ providerId }: LoginEntity) {
  const { data: oauthConfig } = useFetchOauthAuthConfig(providerId);
  const { t } = useTranslation();
  const slug = getSlugFromUrl();

  const { state: locationState } = useLocation();

  const [termsAndCondAccepted, setTermsAndCondAccepted] = useState(
    document.cookie.includes(`termsAndConditions-${slug}=true`)
  );
  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const termsAndConditionsText = useTranslatedProperty(
    fanGroupData?.optInTermsTexts
  );

  const fgTermsAndConditionsUrl = useTranslatedProperty(
    fanGroupData?.termsAndConditionsUrls
  );

  const fgPrivacyPolicyUrl = useTranslatedProperty(
    fanGroupData?.privacyPolicyUrls
  );

  const handleSetCookieData = () => {
    document.cookie = `termsAndConditions-${slug}=true; max-age=${cookieConsentExpirationTime}; path=/`;
  };

  if (!oauthConfig) return null;
  return (
    <Space direction="vertical" className="termsAndConds">
      <Link to={oauthConfig.uri} state={locationState}>
        <Button
          disabled={
            !termsAndCondAccepted ||
            locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.includes(
              routes.waitingList
            )
          }
          onClick={handleSetCookieData}
          size="large"
          type="primary"
          style={{ width: '100%' }}
          data-testid="signInSubmitButton"
        >
          {t('login_text_title')}
        </Button>
      </Link>
      <Checkbox
        defaultChecked={termsAndCondAccepted}
        onChange={(v) => setTermsAndCondAccepted(v.target.checked)}
      >
        {termsAndConditionsText ? (
          <StyledText text={termsAndConditionsText} />
        ) : (
          <>
            {t('signup_text_terms')}
            <a
              href={fgTermsAndConditionsUrl ?? userAgreementUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('terms_amper_conditions')}
            </a>{' '}
            {t('and_text')}{' '}
            <a
              href={fgPrivacyPolicyUrl ?? privacyPolicyUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('privacy_policy')}
            </a>
          </>
        )}
      </Checkbox>
    </Space>
  );
}

import {
  Breadcrumb,
  Flex,
  Popconfirm,
  Skeleton,
  Spin,
  Table,
  Typography,
  UploadFile,
  notification,
  theme,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  EventEntity,
  EventStatus,
  EventValidatorSchema,
  FormattedValuesWithTranslations,
  SEATERS_APP_BASE_URL,
  UploadFileType,
  WaitingListEntity,
  routes,
} from '@seaters-app/constants';
import {
  getTranslations,
  initialTranslations,
} from '../../../utils/helpers/getTranslations';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getSingleTranslation,
  requestAdminOneTimeImageUpload,
  uploadFile,
  useDeleteEvent,
  useFetchEvent,
  useFetchEventWls,
  useUpdateEvent,
} from '@seaters-app/data-access';
import { EventForm } from '../EventForm';
import { EventCreateFormType } from '../Create/Create';
import { prepareValues } from '../EventForm/helpers/prepareValues';
import { Button } from '@seaters-app/ui';
import styles from './event.module.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { WishListStatusTag } from '@seaters-app/ui-shared';

const { Title, Text } = Typography;

export function Event() {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    size: 10,
  });

  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const { eventId = '' } = useParams();

  const { data: event } = useFetchEvent(eventId);

  const { data: eventWls, isFetching } = useFetchEventWls(eventId, {
    itemOffset: (paginationParams.page - 1) * paginationParams.size,
    maxPageSize: paginationParams.size,
  });

  const { mutate: updateEvent, isLoading: isEventUpdating } =
    useUpdateEvent(eventId);

  const { mutateAsync: deleteEvent } = useDeleteEvent();

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<EventEntity>(['name', 'shortName', 'description'], event);

  const initialValues: EventCreateFormType =
    event && valuesWithTranslations
      ? {
          ...event,
          startDate: dayjs(event.startDate),
          endDate: event?.endDate ? dayjs(event?.endDate) : null,
          name: valuesWithTranslations.name,
          shortName: valuesWithTranslations.shortName,
          description: valuesWithTranslations.description,
        }
      : {
          startDate: null,
          endDate: null,
          name: initialTranslations,
          shortName: initialTranslations,
          description: initialTranslations,
          slug: '',
          status: EventStatus.DRAFT,
          venueConfigId: '',
          imageUrl: '',
        };

  const onSubmit = (values: EventCreateFormType) => {
    setSpinning(true);
    const preparedValues = prepareValues(values);

    const parsed = EventValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    updateEvent(parsed.data, {
      onSuccess: async (response) => {
        const imageUrlOrCanvas = values?.imageUrl as
          | string
          | HTMLCanvasElement
          | undefined;

        if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
          setUploadingImage(true);

          await requestAdminOneTimeImageUpload({
            id: response.id,
            fileName: 'file.png',
            entityType: 'events',
          }).then(async (requestOneTimeUploadResponse) => {
            if (requestOneTimeUploadResponse.token) {
              const formFile = new FormData();
              const blob = (await new Promise((resolve) =>
                imageUrlOrCanvas.toBlob(resolve)
              )) as Blob;
              formFile.append('file', blob);
              await uploadFile(
                formFile,
                requestOneTimeUploadResponse.token
              ).then(() => setUploadingImage(false));
            }
          });
        }

        notification.success({
          message: t('notification_success_event_updated'),
        });
        navigate(`..`);
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_event_updated'),
        });
      },
      onSettled: () => {
        setSpinning(false);
      },
    });
  };

  const handleDeleteEvent = (eventId: string) =>
    deleteEvent(
      { eventId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_event_removed'),
          });
          navigate('..');
        },
        onError: () => {
          notification.error({
            message: t('notification_error_event_removed'),
          });
        },
      }
    );

  const pagination = {
    total: eventWls?.totalSize,
    current: paginationParams.page,
    pageSize: paginationParams.size,
    onChange: (page: number, size: number) => {
      setPaginationParams({
        ...paginationParams,
        page: page,
        size: size,
      });
    },
  };
  const loading = spinning || uploadingImage;

  return event ? (
    <Spin tip={t('please_wait_message_text')} spinning={loading}>
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
        gap={12}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="..">{t('admin_events')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('admin_tabs_details')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.subheader}>
          <Title level={3}>{getSingleTranslation(event.name)}</Title>
          {event?.id && (
            <Popconfirm
              title={t('remove_event_text')}
              description={t('remove_event_confirmation_text')}
              cancelText={t('general_no')}
              onConfirm={() => handleDeleteEvent(eventId)}
              onOpenChange={() => console.log('open change')}
            >
              <Button
                size="large"
                type="text"
                icon={<DeleteOutlined rev={undefined} />}
                color={token.colorError}
              >
                {t('remove_event_text')}
              </Button>
            </Popconfirm>
          )}
        </div>
        <EventForm
          initialValues={initialValues}
          saveChanges={onSubmit}
          imageUrl={event?.imageUrl}
          isDetail
        />

        <Table
          dataSource={eventWls?.items}
          title={() => <Text strong>{t('admin_waiting-lists')}</Text>}
          size="middle"
          loading={isFetching}
          pagination={pagination}
        >
          <Table.Column
            title={t('admin_fan-group')}
            dataIndex="groupName"
            key="groupName"
            render={(groupName) => {
              return <span>{groupName['en']}</span>;
            }}
          />
          <Table.Column
            title={t('mwl_tab-wl')}
            dataIndex="translatedExperienceName"
            key="translatedExperienceName"
            render={(translatedExperienceName, wl: WaitingListEntity) => {
              return (
                <Link
                  to={`${SEATERS_APP_BASE_URL}${routes.admin}/${routes.wishLists}/${wl.waitingListId}`}
                >
                  <Button type="link">
                    {translatedExperienceName || wl?.translatedEventName}
                  </Button>
                </Link>
              );
            }}
          />

          <Table.Column
            title={t('admin_user_status')}
            dataIndex="waitingListStatus"
            key="waitingListStatus"
            render={(waitingListStatus) => {
              return <WishListStatusTag status={waitingListStatus} />;
            }}
          />
          <Table.Column
            title={t('admin_event_start-date')}
            dataIndex="eventStartDate"
            key="eventStartDate"
            render={(startDate) => {
              return (
                <span>
                  {dayjs(startDate).format('DD MMM YYYY HH:mm').toString()}
                </span>
              );
            }}
          />
          <Table.Column
            title={t('admin_category')}
            dataIndex="displayName"
            key="displayName"
          />
          <Table.Column
            title={t('admin_fan-groups_access-mode')}
            dataIndex="accessMode"
            key="accessMode"
          />
        </Table>
      </Flex>
    </Spin>
  ) : (
    <Skeleton />
  );
}

// eventId: 0954391a-93ed-4d5a-bf02-3b4504b05404

import React, { useState } from 'react';
import { FormInstance } from 'antd/es/form';

import { FanGroupPersonalizationFormType } from '@seaters-app/constants';
import { Skeleton, Spin, notification } from 'antd';
import {
  requestOneTimeUpload,
  uploadFile,
  useFanGroupStore,
  useUpdateFanGroup,
  useUpdateFanGroupBackgroundImage,
  useUpdateFanGroupClientLogo,
  useUpdateFanGroupCoverImage,
  useUpdateFanGroupProfileImage,
} from '@seaters-app/data-access';
import { FanGroupPersonalizationForm } from '../fanGroupForm/FanGroupPersonalizationForm';
import { useTranslation } from 'react-i18next';

export function FGConfigurationPersonalization() {
  const [spinning, setSpinning] = useState(false);
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const { mutate: updateFanGroup, isLoading: isFanGroupUpdating } =
    useUpdateFanGroup(fanGroup?.id ?? '');

  const {
    mutate: updateFanGroupProfileImage,
    isLoading: isFanGroupProfileImageUploading,
  } = useUpdateFanGroupProfileImage();

  const {
    mutate: updateFanGroupClientLogo,
    isLoading: isFanGroupClientLogoUploading,
  } = useUpdateFanGroupClientLogo();

  const {
    mutate: updateFanGroupBackgroundImage,
    isLoading: isFanGroupBackgroundImageUploading,
  } = useUpdateFanGroupBackgroundImage();

  const {
    mutate: updateFanGroupCoverImage,
    isLoading: isFanGroupCoverImageUploading,
  } = useUpdateFanGroupCoverImage();

  const isSubmitting =
    isFanGroupUpdating ||
    isFanGroupProfileImageUploading ||
    isFanGroupClientLogoUploading ||
    isFanGroupCoverImageUploading ||
    isFanGroupBackgroundImageUploading;

  const onSubmit = async (
    values: FanGroupPersonalizationFormType,
    form: FormInstance<any>
  ) => {
    const profileImageUrlOrCanvas = values?.profileImageUrl;
    const logoImageUrlOrCanvas = values?.logoImageUrl;
    const coverImageUrlOrCanvas = values?.coverImageUrl;
    const backgroundImageUrlOrCanvas = values?.backgroundImageUrl;
    setSpinning(true);
    if (typeof values?.color1 !== 'string' && fanGroup) {
      updateFanGroup(
        {
          ...fanGroup,
          ...fanGroup.operationParameters,
          color1: values?.color1?.hex,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_fg_color_updated'),
            });
          },
          onError: (err) => {
            console.error(err);
            notification.error({
              message: t('notification_error_fg_color_updated'),
            });
          },
        }
      );
    }

    if (
      profileImageUrlOrCanvas &&
      typeof profileImageUrlOrCanvas !== 'string' &&
      fanGroup
    ) {
      const requestOneTimeUploadResponse = await requestOneTimeUpload({
        fileName: 'file.png',
      });
      if (requestOneTimeUploadResponse.fileId) {
        const formFile = new FormData();
        const blob = (await new Promise((resolve) =>
          profileImageUrlOrCanvas?.toBlob(resolve)
        )) as Blob;
        formFile.append('file', blob);
        await uploadFile(
          formFile,
          requestOneTimeUploadResponse.path.slice(20, 52)
        );

        updateFanGroupProfileImage(
          {
            id: fanGroup?.id,
            fileId: requestOneTimeUploadResponse.fileId,
          },
          {
            onSuccess: (response) => {
              notification.success({
                message: t('notification_success_fg_image_uploaded', {
                  image: t('fgc_graphics_profile'),
                }),
              });
              form.setFieldValue('profileImageUrl', response.profileImageUrl);
            },
            onError: (err) => {
              console.error(err);
              notification.error({
                message: t('notification_error_fg_image_uploaded', {
                  image: t('fgc_graphics_profile'),
                }),
              });
            },
          }
        );
      }
    }

    if (
      logoImageUrlOrCanvas &&
      typeof logoImageUrlOrCanvas !== 'string' &&
      fanGroup
    ) {
      const requestOneTimeUploadResponse = await requestOneTimeUpload({
        fileName: 'file.png',
      });
      if (requestOneTimeUploadResponse.fileId) {
        const formFile = new FormData();
        const blob = (await new Promise((resolve) =>
          logoImageUrlOrCanvas?.toBlob(resolve)
        )) as Blob;
        formFile.append('file', blob);
        await uploadFile(
          formFile,
          requestOneTimeUploadResponse.path.slice(20, 52)
        );

        updateFanGroupClientLogo(
          { id: fanGroup?.id, fileId: requestOneTimeUploadResponse.fileId },
          {
            onSuccess: (response) => {
              notification.success({
                message: t('notification_success_fg_image_uploaded', {
                  image: t('fgc_graphics_logo'),
                }),
              });
              form.setFieldValue('logoImageUrl', response.logoImageUrl);
            },
            onError: (err) => {
              console.error(err);
              notification.error({
                message: t('notification_error_fg_image_uploaded', {
                  image: t('fgc_graphics_logo'),
                }),
              });
            },
          }
        );
      }
    }

    if (
      coverImageUrlOrCanvas &&
      typeof coverImageUrlOrCanvas !== 'string' &&
      fanGroup
    ) {
      const requestOneTimeUploadResponse = await requestOneTimeUpload({
        fileName: 'file.png',
      });
      if (requestOneTimeUploadResponse.fileId) {
        const formFile = new FormData();
        const blob = (await new Promise((resolve) =>
          values?.coverImageUrl?.toBlob(resolve)
        )) as Blob;
        formFile.append('file', blob);
        await uploadFile(
          formFile,
          requestOneTimeUploadResponse.path.slice(20, 52)
        );

        updateFanGroupCoverImage(
          { id: fanGroup?.id, fileId: requestOneTimeUploadResponse.fileId },
          {
            onSuccess: (response) => {
              notification.success({
                message: t('notification_success_fg_image_uploaded', {
                  image: t('fgc_graphics_cover'),
                }),
              });
              form.setFieldValue('coverImageUrl', response.coverImageUrl);
            },
            onError: (err) => {
              console.error(err);
              notification.error({
                message: t('notification_error_fg_image_uploaded', {
                  image: t('fgc_graphics_cover'),
                }),
              });
            },
          }
        );
      }
    }

    if (
      backgroundImageUrlOrCanvas &&
      typeof backgroundImageUrlOrCanvas !== 'string' &&
      fanGroup
    ) {
      const requestOneTimeUploadResponse = await requestOneTimeUpload({
        fileName: 'file.png',
      });
      if (requestOneTimeUploadResponse.fileId) {
        const formFile = new FormData();
        const blob = (await new Promise((resolve) =>
          values?.backgroundImageUrl?.toBlob(resolve)
        )) as Blob;
        formFile.append('file', blob);
        await uploadFile(
          formFile,
          requestOneTimeUploadResponse.path.slice(20, 52)
        );

        updateFanGroupBackgroundImage(
          { id: fanGroup.id, fileId: requestOneTimeUploadResponse.fileId },
          {
            onSuccess: (response) => {
              notification.success({
                message: t('notification_success_fg_image_uploaded', {
                  image: t('fgc_graphics_bg'),
                }),
              });
              form.setFieldValue(
                'backgroundImageUrl',
                response.backgroundImageUrl
              );
            },
            onError: (err) => {
              console.error(err);
              notification.error({
                message: t('notification_error_fg_image_uploaded', {
                  image: t('fgc_graphics_bg'),
                }),
              });
            },
          }
        );
      }
    }
    setSpinning(false);
  };
  return fanGroup ? (
    <Spin tip={t('please_wait_message_text')} spinning={spinning}>
      <FanGroupPersonalizationForm
        fanGroup={fanGroup}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </Spin>
  ) : (
    <Skeleton />
  );
}

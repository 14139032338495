import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Table, notification, Space, Popconfirm, theme, Flex } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { VenueEntity, VenueStatus, routes } from '@seaters-app/constants';
import { Typography } from 'antd';
import { getSingleTranslation, useDeleteVenue } from '@seaters-app/data-access';
import { useVenues } from './hooks/useVenues';
import { formatLocation } from '../../utils/helpers';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import Search from 'antd/es/input/Search';
import { VenueStatusTag } from '@seaters-app/ui-shared';

const { Title } = Typography;

const { Column } = Table;

export function Venues() {
  const { search } = useLocation();

  const {
    data,
    isLoading,
    handleTableChange,
    pagination,
    queryParams,
    setQuery,
  } = useVenues();

  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { mutateAsync: deleteVenue } = useDeleteVenue();

  const onSearch = (value: string) => {
    setQuery({ query: value, page: 1 });
  };

  const handleDeleteVenue = (venueId: string) =>
    deleteVenue(
      { venueId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_venue_removed'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_venue_removed'),
          });
        },
      }
    );

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <Title level={2}>{t('admin_venues')}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.query}
          placeholder={t('event_venue_search-btn')}
          onSearch={onSearch}
          size="large"
          style={{ width: '30%' }}
        />
        <Link to={routes.create} state={search}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
          >
            Add venue
          </Button>
        </Link>
      </div>
      <Table
        rowKey={(venue) => venue.id}
        dataSource={data?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('venue_details_name')}
          dataIndex="name"
          key="name"
          render={(name) => {
            return <span>{getSingleTranslation(name, lang)}</span>;
          }}
        />
        <Column
          title={t('admin_venues_location')}
          dataIndex="location"
          key="location"
          render={(_, venue: VenueEntity) => {
            return (
              <span>
                {formatLocation([
                  getSingleTranslation(venue.city, lang),
                  getSingleTranslation(venue.country, lang),
                ])}
              </span>
            );
          }}
        />
        <Column
          title={t('status_label')}
          dataIndex="status"
          key="status"
          render={(status: VenueStatus) => {
            return <VenueStatusTag status={status} />;
          }}
        />
        <Column
          key="actions"
          align="right"
          render={(_, venue: VenueEntity) => {
            return (
              <Space size="small">
                <Link to={venue.id} state={search}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined rev={undefined} />}
                  >
                    {t('admin_tabs_details')}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('delete_venue_title')}
                  description={t('delete_venue_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => handleDeleteVenue(venue.id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="link"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </Flex>
  );
}

import { Card, Popconfirm, Typography, notification, theme } from 'antd';
import styles from './tickets.module.css';
import { DownloadOutlined, CarOutlined } from '@ant-design/icons';

import { Button } from '@seaters-app/ui';
import {
  ParkingTicketsStatus,
  SeatDistributionMode,
  SeatStatus,
  WaitingListEntity,
  WaitingListStatusEnum,
} from '@seaters-app/constants';
import {
  downloadFileByURL,
  fetchWaitingList,
  useExportWaitingListParkingTicket,
  useExportWaitingListSeat,
  useFanCancelWaitingList,
  getSlugFromUrl,
  useFetchLook,
  useFetchWaitingList,
  TicketsUrl,
} from '@seaters-app/data-access';
import Meta from 'antd/es/card/Meta';
import { useTranslation } from 'react-i18next';
import { StyledText } from '@seaters-app/ui-shared';
import { useState } from 'react';
import { RefetchingStatus } from '../../WaitingListFanView';
import AdjustAttendeeList from './AdjustAttendeeList';

const { Text } = Typography;

interface DownloadTicketsComponentProps {
  waitingList: WaitingListEntity;
  isMobile?: boolean;
  setStartRefetching: (v: RefetchingStatus) => void;
}

function DownloadTicketsComponent({
  waitingList,
  isMobile,
  setStartRefetching,
}: DownloadTicketsComponentProps) {
  const {
    eventRequiredAttendeeInfo,
    translatedEventName,
    experienceName,
    eventName,
    waitingListId,
    seatDistributionMode,
    freeWaitingList,
    waitingListStatus,
    position: { numberOfSeats, numberOfParkingTickets },
    seat: {
      status,
      exportedParkingTicketUrl,
      exportedVoucherUrl,
      parkingTicketStatus,
    },
  } = waitingList;

  const { mutate: fanCancelWaitingList } =
    useFanCancelWaitingList(waitingListId);

  const rejectWaitingList = () => {
    fanCancelWaitingList(
      { waitingListId },
      {
        onSuccess: () => {
          setStartRefetching('cancel');
          notification.success({
            message: t('booking_cancellation_notification_success'),
          });
          // setIsActive(false);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('booking_cancellation_notification_error'),
          });
        },
      }
    );
  };
  const slug = getSlugFromUrl();
  const { data: lookData } = useFetchLook(slug ?? '');
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const [loading, setLoading] = useState<TicketsUrl>();

  const { mutateAsync: exportSeats, isLoading: isLoadingSeats } =
    useExportWaitingListSeat();

  const { mutateAsync: exportParkingTicket, isLoading: isLoadingParking } =
    useExportWaitingListParkingTicket();

  const isAbleToAdjustAttendees =
    eventRequiredAttendeeInfo &&
    status === SeatStatus.ACCEPTED &&
    seatDistributionMode === SeatDistributionMode.VOUCHER &&
    freeWaitingList;

  const isAbleToLeaveWL =
    waitingListStatus === WaitingListStatusEnum.OPEN &&
    seatDistributionMode === SeatDistributionMode.VOUCHER &&
    freeWaitingList &&
    lookData?.properties?.directSaleModeTicketsLimit;

  let refetchCount = 0;

  const wlRefetchRepeater = async (ticketsUrl: TicketsUrl) => {
    const waitingList = await fetchWaitingList(waitingListId);

    const ticketsReady = waitingList.seat[ticketsUrl];

    const resetCounter = () => (refetchCount = 0);

    if (refetchCount <= 5) {
      if (ticketsReady) {
        resetCounter();
        downloadFileByURL(
          waitingList.seat[ticketsUrl] || '',
          t('group_numberofseats')
        );
        setLoading(undefined);
      } else {
        refetchCount += 1;
        setTimeout(() => wlRefetchRepeater(ticketsUrl), 1000);
      }
    }
  };

  // Voucher
  const handleTicketsDownload = async () => {
    setLoading('exportedVoucherUrl');
    const waitingList = await fetchWaitingList(waitingListId);

    if (waitingList.seat.exportedVoucherUrl) {
      await downloadFileByURL(
        waitingList.seat.exportedVoucherUrl,
        t('group_numberofseats')
      );
      setLoading(undefined);
    } else {
      await exportSeats(
        { waitingListId },
        {
          onSuccess: async () => {
            wlRefetchRepeater('exportedVoucherUrl');
          },
          onError: () => {
            notification.error({
              message: t('tickets_generate_notification_error'),
            });
          },
        }
      );
    }
  };

  // Parking ticket

  const handleParkingTicketDownload = async () => {
    setLoading('exportedParkingTicketUrl');

    if (exportedParkingTicketUrl) {
      downloadFileByURL(exportedParkingTicketUrl, 'Parking tickets');
      setLoading(undefined);
    } else {
      await exportParkingTicket(
        { waitingListId },
        {
          onSuccess: async () => {
            wlRefetchRepeater('exportedParkingTicketUrl');
          },
          onError: () => {
            notification.error({
              message: t('tickets_generate_notification_error'),
            });
          },
        }
      );
    }
  };

  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={
        <Text className={styles.cardTitle}>
          {t('tickets_you_got', { numberOfSeats })}{' '}
          {!!numberOfParkingTickets &&
            t('parking_tickets_you_got', { numberOfParkingTickets })}
        </Text>
      }
    >
      <div className={styles.content}>
        {waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED && (
          <Meta description={t('waiting_for_seats_header')} />
        )}
        <Button
          size="large"
          type="primary"
          style={{ width: '100%' }}
          onClick={handleTicketsDownload}
          icon={<DownloadOutlined rev={undefined} />}
          loading={isLoadingSeats || loading === 'exportedVoucherUrl'}
          disabled={waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED}
        >
          {t('seat_infos_print_voucher-btn')}
        </Button>
        {isAbleToAdjustAttendees && (
          <AdjustAttendeeList waitingList={waitingList} />
        )}

        {parkingTicketStatus === ParkingTicketsStatus.ACCEPTED && (
          <Button
            size="large"
            type="primary"
            style={{ width: '100%' }}
            onClick={handleParkingTicketDownload}
            icon={<CarOutlined rev={undefined} />}
            loading={isLoadingParking || loading === 'exportedParkingTicketUrl'}
          >
            {t('seat_infos_print_parking-btn')}
          </Button>
        )}
        {isAbleToLeaveWL && (
          <Popconfirm
            title={t('wishlist_leave_wishlist_modal_title')}
            onConfirm={rejectWaitingList}
            description={
              <StyledText
                text={t('wishlist_leave_wishlist_modal_message', {
                  wishlistname:
                    translatedEventName ??
                    experienceName[lang] ??
                    eventName[lang],
                })}
              />
            }
            cancelText={t('general_no')}
          >
            <Button type="link" color={token.colorError}>
              {t('leave_wl_with_tickets_button_text')}
            </Button>
          </Popconfirm>
        )}
      </div>
    </Card>
  );
}

export default DownloadTicketsComponent;

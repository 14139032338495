import { Space, theme, Grid, Flex } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import { useIsCustomFlow } from '@seaters-app/data-access';
import styles from './components/styles.module.css';
import { WaitingListStatusEnum } from '@seaters-app/constants';
import { baseColor400 } from '@seaters-app/ui';
import { WishListBadgeStatusTag } from '@seaters-app/ui-shared';
import { useState } from 'react';
import GeneralInfo from './components/GeneralInfo';

const { useBreakpoint } = Grid;

type WishListSummaryType = {
  data: {
    isHost: boolean;
    generalInfo: {
      imageUrl: string;
      title?: string;
      startDate: string;
      status?: WaitingListStatusEnum;
      venue: string;
      category: string;
    };
    tableInfo: Array<
      { label: string; value: number | string; isCustom?: boolean }[]
    >;
  };
  isDetails?: boolean;
};

interface DataType {
  data: {
    isHost: boolean;
    generalInfo: {
      imageUrl: string;
      title?: string;
      startDate: string;
      status?: WaitingListStatusEnum;
      venue: string;
      category: string;
    };
    tableInfo: Array<
      {
        label: string;
        value: number | string;
        isCustom?: boolean;
        isHidden?: boolean;
      }[]
    >;
  };
  isCustom?: boolean;
  isDetailsShown?: boolean;
  isDetails?: boolean;
}

function WLData({ data, isDetailsShown, isCustom, isDetails }: DataType) {
  const { token } = theme.useToken();
  const screens = useBreakpoint();

  return (
    <div
      style={{
        display: !isDetailsShown ? 'inline-flex' : '',
        width: screens.xs ? '100%' : 'auto',
        borderLeft:
          screens.xs && isDetailsShown
            ? `1px solid ${token.colorBorder}`
            : 'none',
        borderRight:
          screens.xs && isDetailsShown
            ? `1px solid ${token.colorBorder}`
            : 'none',
      }}
    >
      {data.tableInfo.map((column) => (
        <table
          className={isDetails ? styles.tableDetails : styles.tableList}
          style={{
            borderCollapse: 'collapse',
            borderLeft: !screens.xs ? `1px solid ${token.colorBorder}` : 'none',
            color: token.colorTextSecondary,
            width: isDetailsShown || screens.xs ? '100%' : 'auto',
          }}
        >
          {column.map((item) => {
            if ((!isCustom && item.isCustom) || item.isHidden) return null;
            return (
              <tr
                className={
                  !isDetailsShown ? styles.tableRow : styles.tableRowMobile
                }
              >
                <td className={styles.tableCell}>
                  <Flex gap={6}>
                    {item?.label && <Text type="secondary">{item.label}</Text>}
                    <div style={{ minWidth: 50 }}>
                      <Text strong>{item?.value}</Text>
                    </div>
                  </Flex>
                </td>
              </tr>
            );
          })}
        </table>
      ))}
    </div>
  );
}

export function WishListSummary({ data, isDetails }: WishListSummaryType) {
  const { token } = theme.useToken();

  const isCustom = useIsCustomFlow();
  const screens = useBreakpoint();
  const [isDetailsShown, setDetailsShown] = useState(false);
  const toggleDetails = () => {
    setDetailsShown(!isDetailsShown);
  };

  return Object.keys(screens).length ? (
    <div
      style={{
        borderRadius: !isDetailsShown ? 8 : 0,
        boxShadow: !screens.xs ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none',
        border: !screens.xs ? `1px solid ${baseColor400}` : 'none',
        display: 'flex',
        justifyContent: 'space-between',
        background: 'white',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {!screens.xs ? (
        <>
          <GeneralInfo
            generalInfo={data.generalInfo}
            isCustom={isCustom}
            isDetails={isDetails}
          />
          <WLData
            data={data}
            isCustom={isCustom}
            isDetailsShown={isDetailsShown}
          />
        </>
      ) : isDetails ? (
        <div className={styles.detailsMobile}>
          <div className={styles.detailsMobileVisible}>
            <GeneralInfo
              generalInfo={data.generalInfo}
              isCustom={isCustom}
              part="image"
            />
            <Space direction="vertical">
              {data.generalInfo.status && (
                <WishListBadgeStatusTag status={data.generalInfo.status} />
              )}
              <Text strong>{data.generalInfo.title}</Text>
            </Space>
            {!isDetailsShown ? (
              <InfoCircleOutlined
                style={{
                  marginLeft: 'auto',
                  marginRight: '0',
                  alignSelf: 'flex-start',

                  color: token.colorPrimary,
                }}
                rev={undefined}
                onClick={toggleDetails}
              />
            ) : (
              <CloseOutlined
                style={{
                  marginLeft: 'auto',
                  marginRight: '0',
                  alignSelf: 'flex-start',

                  color: token.colorPrimary,
                }}
                rev={undefined}
                onClick={toggleDetails}
              />
            )}
          </div>
          {isDetailsShown && (
            <Space direction="vertical" style={{ width: '100%' }}>
              <GeneralInfo
                generalInfo={data.generalInfo}
                isCustom={isCustom}
                part="info"
                isDetails
              />
              <WLData
                data={data}
                isCustom={isCustom}
                isDetailsShown={isDetailsShown}
                isDetails
              />
            </Space>
          )}
        </div>
      ) : (
        <div
          style={{
            borderRadius: 8,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            border: `1px solid ${baseColor400}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: 'white',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <GeneralInfo
            generalInfo={data.generalInfo}
            isCustom={isCustom}
            isMobile
            isDetails={false}
          />
          <WLData
            data={data}
            isCustom={isCustom}
            isDetailsShown={isDetailsShown}
          />
        </div>
      )}
    </div>
  ) : null;
}

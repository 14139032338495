import {
  Alert,
  Collapse,
  Divider,
  Form,
  Grid,
  InputNumber,
  Modal,
  notification,
  Space,
  Table,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import AntLayout from 'antd/es/layout';
import {
  getInvitationStatus,
  GuestItem,
  invitationStatusTranslation,
  useCreateWaitingListInvitation,
  useFetchHost,
  useFetchWaitingList,
  useFetchWaitingListSurveyInstances,
  useGuestListStore,
  useIsCustomFlow,
  useReserveGuestListStore,
  useUpdateWaitingListInvitation,
} from '@seaters-app/data-access';
import {
  CheckOutlined,
  EditOutlined,
  MinusOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import {
  GuestEntity,
  ImportanceEnum,
  InvitationEntity,
  InvitationsListEntity,
  InvitationStatusEnum,
  SemanticNameEnum,
  SESSION_STORAGE_TOTAL_TICKETS,
  SurveyExtensionPoint,
  waitingListInvitationsKeys,
  WLPositionStatus,
} from '@seaters-app/constants';
import { Button, CollapseButton, statusToBadge } from '@seaters-app/ui';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import './styles.css';
import { useAcceptInvitation, useDeclineInvitation } from './hooks';
import { useQueryClient } from '@tanstack/react-query';
import { PublicOfficialModal } from '../PublicOfficialModal';
import { InternalEmployeeModal } from '../InternalEmployeeModal';
import { CheckoutModalHost } from '../CheckoutModal/CheckoutModalHost';
import { EditContactModal } from '../ContactList/EditContactModal';
import { useEditContact } from '../../utils/useEditContact';
import { useGuestInvitation } from './helpers/guestInvitation';
import { InvitationsActions } from './ActionsButtons/InvitationsActions';

const { Footer: AntFooter } = AntLayout;

const { Text } = Typography;

const { useBreakpoint } = Grid;

const { Panel } = Collapse;

dayjs.extend(relativeTime);

const statusesToIgnoreTickets = [
  InvitationStatusEnum.CANCELLED,
  InvitationStatusEnum.RSVP_DECLINED,
  InvitationStatusEnum.DEMAND_REFUSED,
];

export function InvitationList({
  waitingListId,
  invitations,
  replaceGuestFromReserveToMain,
  isFetching,
}: {
  waitingListId: string;
  invitations: InvitationsListEntity;
  replaceGuestFromReserveToMain?: (guests: GuestItem[]) => void;
  isFetching?: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const screens = useBreakpoint();
  const { token } = theme.useToken();
  const { data: surveyInstances } = useFetchWaitingListSurveyInstances({
    waitinglist_id: waitingListId,
    extension_point: SurveyExtensionPoint.AT_CHECKOUT,
  });

  const defaultTotalTickets = invitations.content
    .filter((invitation) => {
      const status = getInvitationStatus(invitation);

      return (
        status &&
        !statusesToIgnoreTickets.includes(status) &&
        invitation.importance === ImportanceEnum.REQUIRED
      );
    })
    .map((invitation, index) => {
      return (
        invitation.nbrOfRequestedSeats || invitation.numberOfNonAllocatedSeats
      );
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const totalTicketsToIgnore = invitations.content
    .filter((invitation) => {
      const status = getInvitationStatus(invitation);

      return status && statusesToIgnoreTickets.includes(status);
    })
    .map(
      (invitation) =>
        invitation.nbrOfRequestedSeats || invitation.numberOfNonAllocatedSeats
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const [specialGuestModal, setSpecialGuestModal] = useState<boolean>(false);

  const [businessReason, setBusinessReason] = useState<string>();
  const [totalTicketsChange, setTotalTicketsChange] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [publicOfficialModal, setPublicOfficialModal] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [businessReasonModal, setBusinessReasonModal] = useState(false);

  const [emptyBusinessReasonModal, setEmptyBusinessReasonModal] =
    useState(false);

  const [checkoutModalOpened, setCheckoutModalOpened] =
    useState<boolean>(false);

  const [selectedGuest, setSelectedGuest] = useState<InvitationEntity | null>(
    null
  );

  const { data: host } = useFetchHost();

  const { data: waitingList } = useFetchWaitingList(waitingListId);

  // *** Guest list store

  const setTickets = useGuestListStore((state) => state.setTickets);
  const addGuest = useGuestListStore((state) => state.add);
  const addAllGuests = useGuestListStore((state) => state.addAll);
  const reset = useGuestListStore((state) => state.reset);
  const guests = useGuestListStore((state) => state.guests);
  const totalTickets = useGuestListStore((state) => state.totalTickets);
  const setTotalTickets = useGuestListStore((state) => state.setTotalTickets);
  const groupGuests = useGuestListStore((state) => Object.values(state.guests));
  const removeGuest = useGuestListStore((state) => state.remove);

  // ***

  // *** Reserve guest list store

  const addReserveGuests = useReserveGuestListStore((state) => state.addAll);
  const reserveGuests = useReserveGuestListStore((state) =>
    Object.values(state.guests)
  );
  const removeReserveGuest = useReserveGuestListStore((state) => state.remove);

  // ***

  const hostGuestIndex = groupGuests.findIndex(
    (guest) => guest.guest.guestId === host?.userId
  );

  if (hostGuestIndex > 0) {
    groupGuests.splice(0, 0, groupGuests.splice(hostGuestIndex, 1)[0]);
  }

  const allocatedIndex = groupGuests.findIndex((guest) => guest.isToAllocated);

  if (allocatedIndex > 0) {
    groupGuests.splice(
      hostGuestIndex + 1,
      0,
      groupGuests.splice(allocatedIndex, 1)[0]
    );
  }

  const guestInvitation = useGuestInvitation();

  const nonAllocatedGuests = groupGuests.filter((guest) => {
    return !guest?.isToAllocated;
  });

  const nonAllocatedTickets = nonAllocatedGuests
    .map((el) => {
      const invitation = guestInvitation(el.guest.guestId);
      if (
        (invitation &&
          ((invitation?.status === 'APPROVED' &&
            (!invitation.position ||
              invitation.position.seatsRequestStatus === 'DECLINED')) ||
            invitation?.status === 'REJECTED' ||
            invitation?.status === 'CANCELLED')) ||
        invitation?.importance === ImportanceEnum.OPTIONAL
      ) {
        return 0;
      }
      return el.tickets;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const allocatedInvitation = groupGuests.find((guest) => guest.isToAllocated);

  const getRemainingTickets = () => {
    if (allocatedInvitation && guests[allocatedInvitation.guest.guestId]) {
      return guests[allocatedInvitation.guest.guestId].tickets;
    } else {
      return 0;
    }
  };

  const setAllocatedTickets = (tickets: number) => {
    const invitation = guestInvitation(
      allocatedInvitation?.guest.guestId ?? ''
    );
    const status = getInvitationStatus(invitation);
    if (allocatedInvitation) {
      if (status) {
        if (!statusesToIgnoreTickets.includes(status)) {
          setTickets(allocatedInvitation.guest.guestId, tickets);
        }
      } else {
        setTickets(allocatedInvitation.guest.guestId, tickets);
      }
    }
  };

  const reserveListTotal = reserveGuests.length;

  const [form] = Form.useForm();

  const { mutateAsync: updateWaitingListInvitation } =
    useUpdateWaitingListInvitation(waitingListId);

  const { mutateAsync: createWaitingListInvitation } =
    useCreateWaitingListInvitation(waitingListId);

  const handleDeclineInvitation = useDeclineInvitation(waitingListId);
  const handleAcceptInvitation = useAcceptInvitation(waitingListId);

  const {
    isEditContactModal,
    setIsEditContactModal,
    showEditModal,
    guestToEdit,
    setGuestToEdit,
  } = useEditContact();

  const showBusinessReasonModal = () => {
    setBusinessReasonModal(true);
  };

  const hideBusinessReasonModal = () => {
    setBusinessReasonModal(false);
  };

  const handleRemoveGuest = (guest: GuestEntity, tickets: number) => {
    removeGuest(guest.guestId);

    if (reserveGuests.length) {
      const guestToMove = reserveGuests[0];
      removeReserveGuest(guestToMove.guest.guestId);
      addGuest(guestToMove.guest, tickets);
    }
    if (
      guest.guestId !== allocatedInvitation?.guest?.guestId ??
      'to_allocated_id'
    ) {
      setTickets(
        allocatedInvitation?.guest?.guestId ?? 'to_allocated_id',
        (allocatedInvitation?.tickets ?? 0) + tickets
      );
    }
  };

  const submitRequest = async (specialGuests?: {
    [guestId: string]: string;
  }) => {
    setLoading(true);
    const maxOrder = Math.max(...invitations.content.map((i) => i.order)) ?? -1;

    let amountOfOrderedInvitations = 0;

    reserveGuests.map(async ({ guest, tickets }, index) => {
      const invitation = guestInvitation(guest.guestId);
      if (invitation) {
        if (tickets !== invitation.nbrOfRequestedSeats) {
          await updateWaitingListInvitation(
            {
              invitationId: invitation?.id,
              body: {
                numberOfSeats: tickets,
                importance: ImportanceEnum.OPTIONAL,
                order: invitation.order,
              },
            },
            {
              onError: (err) => {
                console.error(err);
                notification.error({
                  message: `Invitation for ${guest.firstName} ${guest.lastName} was not updated`,
                  description: err.response?.data.message,
                });
              },
              onSettled: () => {
                queryClient.invalidateQueries(
                  waitingListInvitationsKeys.allPerWaitingList(waitingListId)
                );
              },
            }
          );
        }
      } else {
        const body = {
          numberOfSeats: tickets,
          importance: ImportanceEnum.OPTIONAL,
          order: maxOrder + 1 + amountOfOrderedInvitations,
        };
        amountOfOrderedInvitations += 1;

        await createWaitingListInvitation(
          {
            guestId: guest.guestId,
            body: body,
          },
          {
            onError: (err) => {
              console.error(err);
              notification.error({
                message: `Invitation for ${guest.firstName} ${guest.lastName} was not created`,
                description: err.response?.data.message,
              });
            },
            onSettled: () => {
              queryClient.invalidateQueries(
                waitingListInvitationsKeys.allPerWaitingList(waitingListId)
              );
            },
          }
        );
      }
    });

    groupGuests.map(async ({ guest, tickets, isToAllocated }, index) => {
      const invitation = guestInvitation(guest.guestId);
      const isHost = guest.guestId === host?.userId;

      const toAllocateBody = {
        numberOfSeats: 0,
        importance: ImportanceEnum.REQUIRED,
        numberOfNonAllocatedSeats: tickets, // numberOfNonAllocatedSeats for all
        invitationReason: businessReason,
        order: 1,
      };

      const body = {
        numberOfSeats: tickets,
        importance: ImportanceEnum.REQUIRED,
        order: isHost ? 0 : maxOrder + 1 + amountOfOrderedInvitations,
      };

      if (specialGuests && specialGuests[guest.guestId]) {
        body.specialGuestId = specialGuests[guest.guestId];
      }

      if (!isToAllocated) {
        if (invitation && invitation.id) {
          if (
            tickets !== invitation.nbrOfRequestedSeats ||
            invitation.importance === ImportanceEnum.OPTIONAL
          ) {
            updateWaitingListInvitation(
              {
                invitationId: invitation.id,
                body: body,
              },
              {
                onError: (err) => {
                  console.error(err);
                  notification.error({
                    message: `Invitation for ${guest.firstName} ${guest.lastName} was not updated`,
                    description: err.response?.data.message,
                  });
                },
                onSettled: () => {
                  queryClient.invalidateQueries(
                    waitingListInvitationsKeys.allPerWaitingList(waitingListId)
                  );
                },
              }
            );
          }
          // order: 0 - host, 1 - to allocate
        } else {
          if (tickets) {
            amountOfOrderedInvitations += 1;

            await createWaitingListInvitation(
              {
                guestId: isToAllocated && host ? host.userId : guest.guestId,
                body: isToAllocated ? toAllocateBody : body,
                guest: guest,
                errorMessage: t('invitation_create_notification_error'),
                errorDescription: t('api_guest_already_invited'),
              },
              {
                onError: (err) => {
                  console.error(err);
                  notification.error({
                    message: `Invitation for ${guest.firstName} ${guest.lastName} was not created`,
                    description: err.response?.data.message,
                  });
                },
                onSettled: () => {
                  queryClient.invalidateQueries(
                    waitingListInvitationsKeys.allPerWaitingList(waitingListId)
                  );
                },
              }
            );
          }
        }
      } else if (isToAllocated) {
        // const invitation = guestInvitation(host?.userId ?? '');
        if (!invitation && tickets) {
          await createWaitingListInvitation({
            guestId: host?.userId ?? '',
            body: toAllocateBody,
            guest: guest,
            errorMessage: t('invitation_create_notification_error'),
            errorDescription: t('api_guest_already_invited'),
          });
        } else if (invitation) {
          const invitationToAllocate = guestInvitation(
            allocatedInvitation?.guest.guestId ?? ''
          );
          await updateWaitingListInvitation({
            invitationId: invitationToAllocate?.id ?? '',
            body: toAllocateBody,
          });
        }
      }
    });
    // Seems like this is not needed, but to be tested more
    // const updatedInvitations = await fetchWaitingListInvitations(waitingListId);
    // const newInvitationsCount = updatedInvitations.content.length;

    // if (allocatedInvitation) {
    //   const invitationToAllocate = guestInvitation(
    //     allocatedInvitation?.guest.guestId
    //   );
    //   const toAllocateBody = {
    //     numberOfSeats: 0,
    //     importance: ImportanceEnum.REQUIRED,
    //     numberOfNonAllocatedSeats:
    //       allocatedInvitation.tickets +
    //       (groupGuests.length - newInvitationsCount), // numberOfNonAllocatedSeats for all
    //     invitationReason: businessReason,
    //     order: 1,
    //   };

    //   if (invitationToAllocate?.id) {
    //     await updateWaitingListInvitation({
    //       invitationId: invitationToAllocate?.id ?? '',
    //       body: toAllocateBody,
    //     });
    //   }
    // }
    queryClient.invalidateQueries(
      waitingListInvitationsKeys.allPerWaitingList(waitingListId)
    );
    setLoading(false);

    await queryClient.invalidateQueries(
      waitingListInvitationsKeys.allPerWaitingList(waitingListId)
    );

    setSpecialGuestModal(false);
    setConfirmationModal(false);
    setBusinessReason(undefined);
  };

  const handleRequest = () => {
    const nonAllocatedInvitation = invitations.content.find(
      (invitation) => invitation.numberOfNonAllocatedSeats
    );

    const isToAllocatedAdded =
      !nonAllocatedInvitation && allocatedInvitation?.tickets;

    const isToAllocatedIncreased =
      allocatedInvitation &&
      nonAllocatedInvitation &&
      allocatedInvitation.tickets >
        nonAllocatedInvitation.numberOfNonAllocatedSeats;

    if (
      (isToAllocatedIncreased || isToAllocatedAdded) &&
      !businessReason?.length
    ) {
      setEmptyBusinessReasonModal(true);
    } else if (waitingList?.specialGuestEnabled) {
      setSpecialGuestModal(true);
    } else {
      setConfirmationModal(true);
    }
  };

  const handleTotalTickets = (value: number) => {
    setTotalTickets(value);

    sessionStorage.setItem(SESSION_STORAGE_TOTAL_TICKETS, value.toString());
  };

  const showChangeTickets = () => {
    setTotalTicketsChange(true);
  };

  const hideChangeTickets = () => {
    setTotalTicketsChange(false);
  };

  const reservedTicketsCount = reserveGuests.reduce((acc, curr) => {
    return acc + curr.tickets;
  }, 0);

  const handleTotalTicketsSubmit = () => {
    const allocatedSeats = totalTickets - nonAllocatedTickets;

    if (allocatedInvitation && waitingList) {
      setAllocatedTickets(allocatedSeats);
    }

    if (!allocatedInvitation && allocatedSeats > 0) {
      addGuest(
        {
          guestId: 'to_allocated_id',
          firstName: 'string',
          customInfos: [],
          guestEmail: '',
          mobileCountryCallingCode: '',
          mobileLocalNumber: '',
          lang: '',
          lastName: '',
        },
        allocatedSeats,
        true
      );
    }

    const slotsLeft = totalTickets - nonAllocatedTickets;
    setAllocatedTickets(totalTickets - nonAllocatedTickets);

    if (
      slotsLeft > 0 &&
      reserveGuests.length &&
      replaceGuestFromReserveToMain
    ) {
      replaceGuestFromReserveToMain(reserveGuests);
      setTickets(
        allocatedInvitation?.guest.guestId ?? 'to_allocated_id',
        totalTickets -
          nonAllocatedTickets -
          (reservedTicketsCount > slotsLeft ? slotsLeft : reservedTicketsCount)
      );
    }

    hideChangeTickets();
  };

  const handlePublicOfficialModal = () => {
    setPublicOfficialModal(true);
  };

  const confirmAcceptInvitation = (invitation: InvitationEntity) => {
    if (
      waitingList?.eventRequiredAttendeeInfo ||
      !!surveyInstances?.content.length
    ) {
      setSelectedGuest(invitation);
      setCheckoutModalOpened(true);
    } else {
      handleAcceptInvitation(invitation.id);
    }
  };

  const isCustom = useIsCustomFlow();

  if (!waitingList) {
    return null;
  }

  // *** Disabled conditions
  const isReserveTicketsToRequest = reserveGuests.filter((guest) => {
    const invitation = guestInvitation(guest?.guest?.guestId);
    const status = getInvitationStatus(invitation);
    return !status || Number(invitation?.nbrOfRequestedSeats) < guest.tickets;
  }).length;

  const isTicketsToRequest = groupGuests.reduce((acc, curr) => {
    const invitation = guestInvitation(curr?.guest?.guestId);
    if (
      (!invitation?.numberOfNonAllocatedSeats &&
        getInvitationStatus(invitation) ===
          InvitationStatusEnum.BEING_REVIEWED &&
        invitation?.nbrOfRequestedSeats &&
        invitation?.nbrOfRequestedSeats < curr.tickets) ||
      invitation?.importance === ImportanceEnum.OPTIONAL
    ) {
      acc =
        acc ||
        !!(
          curr.tickets - invitation?.nbrOfRequestedSeats ||
          invitation.importance === ImportanceEnum.OPTIONAL
        );
    }

    if (
      invitation?.numberOfNonAllocatedSeats &&
      invitation?.numberOfNonAllocatedSeats < curr.tickets
    ) {
      acc = acc || !!(curr.tickets - invitation?.numberOfNonAllocatedSeats);
    }

    const status = getInvitationStatus(invitation);

    if (!status) {
      acc = acc || !!curr.tickets;
    }

    return acc;
  }, false);

  const allocatedInv = guestInvitation(
    allocatedInvitation?.guest.guestId ?? ''
  );

  const disabledChangeTickets =
    allocatedInv &&
    (allocatedInv?.position?.status === WLPositionStatus.HAS_SEAT ||
      (allocatedInv?.status === 'APPROVED' &&
        (!allocatedInv?.position ||
          allocatedInv?.position?.seatsRequestStatus === 'DECLINED')) ||
      allocatedInv?.status === 'REJECTED' ||
      allocatedInv?.status === 'CANCELLED');
  // ***

  const groupId = waitingList?.groupId ?? '';

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    setBusinessReason(
      invitations.content.find((item) => !!item.numberOfNonAllocatedSeats)
        ?.invitationReason
    );
  }, [invitations.content.length]);

  useEffect(() => {
    const allGuests: { [guestId: string]: GuestItem } = {};
    const reserveGuests: { [guestId: string]: GuestItem } = {};

    const sortedInvitations = invitations.content.sort(
      (a, b) => a.order - b.order
    );

    const mainInvitations = sortedInvitations.filter(
      (el) => el.importance === ImportanceEnum.REQUIRED
    );

    const reserveInvitations = sortedInvitations.filter(
      (el) => el.importance === ImportanceEnum.OPTIONAL
    );

    mainInvitations.forEach((invitation) => {
      if (invitation.numberOfNonAllocatedSeats) {
        allGuests[invitation.guest.guestId] = {
          guest: invitation.guest,
          tickets: invitation.numberOfNonAllocatedSeats,
          isToAllocated: true,
          order: invitation.order,
        };
      } else {
        allGuests[invitation.guest.guestId] = {
          guest: invitation.guest,
          tickets: invitation.nbrOfRequestedSeats,
          order: invitation.order,
        };
      }
    });
    addAllGuests(allGuests);

    reserveInvitations.forEach((invitation) => {
      if (invitation.numberOfNonAllocatedSeats) {
        reserveGuests[invitation.guest.guestId] = {
          guest: invitation.guest,
          tickets: invitation.numberOfNonAllocatedSeats,
          isToAllocated: true,
          order: invitation.order,
        };
      } else {
        reserveGuests[invitation.guest.guestId] = {
          guest: invitation.guest,
          tickets: invitation.nbrOfRequestedSeats,
          order: invitation.order,
        };
      }
    });

    addReserveGuests(reserveGuests);

    handleTotalTickets(defaultTotalTickets);
  }, [invitations]);
  useEffect(() => {
    if (
      allocatedInvitation?.tickets &&
      allocatedInvitation?.tickets > 0 &&
      reserveGuests.length &&
      replaceGuestFromReserveToMain
    ) {
      replaceGuestFromReserveToMain(reserveGuests);
      setTickets(
        allocatedInvitation?.guest.guestId ?? 'to_allocated_id',
        allocatedInvitation?.tickets -
          (reservedTicketsCount > allocatedInvitation?.tickets
            ? allocatedInvitation?.tickets
            : reservedTicketsCount)
      );
    }
  }, [allocatedInvitation?.tickets]);

  return (
    <>
      <Collapse
        size="large"
        expandIcon={({ isActive }) => {
          return <CollapseButton isActive={isActive} />;
        }}
        expandIconPosition="end"
        defaultActiveKey={['1']}
      >
        <Panel
          header={`${t('guestlist_invitation_table_title')} (${
            groupGuests.length
          })`}
          key="1"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Table
              dataSource={groupGuests}
              pagination={false}
              loading={isFetching}
              scroll={{
                x: true,
              }}
              footer={
                !isCustom
                  ? () => (
                      <Space>
                        {totalTicketsChange ? (
                          <>
                            <InputNumber
                              min={
                                nonAllocatedTickets
                                // guestTickets - allocatedTickets - totalTicketsToIgnore
                              }
                              max={waitingList.maxNumberOfSeatsPerHost}
                              value={totalTickets}
                              onChange={(value) =>
                                handleTotalTickets(Number(value))
                              }
                            />
                            <Button onClick={hideChangeTickets}>
                              {t('button_text_cancel')}
                            </Button>
                            <Button
                              type="primary"
                              onClick={handleTotalTicketsSubmit}
                            >
                              {t('button_text_submit')}
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={showChangeTickets}
                            disabled={disabledChangeTickets}
                            icon={
                              businessReason && (
                                <CheckOutlined rev={undefined} />
                              )
                            }
                          >
                            {t('guestlist_total_tickets_change')}
                          </Button>
                        )}
                      </Space>
                    )
                  : undefined
              }
            >
              {/*  HELPER COLUMNS TO BETTER DEBUG: />*/}
              {/* <Column
                title={t('order_column_title')}
                key="order"
                dataIndex="order"
                width="5%"
              /> */}
              <Column
                title={t('guestlist_status')}
                key="status"
                render={(_, { guest, tickets }: GuestItem) => {
                  const invitation = guestInvitation(guest.guestId);
                  const status = getInvitationStatus(invitation);
                  const translatedStatus = status
                    ? invitationStatusTranslation[status]
                    : null;

                  return status ? (
                    <Alert
                      style={{
                        width: 'fit-content',
                        fontSize: screens.xs ? '10px' : '14px',
                      }}
                      message={
                        translatedStatus
                          ? t(translatedStatus)
                          : status.toString().replaceAll('_', ' ')
                      }
                      showIcon
                      type={statusToBadge[status].status}
                      icon={statusToBadge[status].icon}
                    />
                  ) : (
                    <Button
                      type="text"
                      style={{ paddingTop: 6 }}
                      onClick={() => handleRemoveGuest(guest, tickets)}
                      icon={<MinusOutlined size={30} rev={undefined} />}
                    />
                  );
                }}
              />
              <Column
                title={t('guestlist_seats')}
                dataIndex="tickets"
                key="tickets"
                render={(_, { guest, tickets, isToAllocated }: GuestItem) => {
                  const invitation = guestInvitation(guest.guestId);
                  const status = getInvitationStatus(invitation);

                  const seatsLeft =
                    totalTickets - nonAllocatedTickets + totalTicketsToIgnore;
                  const maxValue = tickets + seatsLeft;

                  // to allocate: totalTickets - all host tickets
                  return (
                    <InputNumber
                      min={isToAllocated ? undefined : 1}
                      max={
                        isToAllocated
                          ? undefined
                          : maxValue > waitingList.maxNumberOfSeatsPerPosition
                          ? waitingList.maxNumberOfSeatsPerPosition
                          : maxValue
                      }
                      defaultValue={
                        waitingList?.minNumberOfSeatsPerPosition || 1
                      }
                      onChange={(value) => {
                        setTickets(guest.guestId, Number(value));
                        if (allocatedInvitation) {
                          const inv = guestInvitation(
                            allocatedInvitation.guest.guestId
                          );
                          if (
                            !(
                              inv?.status === 'APPROVED' &&
                              (!inv.position ||
                                inv.position.seatsRequestStatus === 'DECLINED')
                            ) &&
                            inv?.status !== 'CANCELLED' &&
                            inv?.status !== 'REJECTED'
                          ) {
                            setTickets(
                              allocatedInvitation?.guest.guestId ??
                                'to_allocated_id',
                              (allocatedInvitation?.tickets ?? 0) -
                                ((value ?? 0) - tickets)
                            );
                          }
                        }
                      }}
                      value={tickets}
                      disabled={
                        isToAllocated ||
                        (invitation
                          ? status !== InvitationStatusEnum.BEING_REVIEWED ||
                            !!invitation?.numberOfNonAllocatedSeats ||
                            totalTicketsChange
                          : totalTicketsChange)
                      }
                    />
                  );
                }}
              />
              <Column
                title={t('guestlist_name')}
                dataIndex="name"
                key="name"
                render={(_, { guest, isToAllocated }: GuestItem) => {
                  const invitation = guestInvitation(guest.guestId);

                  const internalEmployee = groupGuests.find(
                    (el) => el.guest.guestId === invitation?.specialGuestId
                  );

                  const isPublicOfficial =
                    guest.customInfos.find(
                      (customInfo) =>
                        customInfo.semanticName ===
                        SemanticNameEnum.PUBLIC_OFFICIAL
                    )?.informationValue === 'true';

                  return (
                    <span>
                      {isToAllocated ? (
                        t('not_allocated')
                      ) : (
                        <Tooltip
                          title={
                            internalEmployee
                              ? `Internal employee: ${internalEmployee?.guest.firstName} ${internalEmployee?.guest.lastName}`
                              : false
                          }
                        >
                          {guest.firstName} {guest.lastName}{' '}
                          {guest.guestId === host?.userId && `(${t('host')})`}
                        </Tooltip>
                      )}
                      {isPublicOfficial && (
                        <Tooltip title={t('public_official')}>
                          <SolutionOutlined rev={undefined} />
                        </Tooltip>
                      )}
                      {invitation?.specialGuest && (
                        <Tooltip title={'Internal employee'}>
                          <TeamOutlined rev={undefined} />
                        </Tooltip>
                      )}{' '}
                      {!isToAllocated && guest.guestId !== host?.userId && (
                        <EditOutlined
                          onClick={() => showEditModal(guest)}
                          rev={undefined}
                        />
                      )}
                    </span>
                  );
                }}
              />
              <Column
                title={t('guestlist_company')}
                dataIndex="company"
                key="company"
                render={(_, { guest, tickets }: GuestItem) => {
                  return (
                    <span>
                      {guest.customInfos?.find(
                        (customInfo) =>
                          customInfo.semanticName === SemanticNameEnum.COMPANY
                      )?.informationValue || '–'}
                    </span>
                  );
                }}
              />
              {isCustom && (
                <Column
                  title={'Originally requested'}
                  dataIndex="tickets"
                  key="tickets"
                  render={(_, { guest, tickets, isToAllocated }: GuestItem) => {
                    return (
                      isToAllocated && (
                        <span>
                          {
                            invitations?.content?.find(
                              (invitation) =>
                                invitation?.guest?.guestId === guest.guestId
                            )?.originalNumberOfNonAllocatedSeats
                          }
                        </span>
                      )
                    );
                  }}
                />
              )}

              <Column
                title={t('guestlist_last_rsvp')}
                dataIndex="lastInvitation"
                key="lastInvitation"
                render={(_, { guest, tickets }: GuestItem) => {
                  const date = guest.customInfos?.find(
                    (customInfo) =>
                      customInfo.semanticName === SemanticNameEnum.LAST_RSVP
                  )?.informationValue;

                  return date ? <span>{dayjs(date).fromNow()}</span> : null;
                }}
              />
              <Column
                title={t('guestlist_attendance')}
                dataIndex="lastInvitation"
                key="lastInvitation"
                render={(_, { guest, tickets }: GuestItem) => {
                  return (
                    <span>
                      {`${
                        guest.customInfos?.find(
                          (customInfo) =>
                            customInfo.semanticName ===
                            SemanticNameEnum.ATTENDANCE
                        )?.informationValue || 0
                      }/${
                        guest.customInfos?.find(
                          (customInfo) =>
                            customInfo.semanticName === SemanticNameEnum.RSVPS
                        )?.informationValue || 0
                      }`}
                    </span>
                  );
                }}
              />
              <Column
                title={t('guestlist_cost_contact')}
                dataIndex="lastInvitation"
                key="lastInvitation"
                render={(_, { guest, tickets }: GuestItem) => {
                  const cost =
                    guest.customInfos?.find(
                      (customInfo) =>
                        customInfo.semanticName ===
                        SemanticNameEnum.CONTACT_COST
                    )?.informationValue || 0;

                  return !!Number(cost) ? (
                    <span>{Number(cost).toFixed(0)} €</span>
                  ) : (
                    '–'
                  );
                }}
              />
              <Column
                key="actions"
                align="right"
                render={(_, { guest, isToAllocated }: GuestItem) => {
                  return (
                    <InvitationsActions
                      guest={guest}
                      isToAllocated={!!isToAllocated}
                      setBusinessReason={setBusinessReason}
                      showBusinessReasonModal={showBusinessReasonModal}
                      showEditModal={showEditModal}
                      confirmAcceptInvitation={confirmAcceptInvitation}
                      handleDeclineInvitation={handleDeclineInvitation}
                      invitationsCount={invitations.content.length}
                    />
                  );
                }}
              />
            </Table>
          </Space>
        </Panel>
      </Collapse>
      {checkoutModalOpened && selectedGuest && (
        <CheckoutModalHost
          open={checkoutModalOpened}
          onCancel={() => setCheckoutModalOpened(false)}
          selectedGuest={selectedGuest}
          surveyInstances={surveyInstances}
          closeModal={() => setCheckoutModalOpened(false)}
        />
      )}
      {ReactDOM.createPortal(
        <AntFooter
          style={{
            backgroundColor: token.colorBgBase,
            width: '100%',
            padding: '16px 5%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            borderTop: `1px solid ${token.colorBorder}`,
          }}
        >
          <Space
            direction={screens.xs ? 'vertical' : 'horizontal'}
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Space>
              <Space>
                <Text style={{ fontSize: '20px' }} strong>
                  {getRemainingTickets()}
                </Text>
                <Text style={{ fontSize: screens.xs ? '14px' : '20px' }}>
                  {t('in_guestlist')}
                </Text>
              </Space>
              <Divider type="vertical" />
              <Space>
                <Text style={{ fontSize: '20px' }} strong>
                  {reserveListTotal}
                </Text>
                <Text style={{ fontSize: screens.xs ? '14px' : '20px' }}>
                  {t('in_reserve')}
                </Text>
              </Space>
            </Space>
            <Button
              style={{ width: '100%' }}
              size="large"
              type="primary"
              onClick={handleRequest}
              disabled={!isTicketsToRequest && !isReserveTicketsToRequest}
            >
              {t('guestlist_confirm_selection')}
            </Button>
          </Space>
        </AntFooter>,
        document.getElementById('portalForFooter') as HTMLElement
      )}
      <Modal
        title={t('guestlist_confirm_header')}
        open={businessReasonModal}
        onOk={form.submit}
        onCancel={hideBusinessReasonModal}
        okText={t('button_text_confirm')}
      >
        <Form
          initialValues={{
            businessReason,
          }}
          form={form}
          layout="vertical"
          onFinish={(v) => {
            setBusinessReason(v.businessReason);
            hideBusinessReasonModal();
          }}
        >
          <Form.Item
            label={t('guestlist_business_reason')}
            name="businessReason"
            rules={[
              { required: true, message: 'Please input business reason' },
            ]}
          >
            <TextArea
              showCount
              maxLength={150}
              style={{ height: 120, resize: 'none' }}
              placeholder={t('guestlist_business_reason') as string}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('guestlist_business_reason')}
        open={emptyBusinessReasonModal}
        onOk={() => {
          setEmptyBusinessReasonModal(false);
          showBusinessReasonModal();
        }}
        onCancel={() => setEmptyBusinessReasonModal(false)}
        okText={t('guestlist_business_reason_missing-btn')}
      >
        <Text>
          {t('guestlist_business_reason_missing', {
            nonallocated: getRemainingTickets(),
          })}
        </Text>
      </Modal>
      <Modal
        title={t('guestlist_confirm_header')}
        open={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        footer={[
          <Space>
            <Button type="text" onClick={handlePublicOfficialModal}>
              {t('guestlist_public_official_add-btn')}
            </Button>
            <Button key="back" onClick={() => setConfirmationModal(false)}>
              {t('button_text_cancel')}
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={submitRequest}
            >
              {t('button_text_submit')}
            </Button>
          </Space>,
        ]}
      >
        <Space direction="vertical">
          <Text>{t('guestlist_confirm_phrase')}</Text>
          <Text type="secondary">{t('public_official_action')}</Text>
        </Space>
      </Modal>
      <InternalEmployeeModal
        specialGuestModal={specialGuestModal}
        setSpecialGuestModal={setSpecialGuestModal}
        onSubmitForm={submitRequest}
      />
      <PublicOfficialModal
        waitingListId={waitingListId}
        isOpened={publicOfficialModal}
        onClose={() => setPublicOfficialModal(false)}
      />
      {isEditContactModal && (
        <EditContactModal
          groupId={groupId}
          isEditContactModal={isEditContactModal}
          setIsEditContactModal={setIsEditContactModal}
          guestToEdit={guestToEdit}
          setGuestToEdit={setGuestToEdit}
          isInvitationExists
        />
      )}
    </>
  );
}

import React, { useState } from 'react';
import {
  SurveyExtensionPoint,
  SurveyInputMode,
  SurveyInstanceEntity,
  UserRole,
} from '@seaters-app/constants';
import {
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  notification,
  theme,
} from 'antd';
import Column from 'antd/es/table/Column';
import {
  InfoCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { surveyExtensionPoints } from '../../../surveys/[id]/SurveyInstanceDetails/constants';
import {
  useAppUserStore,
  useDeleteAdminSurveyInstance,
  useFetchFGOWishListSurveysInstances,
  useUpdateFGOSurveyInstance,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { Button } from '@seaters-app/ui';
import { Survey } from '@seaters-app/ui-shared';

function WishListSurveys() {
  const { wishListId = '' } = useParams();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { user } = useAppUserStore();

  const isNotAdmin = !user?.roles.includes(UserRole.ADMIN);

  const [isSurveyModalOpened, setSurveyModalOpened] = useState(false);
  const [selectedSurveyInstance, setSelectedSurveyInstance] =
    useState<SurveyInstanceEntity | null>(null);

  const { data: surveyInstances, isLoading } =
    useFetchFGOWishListSurveysInstances(wishListId, {
      // no sense to fetch more , as it should be only one survey per extension point (max 4)
      size: 10,
      page: 0,
      waitinglist_id: wishListId,
    });

  const { mutate: updateSurveyInstance, isLoading: isUpdating } =
    useUpdateFGOSurveyInstance();

  const { mutate: deleteSurveyInstance, isLoading: isSurveyRemoving } =
    useDeleteAdminSurveyInstance();

  const handleUpdateExtPoint = (
    id: string,
    surveyInstance: SurveyInstanceEntity,
    val: SurveyExtensionPoint
  ) => {
    updateSurveyInstance(
      {
        id,
        body: {
          inputMode: surveyInstance.inputMode,
          surveyId: surveyInstance.survey.id,
          waitinglistId: surveyInstance.waitinglistId,
          extensionPoint: val,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_usage_updated'),
          });
        },
        onError: (err) => {
          notification.error({
            message: t('notification_error_survey_usage_updated'),
            description:
              t(
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
              ) ?? err.message,
          });
        },
      }
    );
  };

  const handleUpdateInputMode = (
    id: string,
    surveyInstance: SurveyInstanceEntity,
    val: boolean
  ) => {
    updateSurveyInstance(
      {
        id,
        body: {
          extensionPoint: surveyInstance.extensionPoint,
          surveyId: surveyInstance.survey.id,
          waitinglistId: surveyInstance.waitinglistId,
          inputMode: val ? SurveyInputMode.EDITABLE : SurveyInputMode.LOCKED,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_usage_updated'),
          });
        },
        onError: (err) => {
          notification.error({
            message: t('notification_error_survey_usage_updated'),
            description:
              t(
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
              ) ?? err.message,
          });
        },
      }
    );
  };

  const handleRemoveSurveyInstance = (id: string) => {
    deleteSurveyInstance(
      { surveyInstanceId: id },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_usage_remove'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_survey_usage_remove'),
          });
        },
      }
    );
  };

  const openSurveyModal = (entity: SurveyInstanceEntity) => {
    setSurveyModalOpened(true);
    setSelectedSurveyInstance(entity);
  };

  const extPointOptions = surveyExtensionPoints.reduce(
    (acc: { label: string; value: SurveyExtensionPoint }[], item) => {
      if (item.value !== SurveyExtensionPoint.AFTER_REGISTRATION) {
        acc.push({ label: t(item.label), value: item.value });
      }
      return acc;
    },
    []
  );

  const disabled = isNotAdmin;

  return (
    <>
      <Table
        rowKey={(surveyInstance) => surveyInstance.id}
        dataSource={surveyInstances?.content}
        loading={isLoading || isUpdating}
        tableLayout="fixed"
      >
        <Column
          title={t('survey_management_survey_label')}
          dataIndex={['survey', 'name']}
          key="survey"
          width="35%"
          render={(name, surveyInstance: SurveyInstanceEntity) => {
            return (
              <Button
                type="text"
                onClick={() => openSurveyModal(surveyInstance)}
              >
                {name} <InfoCircleOutlined rev={undefined} />
              </Button>
            );
          }}
        />
        <Column
          title={t('survey_management_extension_point_label')}
          dataIndex="extensionPoint"
          key="extensionPoint"
          width="25%"
          render={(extensionPoint, surveyInstance: SurveyInstanceEntity) => {
            return (
              <Select
                style={{ width: '100%' }}
                value={extensionPoint}
                options={extPointOptions}
                onSelect={(item, option) => {
                  handleUpdateExtPoint(
                    surveyInstance.id,
                    surveyInstance,
                    option.value
                  );
                }}
              />
            );
          }}
        />
        <Column
          title={t('editable_checkbox_label')}
          dataIndex="inputMode"
          key="inputMode"
          width="15%"
          render={(inputMode, surveyInstance: SurveyInstanceEntity) => {
            return (
              <Switch
                checkedChildren={<CheckOutlined rev={undefined} />}
                onChange={(val) =>
                  handleUpdateInputMode(surveyInstance.id, surveyInstance, val)
                }
                defaultChecked={inputMode === SurveyInputMode.EDITABLE}
              />
            );
          }}
        />

        <Column
          dataIndex="id"
          key="details"
          align="right"
          render={(id: string) => {
            return (
              <Popconfirm
                title={t('delete_wl_survey_button_text')}
                description={t('delete_wl_survey_confirmation_text')}
                cancelText={t('general_no')}
                onConfirm={() => handleRemoveSurveyInstance(id)}
                onOpenChange={() => console.log('open change')}
              >
                <Button
                  disabled={disabled}
                  loading={isSurveyRemoving}
                  type="link"
                  color={token.colorError}
                  icon={<DeleteOutlined rev={undefined} />}
                >
                  {t('mwl_wl_fan-remove-btn')}
                </Button>
              </Popconfirm>
            );
          }}
        />
      </Table>
      <Modal
        title={`${selectedSurveyInstance?.survey?.title[0]?.text} (${t(
          'comm_channel_preview-btn'
        )})`}
        centered
        footer={null}
        open={isSurveyModalOpened}
        onCancel={() => setSurveyModalOpened(false)}
        width={680}
        style={{ maxHeight: 'fit-content', margin: '24px' }}
      >
        {selectedSurveyInstance && (
          <Survey
            cancel={() => setSurveyModalOpened(false)}
            surveyInstance={selectedSurveyInstance}
            previewMode
          />
        )}
      </Modal>
    </>
  );
}

export default WishListSurveys;

import {
  CreateSurveyQuestionFormValidator,
  QuestionFormType,
} from '@seaters-app/constants';
import { convertTranslation } from '../../../../../../utils/helpers/convertTranslations';

export const prepareValues = (values: CreateSurveyQuestionFormValidator) => {
  const {
    type,
    text,
    answerSemanticId,
    answerLabel,
    questionChoices,
    structure,
  } = values;

  const question = {
    type,
    text,
    answerSemanticId,
    answerLabel,
    structure: [],
  };

  const choices = questionChoices?.map((choice, i) => {
    return {
      order: i + 1,
      offeredAnswerTranslation: choice.offeredAnswerTranslation,
    };
  });
  const structured = structure?.map((field) => {
    return { ...field, mandatory: !!field.mandatory };
  });

  return { question, choices, structureFields: structured };
};

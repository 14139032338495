import {
  CURRENT_LANGUAGE,
  Language,
  TranslationEntity,
} from '@seaters-app/constants';

export type TransObjectType = {
  [lang in Language]: string;
};

export const translationsParser = (
  transObject: TransObjectType
): TranslationEntity[] => {
  const langArray = Object.keys(transObject) as Language[];
  const parsedData = langArray.map(
    (key: Language): TranslationEntity => ({
      lang: key,
      text: transObject[key],
    })
  );

  return parsedData;
};

export const getSingleTranslation = (
  data: TranslationEntity[],
  lang?: string
): string => {
  const newData = data.map((dataItem) => {
    return { lang: dataItem.lang, text: dataItem.text ? dataItem.text : null };
  });
  return (
    newData?.find((translation) => {
      return lang
        ? translation.lang === lang
        : translation.lang === CURRENT_LANGUAGE;
    })?.text ??
    newData?.find((translation) => {
      return translation.lang === Language.EN;
    })?.text ??
    ''
  );
};

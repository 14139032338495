import { ListEntity, UserListEntity, usersKeys } from '@seaters-app/constants';
import { fetchAdminUsers } from '@seaters-app/data-access';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

export const useUsersOptions = () => {
  const [query, setQuery] = useState({
    size: 10,
    page: 1,
    query: '',
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<UserListEntity>, Error>({
    queryKey: usersKeys.list(query),
    queryFn: ({
      pageParam = {
        maxPageSize: query.size,
        itemOffset: query.size * (query.page - 1),
        query: query.query,
      },
    }) => {
      return fetchAdminUsers(pageParam);
    },
    getNextPageParam: ({ totalSize, itemOffset, maxPageSize }) => {
      const nextOffset = itemOffset + maxPageSize;

      return totalSize - itemOffset > maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: maxPageSize,
            query: query.query,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };
  const allItems = data?.pages?.flatMap((page) => page.items) || [];

  const usersOptions = allItems?.reduce(
    (
      acc: { label: string; value: string; id: string }[],
      user: UserListEntity
    ) => {
      acc.push({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
        id: user.email,
      });

      return acc;
    },
    []
  );

  return {
    usersOptions,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    query,
    setQuery,
  };
};

import React, { useEffect, useState } from 'react';
import {
  Card,
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  theme,
} from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ListEntity,
  ListEntityV2,
  SurveyEntity,
  SurveyExtensionPoint,
  SurveyInputMode,
  SurveyInstanceBody,
  SurveyInstanceEntity,
  SurveyInstanceValidator,
  SurveyInstanceValidatorSchema,
  SurveyPaginationQuery,
  surveyInstancesKeys,
} from '@seaters-app/constants';
import { Footer } from '@seaters-app/ui-shared';
import { surveyExtensionPoints } from './constants';
import { useSurveysOptions } from '../hooks/useSurveysOptions';
import { useWLOptions } from '../hooks/useWLOptions';
import debounce from 'lodash/debounce';
import { Button, baseColor400 } from '@seaters-app/ui';
import { LeftOutlined } from '@ant-design/icons';
import { useFGAdminOptions } from '../hooks/useFGAdminOptions';
import { queryClient } from '@seaters-app/data-access';
const { Text } = Typography;

interface SurveyInstanceProps {
  initialValues: SurveyInstanceBody;
  onSubmit: (dataToSend: SurveyInstanceValidator) => void;
  surveyInstance?: SurveyInstanceEntity;
  fromWishList?: boolean;
  surveysFetchFunction: (
    params: SurveyPaginationQuery
  ) => Promise<ListEntity<SurveyEntity>>;
  hideSubHeader?: boolean;
  back?: () => void;
}

function SurveyInstanceDetails({
  initialValues,
  onSubmit,
  surveyInstance,
  fromWishList = false,
  back,
  surveysFetchFunction,
}: SurveyInstanceProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const colorPrimary = theme.useToken().token.colorPrimary;
  const { state = null } = useLocation();
  const { surveyInstanceId = '', questionnaireId = null } = useParams();
  const [surveyInstanceForm] = Form.useForm();
  const { wishListId = '' } = useParams();
  const extPoint = Form.useWatch('extensionPoint', surveyInstanceForm);
  const wlSurveyInstances = queryClient.getQueryData<
    ListEntityV2<SurveyInstanceEntity>
  >(
    surveyInstancesKeys.listPerWL({
      size: 10,
      page: 0,
      waitinglist_id: wishListId,
    })
  );

  const usedExtPoints =
    wlSurveyInstances?.content.map((instance) => instance.extensionPoint) ?? [];

  // Define selected instances

  const [selectedSurvey, setSelectedSurvey] = useState<string>(
    surveyInstance?.survey?.name ?? ''
  );

  const [selectedFG, setSelectedFG] = useState<string | undefined>('');

  const [selectedWL, setSelectedWL] = useState<string | undefined>('');

  // ***

  const [openWishlistsDropdown, setOpenWishlistsDropdown] = useState(
    !!selectedFG
  );

  const [searchText, setSearchText] = useState('');

  // Get instances options

  const { surveysOptions, loadMoreData: loadMoreSurveys } =
    useSurveysOptions(surveysFetchFunction);

  const handleScrollSurveys = (e: any) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreSurveys();
    }
  };

  const {
    fanGroupsOptions,
    loadMoreData: loadMoreFGs,
    fanGroupsFetching,
  } = useFGAdminOptions(
    {
      page: 0,
      size: 20,
      query: searchText,
    },
    fromWishList
  );

  const handleScrollFGs = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreFGs();
    }
  };

  const {
    waitingListsOptions,
    loadMoreData: loadMoreWLs,
    isLoading: isWLLoading,
  } = useWLOptions(selectedFG ?? '');

  const handleScrollWLs = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreWLs();
    }
  };

  // ***

  const onFormSubmit = (values: SurveyInstanceBody) => {
    const dataToSend = { ...values };

    if (values.extensionPoint === SurveyExtensionPoint.AFTER_REGISTRATION) {
      delete dataToSend.waitinglistId;
      dataToSend.fangroupId = selectedFG;
    } else {
      delete dataToSend.fangroupId;
      dataToSend.waitinglistId = selectedWL;
    }

    if (fromWishList) {
      dataToSend.waitinglistId = initialValues.waitinglistId;
    }
    if (!questionnaireId && !state) {
      dataToSend.surveyId = selectedSurvey;
    }

    const parsed = SurveyInstanceValidatorSchema.safeParse(dataToSend);
    if (parsed.success) {
      onSubmit(dataToSend);
    }
  };

  const extPointsOptions = !fromWishList
    ? surveyExtensionPoints
    : surveyExtensionPoints.filter(
        (option) => option.value !== SurveyExtensionPoint.AFTER_REGISTRATION
      );

  const handleSearchFGs = debounce((searchValue) => {
    setSearchText(searchValue);
  }, 500);

  useEffect(() => {
    if (selectedFG && !openWishlistsDropdown) {
      setOpenWishlistsDropdown(true);
    }
  }, [selectedFG]);

  useEffect(() => {
    loadMoreFGs();
  }, [searchText]);

  useEffect(() => {
    if (surveyInstance) {
      setSelectedFG(surveyInstance.fangroupId);
      setSelectedWL(surveyInstance.waitinglistId);
      setSelectedSurvey(surveyInstance?.survey.id);
    }
  }, [surveyInstance]);
  return surveyInstance || !surveyInstanceId ? (
    <Form
      id="surveyInstanceForm"
      form={surveyInstanceForm}
      layout="vertical"
      onFinish={onFormSubmit}
      autoComplete="off"
      size="middle"
      initialValues={{
        ...initialValues,
        surveyId: questionnaireId ?? state ?? initialValues.surveyId,
      }}
    >
      <Row gutter={24}>
        <Col xs={24} md={16} lg={16} xl={16} xxl={16}>
          <Form.Item
            label={<Text strong>{t('survey_management_survey_label')}</Text>}
            name="surveyId"
          >
            <Select
              optionFilterProp="label"
              showSearch
              disabled={!!questionnaireId}
              options={surveysOptions}
              onSelect={(item, option) => {
                setSelectedSurvey(option.value?.toString() ?? '');
              }}
              onPopupScroll={handleScrollSurveys}
            />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item
            label={<Text strong>{t('input_mode_label')}</Text>}
            name="inputMode"
          >
            <Checkbox
              defaultChecked={
                initialValues?.inputMode === SurveyInputMode.EDITABLE
              }
              onChange={(e) => {
                surveyInstanceForm.setFieldValue(
                  'inputMode',
                  e.target.value === SurveyInputMode.EDITABLE
                    ? SurveyInputMode.LOCKED
                    : SurveyInputMode.EDITABLE
                );
              }}
            >
              {t('editable_checkbox_label')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {!fromWishList && (
        <>
          <Row gutter={24}>
            <Col xs={24} md={16} lg={16} xl={16} xxl={16}>
              <Form.Item
                label={<Text strong>{t('admin_fan-group')}</Text>}
                name="fangroupId"
              >
                <Select
                  optionFilterProp="label"
                  placeholder={t('search_by_placeholder_text', {
                    by: t('admin_fan-groups_slug'),
                  })}
                  allowClear
                  onClear={loadMoreFGs}
                  notFoundContent={
                    fanGroupsFetching ? (
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <Spin size="small" />
                      </div>
                    ) : (
                      !fanGroupsOptions.length && t('not_found')
                    )
                  }
                  showSearch
                  onSearch={handleSearchFGs}
                  onPopupScroll={handleScrollFGs}
                  options={fanGroupsOptions}
                  onSelect={(item, option) => {
                    setSelectedFG(option.value?.toString() ?? '');
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={16} lg={16} xl={16} xxl={16}>
              <Spin
                spinning={isWLLoading && !!selectedFG}
                tip={t('general_please_wait')}
              >
                <Form.Item
                  label={<Text strong>{t('mwl_tab-wl')}</Text>}
                  name="waitinglistId"
                >
                  <Select
                    open={openWishlistsDropdown}
                    onDropdownVisibleChange={(visible) =>
                      setOpenWishlistsDropdown(visible)
                    }
                    showSearch
                    optionFilterProp="label"
                    options={waitingListsOptions}
                    onPopupScroll={handleScrollWLs}
                    onSelect={(item, option) => {
                      setSelectedWL(option.value?.toString() ?? '');
                    }}
                    defaultValue={initialValues.waitinglistId}
                    disabled={
                      extPoint === SurveyExtensionPoint.AFTER_REGISTRATION ||
                      !selectedFG ||
                      isWLLoading
                    }
                  />
                </Form.Item>
              </Spin>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={24}>
        <Col xs={24} md={16} lg={16} xl={16} xxl={16}>
          <Form.Item
            label={
              <Text strong>{t('survey_management_extension_point_label')}</Text>
            }
            name="extensionPoint"
          >
            <Radio.Group
              style={{ width: '100%', minWidth: 300 }}
              name="extensionPoint"
              defaultValue={initialValues.extensionPoint}
            >
              {extPointsOptions.map((option) => (
                <Card
                  bordered
                  style={{
                    marginBottom: 8,
                    borderColor:
                      surveyInstanceForm.getFieldValue('extensionPoint') ===
                      option.value
                        ? colorPrimary
                        : baseColor400,
                  }}
                >
                  <Radio
                    disabled={usedExtPoints.includes(option.value)}
                    value={option.value}
                  >
                    {t(option.label)}
                  </Radio>
                </Card>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {/* // TODO : later replace page with drawer in Surveys tab */}
      {!fromWishList && (
        <Footer onCancel={() => navigate(`..`)}>
          {back && (
            <Button
              color={colorPrimary}
              style={{ marginLeft: 0, marginRight: 'auto' }}
              size="large"
              type="default"
              icon={<LeftOutlined rev={undefined} />}
              onClick={back}
            >
              {t('event_back-btn')}
            </Button>
          )}
        </Footer>
      )}
    </Form>
  ) : (
    <div style={{ textAlign: 'center', width: '100%', padding: '40%' }}>
      <Spin />
    </div>
  );
}

export default SurveyInstanceDetails;

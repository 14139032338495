import { z } from 'zod';
import { Language } from '../enums/languages';

export const getTranslationValidation = () => {
  const obj = {
    [Language.EN]: z.string().min(1, 'English translation is missing'),
    [Language.DE]: z.string(),
    [Language.NL]: z.string(),
    [Language.CN]: z.string(),
    [Language.RU]: z.string(),
    [Language.IT]: z.string(),
    [Language.FR]: z.string(),
    [Language.JA]: z.string(),
    [Language.ES]: z.string(),
  };

  const validation = z
    .object(obj)
    .partial()
    .required({
      [Language.EN]: true,
    });

  return validation;
};

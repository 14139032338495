import { Button, Checkbox, Col, Row, Space, notification } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useEventCreateStepper } from './hooks/useEventCreateStepper';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useCreateFGOEvent } from '@seaters-app/data-access';
import { convertTranslation } from '../../helpers/convertTransaltions';
import { useIsMutating } from '@tanstack/react-query';
import { FGOEventValidatorSchema } from '@seaters-app/constants';

type AttendeesInfoStepProps = {
  handleSelectEvent: (eventId: string) => void;
};

export const attendeesRequiredFieldList = [
  { label: 'event_reqinfo_title', value: 'title' },
  { label: 'event_reqinfo_firstname', value: 'firstName' },
  { label: 'event_reqinfo_lastname', value: 'lastName' },
  { label: 'event_reqinfo_email', value: 'email' },
  { label: 'event_reqinfo_phonenumber', value: 'phoneNumber' },
  { label: 'event_reqinfo_address', value: 'address' },
  { label: 'event_reqinfo_zipcode', value: 'zipCode' },
  { label: 'event_reqinfo_city', value: 'city' },
  { label: 'event_reqinfo_state', value: 'state' },
  { label: 'event_reqinfo_country', value: 'country' },
  { label: 'event_reqinfo_dateofbirth', value: 'birthDate' },
  { label: 'event_reqinfo_citizenship', value: 'citizenshipCountryCode' },
  { label: 'event_reqinfo_idnumber', value: 'idNumber' },
  { label: 'event_reqinfo_company', value: 'company' },
  { label: 'event_reqinfo_language', value: 'language' },
];

export function AttendeesInfoStep({
  handleSelectEvent,
}: AttendeesInfoStepProps) {
  const { t } = useTranslation();
  const { eventFormValues, prev, venueConfiguration, setModalOpen } =
    useEventCreateStepper();

  const [attendeeRequiredFields, setAttendeeRequiredFields] =
    useState<CheckboxValueType[]>();

  const { mutate: createEvent } = useCreateFGOEvent();

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setAttendeeRequiredFields(checkedValues);
  };

  const prepareValues = () => {
    const obj = {
      attendeeRequiredFields,
      shortName: convertTranslation(eventFormValues?.shortName),
      description: convertTranslation(eventFormValues?.description),
      name: convertTranslation(eventFormValues?.name),
      startDate: dayjs(eventFormValues?.startDate).format(),
      endDate: eventFormValues?.endDate
        ? dayjs(eventFormValues?.endDate).format()
        : null,
      ticketEvolutionId: venueConfiguration?.ticketEvolutionId,
      venueConfigId: venueConfiguration?.id,
      imageFileId: eventFormValues?.imageFileId,
    };

    return obj;
  };

  const handlePublish = () => {
    const body = prepareValues();

    const parsed = FGOEventValidatorSchema.safeParse(body);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    createEvent(parsed.data, {
      onSuccess: (data) => {
        handleSelectEvent(data.eventId);
        setModalOpen(false);
        notification.success({
          message: t('notification_success_event_created'),
        });
      },
    });
  };

  const mutating = useIsMutating();

  const isLoading = !!mutating;

  return (
    <Space
      direction="vertical"
      style={{ marginBottom: 50, width: '100%' }}
      size={0}
    >
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={onChange}
        value={attendeeRequiredFields}
        disabled={isLoading}
      >
        <Row gutter={[16, 24]}>
          {attendeesRequiredFieldList.map((el) => {
            return (
              <Col span={12}>
                <Checkbox value={el.value}>{t(el.label)}</Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
      <div className={styles.footer}>
        <Button size="middle" onClick={prev} disabled={isLoading}>
          <LeftOutlined rev={undefined} />
          {t('prev_step_button_text')}
        </Button>
        <Button
          size="middle"
          type="primary"
          onClick={handlePublish}
          loading={isLoading}
        >
          {t('mwl_tab-publish')}
        </Button>
      </div>
    </Space>
  );
}

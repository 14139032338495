import {
  AccessMode,
  FanGroupBillingMode,
  FilterTypeEnum,
  LoginTypeEnum,
  PaymentMethod,
  RegistrationTypeEnum,
} from '../../enums';
import { FanGroupStatus } from '../../enums';
import { z } from 'zod';
import { TranslationValidatorSchema } from './translation.validator';

export const FanGroupValidatorSchema = z.object({
  id: z.string().optional(),
  name: z.array(TranslationValidatorSchema).optional(),
  shortName: z.array(TranslationValidatorSchema).optional(),
  slug: z.string(),
  companyName: z.string().optional(),
  status: z.nativeEnum(FanGroupStatus).optional(),
  termsAndConditions: z.array(TranslationValidatorSchema).optional(),
  privacyPolicy: z.array(TranslationValidatorSchema).optional(),
  operationParameters: z
    .object({
      optInPlatformTexts: z.array(TranslationValidatorSchema).optional(),
      optInPartnersTexts: z.array(TranslationValidatorSchema).optional(),
      optInTermsTexts: z.array(TranslationValidatorSchema).optional(),
      optInRulesTexts: z.array(TranslationValidatorSchema).optional(),
    })
    .optional(),
  optInPlatformTexts: z.array(TranslationValidatorSchema).optional(),
  optInPartnersTexts: z.array(TranslationValidatorSchema).optional(),
  optInTermsTexts: z.array(TranslationValidatorSchema).optional(),
  optInRulesTexts: z.array(TranslationValidatorSchema).optional(),
  geoFilteringNotificationTitle: z.array(TranslationValidatorSchema).optional(),
  geoFilteringNotificationDescription: z
    .array(TranslationValidatorSchema)
    .optional(),
  description: z.array(TranslationValidatorSchema).optional(),
  welcomeMessage: z.array(TranslationValidatorSchema).optional(),
  showLogo: z.boolean().optional(),
  optInPlatformHidden: z.boolean().optional(),
  optInPartnersHidden: z.boolean().optional(),
  profileImageUrl: z.any().optional(),
  color1: z.string().optional(),
  logoImageUrl: z.any().optional(),
  coverImageUrl: z.any().optional(),
  backgroundImageUrl: z.any().optional(),
  properties: z.record(z.string().min(1), z.unknown()).optional().nullable(),
});

export type FanGroupValidator = z.infer<typeof FanGroupValidatorSchema>;

export const FanGroupGeneralValidatorSchema = z.object({
  name: z.array(TranslationValidatorSchema),
  shortName: z.array(TranslationValidatorSchema).optional(),
  slug: z.string(),
  companyName: z.string().optional(),
  status: z.nativeEnum(FanGroupStatus).optional(),
  termsAndConditions: z.array(TranslationValidatorSchema).optional(),
  privacyPolicy: z.array(TranslationValidatorSchema).optional(),
  rulesLinks: z.array(TranslationValidatorSchema).optional(),
  legalNotice: z.array(TranslationValidatorSchema).optional(),
  faqLinks: z.array(TranslationValidatorSchema).optional(),
  helpLinks: z.array(TranslationValidatorSchema).optional(),
  optInPlatformTexts: z.array(TranslationValidatorSchema).optional(),
  optInPartnersTexts: z.array(TranslationValidatorSchema).optional(),
  optInPlatformHidden: z.boolean().optional(),
  optInPartnersHidden: z.boolean().optional(),
  optInTermsTexts: z.array(TranslationValidatorSchema).optional(),
  optInRulesTexts: z.array(TranslationValidatorSchema).optional(),
  geoFilteringNotificationTitle: z.array(TranslationValidatorSchema).optional(),
  geoFilteringNotificationDescription: z
    .array(TranslationValidatorSchema)
    .optional(),
  description: z.array(TranslationValidatorSchema).optional(),
  welcomeText: z.array(TranslationValidatorSchema).optional(),
  showLogo: z.boolean(),
  ticketingSystemId: z.string().optional().nullable(),
  billingParameters: z
    .object({
      authorizedFanBillingModes: z
        .array(z.nativeEnum(FanGroupBillingMode))
        .optional(),
      billingVariables: z.object({
        distributionRate: z.string().optional(),
        maxDistributionFee: z.string().optional(),
        minDistributionFee: z.string().optional(),
        vatRate: z.string().nullable().optional(),
      }),
      paymentMethods: z.array(z.nativeEnum(PaymentMethod)).optional(),
      paymentSystemId: z.string().optional(),
      prepaidFee: z.boolean().optional(),
    })
    .optional(),
});

export type FanGroupGeneralValidator = z.infer<
  typeof FanGroupGeneralValidatorSchema
>;

export const FGRegisterConfigValidatorSchema = z.object({
  login: z.array(z.nativeEnum(LoginTypeEnum)),
  signup: z.array(z.nativeEnum(RegistrationTypeEnum)),
});

export type FGRegisterConfigValidator = z.infer<
  typeof FGRegisterConfigValidatorSchema
>;

export const FanGroupGeneralFormValidatorSchema = z.object({
  name: z.record(z.string().min(1), z.string()).optional(),
  shortName: z.record(z.string().min(1), z.string()).optional(),
  slug: z.string(),
  companyName: z.string().optional(),
  ticketingSystemId: z.string().optional().nullable(),

  billingParameters: z
    .object({
      authorizedFanBillingModes: z.array(z.nativeEnum(FanGroupBillingMode)),
      billingVariables: z
        .object({
          distributionRate: z.string().optional(),
          maxDistributionFee: z.string().optional(),
          minDistributionFee: z.string().optional(),
          vatRate: z.string().nullable().optional(),
        })
        .optional(),
      paymentMethods: z.array(z.nativeEnum(PaymentMethod)),
      paymentSystemId: z.string().optional(),
      prepaidFee: z.boolean().optional(),
    })
    .optional(),
  status: z.nativeEnum(FanGroupStatus).optional(),
  termsAndConditions: z.record(z.string().min(1), z.string()).optional(),
  privacyPolicy: z.record(z.string().min(1), z.string()).optional(),
  rulesLinks: z.record(z.string().min(1), z.string()).optional(),
  legalNotice: z.record(z.string().min(1), z.string()).optional(),
  faqLinks: z.record(z.string().min(1), z.string()).optional(),
  helpLinks: z.record(z.string().min(1), z.string()).optional(),
  optInPlatformTexts: z.record(z.string().min(1), z.string()).optional(),
  optInPartnersTexts: z.record(z.string().min(1), z.string()).optional(),
  optInPlatformHidden: z.boolean().optional(),
  optInPartnersHidden: z.boolean().optional(),
  optInTermsTexts: z.record(z.string().min(1), z.string()).optional(),
  optInRulesTexts: z.record(z.string().min(1), z.string()).optional(),
  // geoFilteringNotificationTitle: z
  //   .record(z.string().min(1), z.string())
  //   .optional()
  //   .nullable(),
  // geoFilteringNotificationDescription: z
  //   .record(z.string().min(1), z.string())
  //   .optional()
  //   .nullable(),
  description: z.record(z.string().min(1), z.string()).optional(),
  welcomeText: z.record(z.string().min(1), z.string()).optional(),
  showLogo: z.boolean(),
});

export type FanGroupGeneralFormValidator = z.infer<
  typeof FanGroupGeneralFormValidatorSchema
>;

export const FanGroupAccessValidatorSchema = z.object({
  visibility: z.boolean(),
  accessMode: z.nativeEnum(AccessMode),
  protectionCodeExplanation: z.array(TranslationValidatorSchema),
});

export type FanGroupAccessValidator = z.infer<
  typeof FanGroupAccessValidatorSchema
>;

export const FanGroupAccessFormValidatorSchema = z.object({
  visibility: z.boolean(),
  accessMode: z.nativeEnum(AccessMode),
  protectionCodeExplanation: z.record(z.string().min(1), z.string()),
});
